import {
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  
  import Form from "react-bootstrap/Form";
  import Col from "react-bootstrap/Col";
  import Row from "react-bootstrap/Row";
  import moment from "moment";
  import { InputGroup, Tab, Tabs } from "react-bootstrap";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import PaymentDetailInfo from "./components/paymentDetailsInfo";
  



  const DataRow = ({ label, value, type }) => {
    return (
      <>
          <Form.Label>{label}</Form.Label>
      {type === 'Amount' 
      ?
      <InputGroup>
        <InputGroup.Text style={{height:'2rem', border:0, background:'lightGray'}}><span className="rupee-symbol">₹</span> </InputGroup.Text>
        <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? formatAmountIndianRupees(value) : " "}
        disabled
      />
      </InputGroup>
      :
      <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? value : " "}
        disabled
      />
      }
      </>
    );
  };
  
  const EmbrillProfileInfoModel = ({ openInfoModal, closeInfoModal, selectedData, role }) => {
   
    const [selectedItem, setSelectedItem] = useState(selectedData);
    const dispatch = useDispatch();
  
    const [activeTab, setActiveTab] = useState("information");
  
    useEffect(() => {
      setSelectedItem(selectedData);
    }, [selectedData]);
  
    return (
      <div>
        <Modal
          isOpen={openInfoModal}
          onClose={closeInfoModal}
          size="xl"
          className="modal-main"
        >
          <ModalOverlay />
          <ModalContent className="info-model">
            <div className="fixed-header">
              <ModalHeader className="expense-modal-header">
                <span>Embrill Profile Information</span>
              </ModalHeader>
              <ModalCloseButton className="expense-modal-header" />
            </div>
            <ModalBody>
              <Form className="addForm">
                <Tabs
                  id="justify-tab-example1"
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                  className=""
                  style={{ marginTop: "4rem" }}
                  justify
                >
                  <Tab
                    eventKey="information"
                    title="Information"
                    className="main-tab-item"
                  >
                    <div className="card">
                     <h5 className="heading-h5">Bank Details</h5>
            
            <Row className="info-row">
              
                <Col>
                  <DataRow
                    label="Account Number"
                    value={selectedItem?.accountNumber}
                  />
                </Col>
                <Col>
                  <DataRow
                    label="IFSC Code"
                    value={selectedItem?.ifscCode}
                  />
                </Col>    
              </Row>
              <Row className="info-row">
             
                <Col>
                  <DataRow
                    label="Bank Name"
                    value={selectedItem?.bankName}
                  />
                </Col>
                <Col>
                  <DataRow
                    label="Branch Name"
                    value={selectedItem?.branchName}
                  />
                </Col>
    
              </Row>
                    <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">Bank Person Contact Details</h5>
            
                    <Row className="info-row">
                      
                        <Col>
                          <DataRow
                            label="Contact Person"
                            value={selectedItem?.contactPerson}
                          />
                        </Col>
                        <Col></Col>
            
                      </Row>
                      <Row className="info-row">
                      <Col>
                          <DataRow
                            label="Email"
                            value={selectedItem?.email}
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="phone"
                            value={selectedItem?.phone}
                          />
                        </Col>
            
                      </Row>
                      <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                     <PaymentDetailInfo selectedItem={selectedItem}/>
                   </div>
                     

                  </Tab>
                 <Tab
                  eventKey=""
                  title=""
                  disabled
                  className="main-tab-item"
                 >

                 </Tab>
                </Tabs>
              </Form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
  };
  
  export default EmbrillProfileInfoModel;
  