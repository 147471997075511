import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Form from "react-bootstrap/Form";
// Custom components
import BarChart from "components/charts/BarChart";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import { MdBarChart, MdStackedLineChart } from "react-icons/md";
import { getDifferenceFromPrevVal } from "jsFunctions/helperFunctions";
import { createListForSingleOption } from "components/allForm/createableSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetSubCategory } from "redux/formOptions";
import { fetchAsyncGetMinorSubCategory } from "redux/formOptions";
import LineChart from "components/charts/LineChart";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { clientProjectOptions } from "jsFunctions/clientProjectOptions";

const CreateOption = ({array})=>{
  return (

    array && array.length > 0 && array.map((item,index)=>{
      return (

        <option key={index} value={item?.value}>{item?.label}</option>
      )
    })
  )
    }
  
export default function WeeklyExpense(props) {
  const { ...rest } = props;
  const dispatch = useDispatch();
  const [option, setOption] = useState([]);
  const [value, setValue] = useState([]);
  const [valueDifference, setValueDifference] = useState(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("#22b378", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const formOptionsList1 = useSelector((state) => state.formOptions);
  const [formOptionsList, setFormOptionsList] = useState([])

  const [clientOption, setClientOption] = useState([]);
  const [merchentOption, setMerchentOPtion] = useState([]);
  const [officeTypeOption, setOfficeTypeOption] = useState([]);
  const [majorTypeOption, setMajorTypeOption] = useState([]);
  const [minorTypeOption, setMinorTypeOption] = useState([]);
  const [minorSubCategoryOption, setMinorSubCategoryOption] = useState([]);
  const [activeType, setActiveType] = useState('bar');
  const [resourceTypeOption, setResourceTypeOption] = useState([]);
  const [projectTypeOption, setProjectTypeOption] = useState([]);

  const [poNoResource, setPoNoResource] = useState([])
  const [receiverOption, setReceiverOption] =useState([])

  const clientData = useSelector((state) => state.clientData);
  const vendorList = useSelector((state) => state.vendorData);

  const fetchPOResource = ()=>{
    dispatch(fetchAsyncResourcePONum()).then((res) => {
      if (res.payload) {
        const resourcePoNo = [];
        res.payload.forEach((item, index) => {
          resourcePoNo.push({
            value: item._id,
            label: poNumberFormate(item.poNo),
          });
        });
        setPoNoResource(resourcePoNo);
      }
    });
  }
  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === props?.type)
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

 
  useEffect(() => {
   
    getAllClientOptions()


    let officeTypeList = createListForSingleOption(
      formOptionsList,
      "office-type"
    );
    let expenseList = createListForSingleOption(formOptionsList, "expense");
    let receiverList = createListForSingleOption(formOptionsList, "receiver");

   
    setOfficeTypeOption(officeTypeList)
    setMajorTypeOption(expenseList)
    setReceiverOption(receiverList);

    if(props?.type === 'tds'){
      fetchPOResource()
    }
  }, [formOptionsList]);


  useEffect(() => {
    setOption([]);
    setValueDifference(null);
    setOption(props.optionBarChart);
    setValue(props.valuesBarChart);
  }, [props.valuesBarChart, props.optionBarChart]);

  useEffect(() => {
    if (value.length > 0 && option.length > 0) {
      const diff = getDifferenceFromPrevVal(value);
      let getOption = barChartOptionsConsumption(option, diff);
      setValueDifference(getOption);
    }
  }, [value]);

  useEffect(() => {
    const major_value = props?.filterData?.majorType
    if (major_value) {
      dispatch(fetchAsyncGetSubCategory(major_value)).then(
        (response) => {
          if (response.payload) {
            const minorOptions = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === props?.type)

            setMinorTypeOption(minorOptions ? minorOptions : []);
          }
        }
      );
    }
  }, [props?.filterData?.majorType]);

  // Set SubCategory for selected minorype value
  useEffect(() => {
    const minor_value = props?.filterData?.minorType

    if (minor_value) {
      dispatch(fetchAsyncGetMinorSubCategory(minor_value)).then(
        (response) => {
          if (response.payload) {
            const minorSubOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === props?.type)

            setMinorSubCategoryOption(minorSubOption ? minorSubOption : []);
          }
        }
      );
    }
  }, [props?.filterData?.minorType]);

  const getAllClientOptions = async()=>{
    if(clientData){

      const clientOption = await clientProjectOptions('clientName','_id',clientData) 
      setClientOption(clientOption);
    }
  }

  const getAllProjectOptions = async(clientId)=>{
    const client = clientData.find(client => client._id === clientId);
    if(client){

      const projects = client?.projects || []
      if(projects){
        const projectOption = await clientProjectOptions('projectName','_id',projects) 
        setProjectTypeOption(projectOption);
       
      }
     
    }
  }

   // Set MinorCategory for selected majorType value
   useEffect(() => {
    if (props?.filterData?.clientName && props?.filterData?.clientName) {
      getAllProjectOptions(props?.filterData?.clientName)
    }
    else{
      setProjectTypeOption([])
    }
  }, [props?.filterData?.clientName]);

  const getAllCompanyOptions = async()=>{
    // const filteredVendors = vendorList.filter(vendor => vendor.isConsultant === true);
      const companyOption = await clientProjectOptions('companyName','_id',vendorList) 
      setResourceTypeOption(companyOption);
      setMerchentOPtion(companyOption);
  }

  useEffect(()=>{
    if(vendorList){
      getAllCompanyOptions()
    }
  },[vendorList])

  return (
    <Card
      align="center"
      direction="column"
      w="130%"
      h="680px"
      justifyContent="space-between"
      p={0}
      // overflowX={{ sm: "scroll", lg: "hidden" }}
      {...rest}
      className="weekly-expense-graph"

    >
      <Flex 
       className="weekly-graph-flex"
       alignItems="center"
       justifyContent="space-between"

       w="100%"
      >        
        <Text me={20}>
          {props?.heading}
        </Text> 
        <Form.Select
          size="sm"
          className="dashboardSelect"
          disabled={props?.filterData?.viewType === "yearly"}
          onChange={(e) => props.changeYear(e.target.value)}
          defaultValue="2024-2025"
        >
          <option value="">Select Financial Year</option>
          <option value="2021-2022">2021-2022</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
          <option value="2025-2026">2025-2026</option>
        </Form.Select>
        <Select
          fontSize="sm"
          variant="subtle"
          width="unset"
          fontWeight="700"
          defaultValue={props?.filterData?.viewType}
          name="viewType"
          onChange={(e) => {
            props.handleFilter(e);
          }}
        >
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>

        <Button
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>
      {(props?.type !== 'tds' && props?.type !== 'directTax') ?
        <>
      <Flex align="center" w="100%" px="0px" py="0px">
       
       {props?.type === 'internal' &&
        <Form.Select
          size="sm"
          className="dashboardSelect"
          name="office"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.office}
        >
          <option value="">Select Office</option>
          <CreateOption array = {officeTypeOption}/>
        </Form.Select>
        }
        <Form.Select
          size="sm"
          className="dashboardSelect"
          name="majorType"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.majorType}         
        >
          <option value="">Select Major type</option>
          <CreateOption array = {majorTypeOption}/>
        </Form.Select>
        
        <Form.Select
          size="sm"
          
          className="dashboardSelect"
          name="minorType"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.minorType}
        >
          <option value="">Select Minor Type</option>
          <CreateOption array = {minorTypeOption}/>

        </Form.Select>
        {
          props?.type !== 'internal' &&
          <Form.Select
          size="sm"
          className="dashboardSelect"
          name="minorSubCategory"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.minorSubCategory}
        >
          <option value="">Select Minor Sub Category</option>
          <CreateOption array = {minorSubCategoryOption}/>
        </Form.Select>
        }
      </Flex>
      <Flex align="center" w="100%" px="0px" py="0px" mb={1}>
        
       {props?.type === 'internal' &&
        <Form.Select
          size="sm"
          className="dashboardSelect"
          name="minorSubCategory"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.minorSubCategory}
        >
          <option value="">Select Minor Sub Category</option>
          <CreateOption array = {minorSubCategoryOption}/>
        </Form.Select>
        
       }
       
        
        {props?.type === 'resource' ?
          <Form.Select
          size="sm"
          className="dashboardSelect"
          name="resource"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.resource}
        >
          <option value="">Select Resource</option>
          <CreateOption array = {resourceTypeOption}/>
        </Form.Select>
        :
        <Form.Select
          size="sm"
          
          className="dashboardSelect"
          name="merchantName"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.merchantName}
        >
          <option value="">Select Merchant Name</option>
          <CreateOption array = {merchentOption}/>
        </Form.Select>
        }
        <Form.Select
          size="sm"
          className="dashboardSelect"
          name="clientName"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.clientName}
        >
          <option value="">Select Client Name</option>
          <CreateOption array = {clientOption}/>
        </Form.Select>
        {(props?.type === 'resource'  || props?.type === 'external') &&
        <Form.Select
          size="sm"
          className="dashboardSelect"
          name="projectName"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.projectName}
        >
          <option value="">Select Project Name</option>
          <CreateOption array = {projectTypeOption}/>
        </Form.Select>
        
       }
      </Flex>
        </>
        :
        
          props?.type === 'tds' ?
        
          <Flex align="center" w="130%" px="0px" py="0px">
       
       
       <Form.Select
         size="sm"
         className="dashboardSelect"
         name="resource"
         onChange={(e) => {
           props.handleFilter(e);
         }}          
         defaultValue={props?.filterData?.resource}
       >
         <option value="">Select Resource</option>
         <CreateOption array = {resourceTypeOption}/>
       </Form.Select>
       <Form.Select
         size="sm"
         className="dashboardSelect"
         name="referenceResourcePO"
         onChange={(e) => {
           props.handleFilter(e);
         }}          
         defaultValue={props?.filterData?.referenceResourcePO}         
       >
         <option value="">Reference Resource PO</option>
         <CreateOption array = {poNoResource}/>
       </Form.Select>
       
       
     </Flex>
        :
        <Flex align="center" w="130%" px="0px" py="0px">
       
       
        <Form.Select
          size="sm"
          className="dashboardSelect"
          name="receiverName"
          onChange={(e) => {
            props.handleFilter(e);
          }}          
          defaultValue={props?.filterData?.receiverName}
        >
          <option value="">Select Receiver Name</option>
          <CreateOption array = {receiverOption}/>
        </Form.Select>
        
        
      </Flex>
        
        
      }

      <Box h="500px" mt="auto">
        {valueDifference !== null && option.length > 0 && (
          activeType === 'bar' ?
          <BarChart
            chartData={barChartDataConsumption(value)}
            chartOptions={valueDifference}
          />
          :

          <LineChart
            chartData={barChartDataConsumption(value)}
            chartOptions={valueDifference}
          />
          
        )}
      </Box>
      
    </Card>
  );
}
