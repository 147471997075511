import React from 'react';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box
} from '@chakra-ui/react';
import { SearchIcon,ChevronDownIcon } from '@chakra-ui/icons';

export function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const {
    variant,
    background,
    children,
    placeholder,
    borderRadius,
    onChange,
    dropdownItems,
    handleIsAdvance,
    currentLocation,
    value,
    ...rest
  } = props;

  // Chakra Color Mode
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const pathArray = ["/dashboard","/all-category","/vendor","/embrill-profile","/client-project","/users"]

  return (
    <InputGroup w={{ base: '100%', md: '200px' }} {...rest} className='search-bar'>
      <InputLeftElement>
        <Menu className="search-menu">
        
          <MenuButton
            
            as={IconButton}
            bg='inherit'
            mb={2}
            _hover='none'
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            icon={<Box className="menu-button"><SearchIcon color={searchIconColor} w='15px' h='15px' /><ChevronDownIcon color="gray.500" w='15px' h='15px' /></Box>}
          />
         
        
          <MenuList>
           {(currentLocation && !pathArray.includes(currentLocation)) && 
          <MenuItem className='search-menu-item' onClick={() => handleIsAdvance()}>
              <SearchIcon color="blue.500" w='15px' h='15px' style={{ marginRight: '8px' }} />
              Advanced Search
            </MenuItem>
          }
          </MenuList>
        </Menu>
      </InputLeftElement>

      <Input
        variant='search'
        fontSize='sm'
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: 'gray.400', fontSize: '14px' }}
        placeholder={placeholder ? placeholder : 'Search...'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value || ""}
      />
    </InputGroup>
  );
}
