import React, { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Input,
  Button,
  IconButton,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons"; // Close icon
import { AiFillPlusCircle, AiFillEdit, AiOutlineSave, AiOutlineClose, AiFillDelete } from "react-icons/ai"; // Icons
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications"; // Import addToast
import { fetchAsyncAddProject, fetchAsyncEditProject, fetchAsyncDeleteProject } from "redux/clientProjectSlice"; // Import your redux actions
import { FaPen } from "react-icons/fa";

const ProjectPopover = ({ clientId, projects, refreshData ,role}) => {
  const [projectName, setProjectName] = useState("");
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [editingProjectName, setEditingProjectName] = useState("");
  const dispatch = useDispatch();
  const { addToast } = useToasts(); // Initialize addToast

  const handleAddProject = async () => {
    if (projectName.trim()) {
      try {
        const res = await dispatch(fetchAsyncAddProject({ id: clientId, projectName }));
        if (res?.payload) {
          refreshData();
          setProjectName(""); // Clear the input field
          addToast("Project added successfully!", { appearance: "success" });
        }
      } catch (error) {
        addToast("Error adding project.", { appearance: "error" });
      }
    }
  };

  const handleEditProject = async (projectId) => {
    if (editingProjectName.trim()) {
      try {
        const res = await dispatch(fetchAsyncEditProject({ id: clientId, projectId, projectName: editingProjectName }));
        if (res?.payload) {
          refreshData();
          setEditingProjectId(null); // Reset editing state
          setEditingProjectName(""); // Clear the input field
          addToast("Project updated successfully!", { appearance: "success" });
        }
      } catch (error) {
        addToast("Error updating project.", { appearance: "error" });
      }
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      const res = await dispatch(fetchAsyncDeleteProject({ id: clientId, projectId }));
      if (res?.payload) {
        refreshData();
        addToast("Project deleted successfully!", { appearance: "success" });
      }
    } catch (error) {
      addToast("Error deleting project.", { appearance: "error" });
    }
  };

  return (
    <Popover placement="top">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
           
            <button type="button"  class="btn btn-secondary position-relative">
            <FaPen size={8}/>
            <span class="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-info text-dark">
            {projects && projects?.length ? projects?.length : 0}
            </span>
            </button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader display="flex" justifyContent="space-between">
              <span>Manage Projects</span>
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close"
                size="sm"
                onClick={onClose} // Close the popover
              />
            </PopoverHeader>
            <PopoverBody>
              <Input
                placeholder="New Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                mb={2}
              />
              <Button onClick={handleAddProject} colorScheme="blue" size="sm" mb={3}>
                Add Project
              </Button>
              <List spacing={3}>
                {projects.map((project) => (
                  <ListItem key={project._id} display="flex" alignItems="center">
                    <ListIcon as={AiFillPlusCircle} color="green.500" />
                    {editingProjectId === project._id ? (
                      <>
                        <Input
                          value={editingProjectName}
                          onChange={(e) => setEditingProjectName(e.target.value)}
                          size="sm"
                          mr={2}
                        />
                        <IconButton
                          icon={<AiOutlineSave />}
                          aria-label="Save"
                          size="sm"
                          colorScheme="green"
                          onClick={() => handleEditProject(project._id)}
                          mr={2}
                        />
                        <IconButton
                          icon={<AiOutlineClose />}
                          aria-label="Cancel"
                          size="sm"
                          colorScheme="red"
                          onClick={() => {
                            setEditingProjectId(null); // Cancel edit
                            onClose(); // Close the popover
                          }}
                        />
                      </>
                    ) : (
                      <>
                         <span style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px', // Set max width for truncation
                        }}>
                          {project.projectName}
                        </span>
                        {role === 'Admin' &&
                        <>

                        <IconButton
                          icon={<AiFillEdit />}
                          aria-label="Edit"
                          size="sm"
                          colorScheme="yellow"
                          ml={2}
                          onClick={() => {
                            setEditingProjectId(project._id);
                            setEditingProjectName(project.projectName);
                          }}
                        />
                        <IconButton
                          icon={<AiFillDelete />}
                          aria-label="Delete"
                          size="sm"
                          colorScheme="red"
                          ml={2}
                          onClick={() => handleDeleteProject(project._id)}
                        />
                        </>}
                      </>
                    )}
                  </ListItem>
                ))}
              </List>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ProjectPopover;
