/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  Box
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

import { createListForSingleOption } from "./createableSelect";
import { createOption } from "./createableSelect";

import calculateTds from "jsFunctions/calculateTds";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import { Option } from "antd/es/mentions";
import { useToasts } from "react-toast-notifications";
import { fetchAsyncAddResourceExpense } from "redux/resourceSlice";
import PaymentInfoComponent from "./paymentInfoComponent";
import { fetchAsyncGetPONumresourceExpense } from "redux/resourceSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import Swal from "sweetalert2";
import { fetchAsyncGetPanResourceExpense } from "redux/resourceSlice";
import { fetchAsyncAddPanWithResourceExpense } from "redux/resourceSlice";
import moment from "moment";
import { fetchAsyncGetPONumTDSExpense } from "redux/tdsSlice";
import CustomSelect from "components/CustomSelect";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { fetchAsyncUploadResourcePdf } from "redux/resourceSlice";
import { fetchAsyncDeleteResourcePdf } from "redux/resourceSlice";
import { fetchAsyncUpdateResourceExpense } from "redux/resourceSlice";
import DataChangeModalOnEdit from "components/models/DataChangeModalOnEdit";
import { ResourceFormValidation } from "validation";
import { fetchAsyncAddResourceInvoiceRemark } from "redux/resourceSlice";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { checkApprovalAmount } from "jsFunctions/helperFunctions";
import { checkTotalApprovedAmountWithSpentAmount } from "jsFunctions/helperFunctions";
import { fetchAsyncGetMinorSubCategory } from "redux/formOptions";
import { fetchAsyncCreateMinorSubCategory } from "redux/formOptions";
import { fetchAsyncGetSubCategory } from "redux/formOptions";
import { fetchAsyncEditMinorSubCategory } from "redux/formOptions";
import { fetchAsyncEditSubCategory } from "redux/formOptions";
import { fetchAsyncDeleteSubCategory } from "redux/formOptions";
import { fetchAsyncCreateSubCategory } from "redux/formOptions";
import { fetchAsyncDeleteMinorSubCategory } from "redux/formOptions";
import { fetchAsyncRejectResourceTotalPayAmount } from "redux/resourceSlice";
import { fetchAsyncApproveResourceTotalPayAmount } from "redux/resourceSlice";
import { fetchAsyncSendRequestApprovalResourceTotalPayAmount } from "redux/resourceSlice";
import { fetchAsyncSendRequestUpdateApprovalResourceTotalPayAmount } from "redux/resourceSlice";
import { fetchAsyncDeleteResourcePaymentListItem } from "redux/resourceSlice";
import { checkSpentAmountisEmpty } from "jsFunctions/helperFunctions";
// modal style
import { fetchAsyncGetTDSExpenseByRefrencePO } from "redux/tdsSlice";
import { InputGroup, OverlayTrigger, Popover, Tab, Tabs } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { fetchAsyncGetGstCompanyExpense } from "redux/resourceSlice";
import ClientProjectSelect from "components/ClientProjectSelect";
import { clientProjectOptions } from "jsFunctions/clientProjectOptions";
import GstInfoComponent from "./gstInfoComponent";
import { fetchAsyncDeleteResourceGstListItem } from "redux/resourceSlice";
import RefundInfoListComponent from "./refundListInfoComponent";
import { fetchAsyncDeleteResourceRefundListItem } from "redux/resourceSlice";
import { fetchAsyncGetClient } from "redux/clientProjectSlice";
import { fetchAsyncGetVendorByID } from "redux/vendorSlice";
import { fetchAsyncGetVendor } from "redux/vendorSlice";
import QuotationForm from "./seprateFormComponent/quatationForm";
import { FaCheck, FaFileUpload, FaPlusCircle } from "react-icons/fa";
import { fetchAsyncGetAllQuotation } from "redux/quotationSlice";
import { fetchAsyncGetTotalPayAmountByPanNumber } from "redux/vendorSlice";
import PanNumberField from "./seprateFormComponent/panNumberField";
import DownloadExcelFile from "./DownloadExcel/downloadExcel";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});

const ResourceAddNew = ({
  resourceOpenModal,
  resourceCloseModal,
  isEditForm = false,
  editItem,  
  refreshEditData,
  activeList,
  role
  
}) => {
  const [poPrefix, setPoPrefix] = useState({
    prefix: "PR-S-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const formOptionsList1 = useSelector((state) => state.formOptions);
  const subCategoryList1 = useSelector((state) => state.subCategory);
  
  const [isEdit, setIsEdit] = useState(isEditForm)
  const [isGst, setIsGst] = useState(false);
  const [editData, setEditData] = useState(editItem)
  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
  const [generatedNum, setGeneratedNum] = useState("PR-S-");
  const [fullTdsData, setFullTdsData] = useState({})
  const [formOptionsList, setFormOptionsList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [isTds, setIsTds] = useState(false);
  const [amountTotal, setAmountTotal] = useState('');
  const [tdsAmount, setTdsAmount] = useState(0);
  const [minorTypeOption, setMinorTypeOption] = useState([]);
  const [minorSubCategoryOption, setMinorSubCategoryOption] = useState([]);
  const [approveOrRejectRemarks, setApproveOrRejectRemarks] = useState('')
  const [multipleAddRefundInfo, setMultipleAddRefundInfo] = useState([""]);
  const [totalSpentAmount, setTotalSpentAmount] = useState(0)
  const [totalRefundAmount, setTotalRefundAmount] = useState(0)
  const [isReimbursement, setIsReimbursement] = useState(false);
  const [editTDSAmount, setEditTDSAmount] = useState(true);
  const [tdsPercent, setTdsPercent] = useState(10);
  const [editPan, setEditPan] = useState(true);
  const [editPercentNum, setEditPercentNum] = useState(true);
  const [isGenerateTDSPo, setIsGenerateTDSPo] = useState(false);
  const [clientValue, setClientValue] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [resourceValue, setResourceValue] = useState("");
  const [expenseValue, setExpenseValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const dispatch = useDispatch();
  const [invoiceDate, setInvoiceDate] = useState();
  const [multipleInvoiceData, setMultipleInvoiceData] = useState([]);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [approvalAmount, setApprovalAmount] = useState('');
  const [remarksValue, setRemarksValue] = useState('');
  const [errors, setErrors] = useState({})
  const [panNumber, setPanNumber] = useState("");

  const [sendPayAmountRequest, setSendPayAmountRequest] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState()
  const [reimbursementAmount, setReimbursementAmount] = useState('')

  const [multipleAddPaymentInfo, setMultipleAddPaymentInfo] = useState([""]);
  const [multipleAddGstInfo, setMultipleAddGstInfo] = useState([""]);
  const [oldResourceName, setOldResourceName] = useState("")

  const [clientOPtion, setClientOption] = useState([]);
  const [projectOption, setProjectOption] = useState([]);
  const [minorTypeValue, setMinorTypeValue] = useState("");
  const [officeTypeValue, setOfficeTypeValue] = useState("");
  const [resourceOption, setResourceOption] = useState([]);
  const [sourceOption, setSourceOption] = useState([]);
  const [expenseOption, setExpeneOption] = useState([]);
  const [minorSubCategoryValue, setMinorSubCategoryValue] = useState("");
  const [totalPayAmountIsApproved, setTotalPayAmountIsApproved] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // data  change modal
  const [compareDataModal, setCompareDataModal] = useState(false);
  const [comparingData, setComparingData] = useState(null);
  const [generatedTDSpoNum, setGeneratedTDSpoNum] = useState("PR-T-");
  const [tdsPoPrefix, setTdsPoPrefix] = useState({
    prefix: "PR-T-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const [totalPayAmountByPanNumber, setTotalAmountByPanNumber] = useState({})

  const [openQuotation, setOpenQuotation] = useState(false)

  const [quotationData, setQuotationData] = useState({
    remarks:"",
    amount:"",
    files:""
  })

  const [allQuotation,setAllQuotation] = useState([])

  const { addToast } = useToasts();
  const currUser = useSelector((state) => state.auth.currUser);
  const clientData = useSelector((state) => state.clientData);
  const vendorList = useSelector((state) => state.vendorData);

  const closeComparedDataModal = () => {
    setCompareDataModal(false);

    setComparingData(null);
  };
  const resetAllStateOnCloseModal = () => {
    setEditPan(true);
    setTdsPercent(10);
    setIsReimbursement(false);
    setIsTds(false);
    setEditPan(true);
    setEditTDSAmount(true);
    setEditPercentNum(true);
  };


  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === 'resource')
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

  useEffect(()=>{
    if(subCategoryList1 && subCategoryList1?.length > 0){
      const internalSubCategoryList = subCategoryList1.filter((item)=> item.expenseType === 'resource')
      setSubCategoryList(internalSubCategoryList)
    }
  },[subCategoryList1])

  useEffect(() => {
    if( amountTotal && tdsPercent ){

      setTdsAmount(calculateTds(amountTotal, tdsPercent));
    }
    else{
      setTdsAmount(0)
    }
  }, [amountTotal, tdsPercent]);

  const handleEditTDSPercentange = () => {
    setEditPercentNum(false);
  };
  const handleEditTDS = () => {
    setEditTDSAmount(false);
  };
  const handleEditSubmit = () => {
    setEditTDSAmount(true);
  };
  const handleEditPAN = () => {
    setEditPan(false);
  };

  const finalRef = useRef(null);

  // SET PO NUMBER

  useEffect(() => {
    setGeneratedNum(poNumberFormate(poPrefix));
  }, [poPrefix]);

  useEffect(() => {
    if (isEdit && editData) {
      if (!fullTdsData?.referenceResourcePO) {
        setGeneratedTDSpoNum(poNumberFormate(tdsPoPrefix));
      }
    } else {
      setGeneratedTDSpoNum(poNumberFormate(tdsPoPrefix));
    }
  }, [tdsPoPrefix, isGenerateTDSPo]);

  const submitUpdateData = (formData, editData) => {
    dispatch(
      fetchAsyncUpdateResourceExpense({ id: editData._id, data: formData })
    )
      .then((res) => {
        if (res.payload) {
          addToast(`Expense ${generatedNum} updated successfully!`, { appearance: "success" });
        }
        resourceCloseModal();
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        addToast("Error", { appearance: "error" });
      });
  };
  useEffect(() => {
    if (!isEdit && getPoMonth) {
      const dataSplit = getPoMonth.split("-");
      dispatch(
        fetchAsyncGetPONumresourceExpense({
          month: dataSplit[1],
          year: dataSplit[0],
        })
      )
        .then((res) => {
          if (res.payload?.[0]?.poNo) {
            if(fullTdsData?.referenceResourcePO){
              setTdsPoPrefix((prev) => {
                return {
                  prefix: res.payload?.[0]?.poNo.prefix,
                  month: res.payload?.[0]?.poNo.month,
                  year: res.payload?.[0]?.poNo.year,
                  number: res.payload?.[0]?.poNo.number,
                };
              });
            }
            else{

              setPoPrefix((prev) => {
                return {
                  prefix: prev.prefix,
                  month: dataSplit[1],
                  year: dataSplit[0],
                  number: res.payload?.[0]?.poNo.number + 1,
                };
              });
            }
          } else {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: 1,
              };
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getPoMonth]);

  const getAllCompanyOptions = async()=>{
    const filteredVendors = vendorList.filter(vendor => vendor.isConsultant === true);

      const companyOption = await clientProjectOptions('companyName','_id',filteredVendors) 
      setResourceOption(companyOption);
  }

  useEffect(()=>{
    if(vendorList){
      getAllCompanyOptions()
    }
  },[vendorList])
  useEffect(() => {
    dispatch(fetchAsyncGetClient())
    dispatch(fetchAsyncGetVendor());

  }, []);

  // TDS PO BY Resource
  useEffect(() => {
    const dataSplit = getPoMonth.split("-");
    const year =
      parseInt(dataSplit[1]) === 12
        ? parseInt(dataSplit[0]) + 1
        : parseInt(dataSplit[0]);
    let month = parseInt(dataSplit[1]) === 12 ? 1 : parseInt(dataSplit[1]) + 1;
    dispatch(
      fetchAsyncGetPONumTDSExpense({
        month: month,
        year: year,
      })
    )
      .then((res) => {
        if (res.payload?.[0]?.poNo) {
          if(fullTdsData?.referenceResourcePO){
            setTdsPoPrefix((prev) => {
              return {
                prefix: res.payload?.[0]?.poNo.prefix,
                month: res.payload?.[0]?.poNo.month,
                year: res.payload?.[0]?.poNo.year,
                number: res.payload?.[0]?.poNo.number,
              };
            });
          }
          else{

            setTdsPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: month,
                year: year,
                number: res.payload?.[0]?.poNo.number + 1,
              };
            });
          }
        } else {
          setTdsPoPrefix((prev) => {
            return {
              prefix: prev.prefix,
              month: month,
              year: year,
              number: 1,
            };
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [isGenerateTDSPo, getPoMonth]);
  const initialValues = {
    poNo: "",
    orderMonth: "",
    orderDate: "",
    clientName: "",
    projectName: "",
    resourceName: "",
    remarks: "",
    expense: "",
    approvalAmount: 0,
    gstNumber:"",
    payee: 0,
    paymentAmount: 0,
    bank: "",
    referance: "",
    date: null,
   
    isTDS: false,
    totalAmount: 0,
    tdsPercent: 0,
    totalTDS: 0,

    isTDSPO: false,
    TDSPONum: "",

    source: "",
    invoiceNum: editData?.reimbursementInfo?.invoiceNum || "",
    invoiceDate: null,
    reimbursementAmount: "",
    isRefund:
    editData?.refundInfo?.isRefund|| false,

    invoice: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ResourceFormValidation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {

      if (!clientValue || !minorTypeValue  || !projectValue || !resourceValue || !expenseValue  || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
        setErrors({
          ...errors,
          projectValue:'Project name is required!',
          minorTypeValue:'Minor type is required!',
          resourceValue:'Resource name name is required!',
          expenseValue:'Major type is required!',
          clientValue:'Client name is required!',
          minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
        }) 
        addToast("Please fill all mandatory fields", { appearance: "error" });
        return;
      }
     

      // const checkSpent = checkSpentAmountisEmpty(
      //   multipleAddPaymentInfo,
      // );
      // if (!checkSpent.status) {
      //   addToast(checkSpent.msg, { appearance: "error" });
      //   return;
      // }
      const checkApproval = checkApprovalAmount(
        approvalAmount,
      );
      if (!checkApproval.status) {
        setErrors({
          ...errors,
          approvalAmount:checkApproval.msg
        })        
        return;
      }
      const filteredMultipleAddPaymentInfo = multipleAddPaymentInfo.filter((item) => item?.amount);

      const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)

      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        total_pay_amount,
        filteredMultipleAddPaymentInfo
      );

      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        // addToast(checkValid.msg, { appearance: "error" });
        return;
      }
      if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
        return;
      }
      let formData = new FormData();
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
      formData.append("clientName", clientValue.value || "");
      formData.append("projectName", projectValue.value || "");
      formData.append("minorType", minorTypeValue.value);
      formData.append("officeType", officeTypeValue.value);
      formData.append("minorTypeCategory", minorSubCategoryValue.value ? minorSubCategoryValue.value : '');
      formData.append("resourceId", resourceValue.value || "");
      formData.append("panNumber", panNumber);
      formData.append("remarks", remarksValue);
      formData.append("expenseType", expenseValue.value);
      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
      formData.append("isRefund", values.isRefund);
      const filteredMultipleAddRefundInfoPay = multipleAddRefundInfo.filter((item) => item?.amount);

      formData.append("refundInfo", JSON.stringify(filteredMultipleAddRefundInfoPay));

      const filteredMultipleAddPaymentInfoPay = filteredMultipleAddPaymentInfo.map((item)=>{
        if(item?.invoice){
          return {
            ...item,
            invoice: item?.invoice.filter(entry => 'Key' in entry)
          }
        }
        else{
          return item
        }
      });
      
      formData.append("isGST", isGst);

      formData.append("payInfo", JSON.stringify(filteredMultipleAddPaymentInfoPay));
     
      formData.append("isTDS", isTds);
      amountTotal && formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
      formData.append("isTDSPO", isGenerateTDSPo);
      formData.append("TDSPONum", JSON.stringify(tdsPoPrefix));
      formData.append("isReimbursement", isReimbursement);
      formData.append("source", sourceValue.value);
      formData.append("invoiceNum", values.invoiceNum);
      invoiceDate && formData.append("invoiceDate", invoiceDate);
      formData.append("amount", reimbursementAmount);
      formData.append("gstInfo", JSON.stringify(multipleAddGstInfo));

      if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
        formData.append('quotation_remarks', quotationData?.remarks);
       formData.append('quotation_amount', quotationData?.amount);
       Array.from(quotationData?.files).forEach((file) => {
           formData.append('quotation_files', file);
       });

     }
      if (isEdit) {
        setComparingData(formData);
      } else {
        multipleInvoiceData.forEach((element, i) => {
          formData.append(`file${i}`, element);
        });

        dispatch(fetchAsyncAddResourceExpense(formData))
          .then((res) => {
            if (res?.payload) {
              resourceCloseModal();
              resetForm();
              addToast("Successfully added", { appearance: "success" });
            } else {
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
  });
 
  const handleInvoiceChange = (data) => {
    // setOpenInvoice(true);
    // if (multipleInvoiceData?.length < 10) {
    //   setMultipleInvoiceData([
    //     ...multipleInvoiceData,
    //     { data: "", invNumber: data.data },
    //   ]);
    // }

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === data?.id) {

        let invoice = []

        if(item?.invoice){
           if (item?.invoice?.length < 5) {
          invoice = [
            ...item?.invoice,
            { data: "", invNumber: data.data }
          ]
        }
        else{
          invoice = item?.invoice
        }
        }
        else{
          invoice = [
            { data: "", invNumber: data.data }
          ]
        }

        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handleUpdateInvoiceData = (invoiceData, index) => {
    const updateList = multipleInvoiceData.map((item, i) => {
      if (i === index) {
        return invoiceData;
      } else return item;
    });
    setMultipleInvoiceData([...updateList]);
  };

  const handelDeleteInvoice = (index,id) => {
    // const updateList = multipleInvoiceData.filter((item, i) => i !== index);
    // setMultipleInvoiceData([...updateList]);
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === id) {

        let invoice = []
        if(item?.invoice){

          invoice = item?.invoice.filter((item, i) => i !== index)
        }
        
        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };

  // payment info function
  const handleMultiplePayInfo = (data) => {
    setMultipleAddPaymentInfo([
      ...multipleAddPaymentInfo,
      { data: "", paydata: data.data,invoice:[] },
    ]);
  };
  // payment info function
  const handleMultipleGstInfo = (data) => {
    setMultipleAddGstInfo([
      ...multipleAddGstInfo,
      ""
    ]);
  };
  const handleUpdateMultiplePayData = (payData, index) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handleMultipleRefundInfo = (data) => {
    setMultipleAddRefundInfo([
      ...multipleAddRefundInfo,
      { data: "", paydata: data.data },
    ]);
  };
  const handleUpdateMultipleRefundData = (payData, index) => {
    const updateList = multipleAddRefundInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddRefundInfo([...updateList]);
  };


  const handleUpdateMultipleGstData = async(gstData, index) => {
    let gstNum = ""
    const companyId = gstData?.companyName?.value
    if (companyId) {
      await dispatch(fetchAsyncGetVendorByID(companyId)).then((res)=>{

        if(res?.payload){
          const {gstNo} = res?.payload?.vendor;  
          gstNum = gstNo;
        }
        }).catch((err)=>{
          console.log(err)
        })
    }
    
    const gstFullData = {
      baseAmount:gstData?.baseAmount,
      companyId:companyId,
      gstNumber:gstNum,
      gstAmount:gstData?.gstAmount || {},
      gstType: gstData?.gstType,
      gstPercentage: gstData?.gstPercentage
    }
    const updateList = multipleAddGstInfo.map((item, i) => {
      if (i === index) {
        return {...item,gstFullData};
      } else return item;
    });

    setMultipleAddGstInfo([...updateList]);
  };
  const getAllClientOptions = async()=>{
    if(clientData){

      const clientOption = await clientProjectOptions('clientName','_id',clientData) 
      setClientOption(clientOption);
    }
  }

  const getAllProjectOptions = async(clientId)=>{
    const client = clientData.find(client => client._id === clientId);
    if(client){

      const projects = client?.projects || []
      if(projects){
        const projectOption = await clientProjectOptions('projectName','_id',projects) 
        setProjectOption(projectOption);
        setProjectValue("");
        if (isEdit && editData && editData?.projectName && editData.projectName?._id) {
              const subCat = projectOption.filter(
                (option) => option.value === editData.projectName?._id
              );
              if (subCat[0]) {
                setProjectValue({
                  label: subCat[0].label,
                  value: subCat[0].value,
                });
              }
            }
      }
     
    }
}

 // Set MinorCategory for selected majorType value
 useEffect(() => {
  if (clientValue && clientValue?.value) {
    getAllProjectOptions(clientValue?.value)
  }
}, [clientValue]);

  // form list option
  useEffect(() => {
   
    getAllClientOptions()

    let expenseList = createListForSingleOption(formOptionsList, "expense");
    let sourceList = createListForSingleOption(formOptionsList, "source");

    setSourceOption(sourceList);
    setExpeneOption(expenseList);
  }, [formOptionsList]);

  // create new function
  const createNewFormOption = (data, type) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        expenseType:'resource'

      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
          };
          

         
        
          if (type === "expense") {
            setExpenseValue(setData);
          }

          if (type === "source") {
            setSourceValue(setData);
          }
          Swal.fire({
            title: "Successfully Added",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {});
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      });
  };

 

  const getCompanyByID = async(id)=>{
    await dispatch(fetchAsyncGetVendorByID(id)).then((res)=>{

    if(res?.payload){
      const vendor = res?.payload?.vendor;
      const setData = {
        label: vendor.companyName,
        value: vendor?._id,
      };
            
      setResourceValue(setData);
      setPanNumber(vendor?.panNo)
    }
    }).catch((err)=>{
      console.log(err)
    })
  }
  
  const getTotalPayAmount = async(panNo)=>{
    await dispatch(fetchAsyncGetTotalPayAmountByPanNumber(panNo)).then((res)=>{
  
      if(res?.payload){
      setTotalAmountByPanNumber(res?.payload)
        
      }
      }).catch((err)=>{
        setTotalAmountByPanNumber({})
  
        console.log(err)
      })
  }
  useEffect(()=>{
    if(panNumber){
      getTotalPayAmount(panNumber)
    }
  },[panNumber])
  const isValidObjectId = (id) => {
    return /^[a-f0-9]{24}$/.test(id);
};
  useEffect(() => {
    if(resourceValue && resourceValue?.value){
      const id = resourceValue?.value;
      if(isValidObjectId(id)){

        dispatch(fetchAsyncGetVendorByID(id)).then((res)=>{
  
          if(res?.payload){
            const {panNo} = res?.payload?.vendor;  
            setPanNumber(panNo)
          }
          }).catch((err)=>{
            console.log(err)
          })
        }
      }

  }, [resourceValue]);

  
  
  const deleteFromOption = (option, type) => {
   
      let data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    
    if (data?.value) {
      dispatch(fetchAsyncGetSubCategory(data.value)).then(
        (response) => {
          if (response.payload) {
            const minorOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'resource');
          
            if (minorOption && minorOption?.length > 0) {
              addToast(`Please delete all related Minor Type first`, { appearance: "error" });
            }
            else{
              dispatch(fetchAsyncDeleteFormOptions(data._id)).then((res)=>{
                console.log(res,'response')
              });
            }
          }
        }
      );
    }
   
  };


  const editFromOption = (option,newValue, type) => {
   
      let data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    
   
    
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:'resource'

      }
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
        };
      
       
        
        if (type === "expense") {
          setExpenseValue(setData);
        }

        if (type === "source") {
          setSourceValue(setData);
        }
       

       
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };
  // Changes on Edit

  const editFormPrefiledData = (formOptionsList, editData) => {
   
    const clientData = editData?.clientName
    if (clientData) {
      setClientValue({ label: clientData.clientName, value: clientData._id });
    }
   
    const officeData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "officeType",
      "office-type"
    );
    if (officeData && !officeTypeValue) {
      setOfficeTypeValue({
        label: officeData.label,
        value: officeData.value,
      });
    }
   

    const expense = getOptionDataOnEdit(
      formOptionsList,
      editData?.expense,
      "majorType",
      "expense"
    );
    if (expense) {
      setExpenseValue({
        label: expense.label,
        value: expense.value,
      });
    }
  };

  const getOptionDataOnEdit = (formOptionsList, editData, value, type) => {
    const data = formOptionsList.find(
      (item) => item.value === editData?.[value] && item.type === type
    );
    return data;
  };

  const handleQuotationData = (data)=>{
    setQuotationData(data)
  }

  const handleCloseQuotation = ()=>{
    setOpenQuotation(!openQuotation)
    if (isEdit && editData) {
    getAllQuotation(editData?._id)
    }
  }
  const getAllQuotation = async(_id)=>{
    await dispatch(fetchAsyncGetAllQuotation(_id)).then((res)=>{

        if(res?.payload){
            const quotations = res.payload?.quotations;
            setAllQuotation(quotations)
        }
        }).catch((err)=>{
          console.log(err)
        })
}


  useEffect(() => {
    // fill data in form on edit
    if (isEdit && editData) {
      getAllQuotation(editData?._id)

      setPoPrefix(editData.poNo);
      setSelectedDate(new Date(editData.orderDate));

      setGetPoMonth(
        `${editData.poNo.year}-${
          editData.poNo.month <= 9
            ? "0" + editData.poNo.month
            : editData.poNo.month
        }`
      );

      if(editData?.resourceName){
        setOldResourceName(editData?.resourceName)
      }
      if(editData?.resourceId && editData?.resourceId?._id){
        getCompanyByID(editData?.resourceId?._id)
      }
      else if(editData?.resourceName){
        const setData = {
          label: editData?.resourceName,
          value: editData?.resourceName,
        };
        setResourceValue(setData);
        setPanNumber(editData?.panNumber)
      }
      if (formOptionsList?.length > 0 || formOptionsList1?.length > 0) {
        editFormPrefiledData(formOptionsList, editData);
      }
      if (editData?.reimbursementInfo?.amount) {
        setReimbursementAmount(editData?.reimbursementInfo?.amount);
      }

      if (editData.paymentInfo?.totalPayAmount) {
        setTotalPaymentAmount(editData.paymentInfo?.totalPayAmount);
      }
      if (editData.paymentInfo?.list && editData.paymentInfo?.list.length > 0) {
        setMultipleAddPaymentInfo(editData.paymentInfo?.list);
      }
      if (editData.paymentInfo?.payAmountApproveIsRequest) {
        setSendPayAmountRequest(true)
      }
      if (editData?.remarks) {
        setRemarksValue(editData?.remarks)
      }
      if (editData.refundInfo?.list && editData.refundInfo?.list.length > 0) {
        setMultipleAddRefundInfo(editData.refundInfo?.list);
      }
      if (editData?.approveRejectRemarks) {
        setApproveOrRejectRemarks(editData?.approveRejectRemarks)
      }
      if (editData?.gstApplicable && editData?.gstApplicable?.isGST) {
        setIsGst(editData?.gstApplicable?.isGST);
      }
      
     
       if(editData?.paymentInfo?.totalPayAmountIsApproved){
        setTotalPayAmountIsApproved(true)
      }
     
      if (editData.paymentInfo?.approvalAmount) {
        setApprovalAmount(editData.paymentInfo?.approvalAmount);
      }
      if (editData.gstApplicable?.gstId && editData.gstApplicable?.gstId?.length > 0) {
        setMultipleAddGstInfo(editData.gstApplicable?.gstId);
      }
    
      if (editData.reimbursementInfo?.invoiceDate) {
        setInvoiceDate(new Date(editData.reimbursementInfo.invoiceDate));
      }

      if(editData.tdsApplicable?.tdsPercent) {

        setTdsPercent(editData.tdsApplicable?.tdsPercent);
      }
      if(editData?._id){
        dispatch(fetchAsyncGetTDSExpenseByRefrencePO(editData?._id)).then((res)=>{
          const tdsData = res?.payload?.tdsExpense
          if (res?.payload && tdsData) {
            if (tdsData?.referenceResourcePO) {
              setIsTds(true);
              setAmountTotal(tdsData?.totalBillingAmount);
              setTdsAmount(tdsData?.tdsAmount);
              setTdsPercent(tdsData?.tdsPercent);
              setIsGenerateTDSPo(true);
              setGeneratedTDSpoNum(poNumberFormate(tdsData?.poNo));
            }
            setFullTdsData(tdsData);
          } else {
            if (editData.tdsApplicable?.isTDS) {
              setIsTds(editData.tdsApplicable?.isTDS);
              editData.tdsApplicable?.totalAmount &&
                setAmountTotal(editData.tdsApplicable?.totalAmount);
              editData.tdsApplicable?.totalTDS &&
                setTdsAmount(editData.tdsApplicable?.totalTDS);
              editData.tdsApplicable?.tdsPercent &&
                setTdsPercent(editData.tdsApplicable?.tdsPercent);
            }
            
          }
        }).catch((err)=>{
          console.log(err)
        })
      }
      if (editData.reimbursementInfo?.isReimbursement) {
        const { invoiceDate, isReimbursement } = editData.reimbursementInfo;
        setIsReimbursement(isReimbursement);
        invoiceDate && setInvoiceDate(new Date(invoiceDate));

        const sourceData = getOptionDataOnEdit(
          formOptionsList,
          editData.reimbursementInfo,
          "source",
          "source"
        );
        if (sourceData) {
          setSourceValue({
            label: sourceData.label,
            value: sourceData.value,
          });
        }
      }
      if (editData.invoice?.length > 0) {
        setMultipleInvoiceData([...editData.invoice]);
      } else {
        setMultipleInvoiceData([]);
      }
    }
  }, [isEdit, editData, formOptionsList]);

  const handleDeleteInvoiceOnEdit = (key,listIndex) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          key: key,
          listIndex:listIndex

        };
        dispatch(fetchAsyncDeleteResourcePdf(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.resourceExpense?.paymentInfo?.list)

          refreshEditData();
        });
      }
    });
  };

  const addNewInvoiceOnEdit = (file, index,listIndex) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (editData) {
          const lastInvoiceKey =
            editData.invoice?.length > 0 &&
            editData.invoice[editData.invoice.length - 1].Key;
          let number = index + 1;
          if (lastInvoiceKey) {
            number = lastInvoiceKey.split("PAY_")[1]?.split(".pdf")[0];
            number = parseInt(number) + 1;
          }
          let formData = new FormData();
          formData.append("poNo", JSON.stringify(editData.poNo));
          formData.append("number", number);
          formData.append("file", file);
          formData.append("resourceName", editData?.resourceName);
          formData.append("listIndex", listIndex);
          formData.append("index", index);
          const listItem = multipleAddPaymentInfo[listIndex];
          formData.append("listItem", JSON.stringify(listItem));
          dispatch(
            fetchAsyncUploadResourcePdf({ id: editData._id, formData })
          ).then((res) => {
            setMultipleAddPaymentInfo(res?.payload?.resourceExpense?.paymentInfo?.list)
            refreshEditData();
          });
        }
      }
    });
  };

   // Set SubCategory for selected minorype value
   useEffect(() => {
    if (minorTypeValue?.value) {
      dispatch(fetchAsyncGetMinorSubCategory(minorTypeValue.value)).then(
        (response) => {
          if (response.payload) {
            const minorSubOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'resource')
            setMinorSubCategoryOption(minorSubOption ? minorSubOption : []);
            setMinorSubCategoryValue("");
            if (isEdit && editData && minorSubOption?.length > 0) {
              const subCat = minorSubOption.filter(
                (option) => option.value === editData.minorSubCategory
              );
              if (subCat[0]) {
                setMinorSubCategoryValue({
                  label: subCat[0].label,
                  value: subCat[0].value,
                });
              }
            }
          }
        }
      );
    }
  }, [minorTypeValue]);

  const createMinorSubCategory = (category, value) => {
    const option = subCategoryList.find((data) => data.value === value);
    if (!option) return;

    dispatch(fetchAsyncCreateMinorSubCategory(
      { 
        id: option._id, 
        category,
        expenseType:'resource'
 
      })).then(
      (res) => {
        if (res.payload && res.payload.value) {
          setMinorSubCategoryOption((prev) => [
            ...prev,
            {_id:res.payload._id, label: res.payload.label, value: res.payload.value ,expenseType:res.payload?.expenseType},
          ]);
          setMinorSubCategoryValue({
            label: res.payload.label,
            value: res.payload.value,
          });
        }
      }
    );
  };
  const deleteMinorSubCategory = (option) => {
    const id = option?._id;
    dispatch(fetchAsyncDeleteMinorSubCategory(id)).then((res) => {
      if (res.payload && res.payload.value) {
        setMinorSubCategoryOption((prev) => [
          ...prev.filter((cat) => cat.value !== res.payload.value),
        ]);
      }
    });
  };
  const createSubCategory = (category, value) => {
    const option = formOptionsList.find((data) => data.value === value);
    if (!option) return;

    dispatch(fetchAsyncCreateSubCategory({ id: option._id, category, expenseType:'resource'
    })).then(
      (res) => {
        if (res.payload && res.payload.value) {
          setMinorTypeOption((prev) => [
            ...prev,
            {_id:res.payload._id, label: res.payload.label, value: res.payload.value,expenseType:res.payload?.expenseType },
          ]);
          setMinorTypeValue({
            label: res.payload.label,
            value: res.payload.value,
          });
        }
      }
    );
  };


  const deleteSubCategory = (option) => {
    const id = option?._id;
    const value = option?.value;
    if (value) {
      dispatch(fetchAsyncGetMinorSubCategory(value)).then(
        (response) => {
          if (response.payload) {
            const minorSubOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'resource')

            if (minorSubOption  && minorSubOption?.length > 0) {
              addToast(`Please delete all related Minor Sub Category first`, { appearance: "error" });
            }
            else{
              dispatch(fetchAsyncDeleteSubCategory(id)).then((res) => {
                if (res.payload && res.payload.value) {
                  setMinorTypeOption((prev) => [
                    ...prev.filter((cat) => cat.value !== res.payload.value),
                  ]);
                }
              });
            }
          }
        }
      );
    }
   
  };

  const editSubCategory = (option,newValue) => {
    dispatch(fetchAsyncEditSubCategory({
      id:option?._id,
      newData:{
        optionValue:newValue,
        expenseType:'resource'

      }
    })).then((res)=>{
      if (res.payload && res.payload.value) {
        if (expenseValue?.value) {
          dispatch(fetchAsyncGetSubCategory(expenseValue.value)).then(
            (response) => {
              if (response.payload) {
                const minorOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'resource');

                setMinorTypeOption(minorOption);
                if (minorOption.length > 0) {
                  const subCat = minorOption.filter(
                    (option) => option.value === newValue
                  );
                  if (subCat[0]) {
                    setMinorTypeValue({
                      label: subCat[0].label,
                      value: subCat[0].value,
                    });
                  }
                }
              }
            }
          );
        }
      }
    }).catch((err)=>{
      console.log(err)
    })
  };

  const editMinorSubCategory = (option,newValue) => {
    dispatch(fetchAsyncEditMinorSubCategory({
      id:option?._id,
      newData:{
        optionValue:newValue,
        expenseType:'resource'

      }
    })).then((res)=>{
      if (res.payload && res.payload.value) {
        if (minorTypeValue?.value) {
          dispatch(fetchAsyncGetMinorSubCategory(minorTypeValue.value)).then(
            (response) => {
              if (response.payload) {
                const minorSubOption = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'resource')

                setMinorSubCategoryOption(minorSubOption ? minorSubOption : []);
                if (minorSubOption?.length > 0) {
                  const subCat = minorSubOption.filter(
                    (option) => option.value === newValue
                  );
                  if (subCat[0]) {
                    setMinorSubCategoryValue({
                      label: subCat[0].label,
                      value: subCat[0].value,
                    });
                  }
                }
              }
            }
          );
        }
      }
    }).catch((err)=>{
      console.log(err)
    })
  };
   // Set MinorCategory for selected majorType value
   useEffect(() => {
    if (expenseValue?.value) {
      dispatch(fetchAsyncGetSubCategory(expenseValue.value)).then(
        (response) => {
          if (response.payload) {
            const minorOptions = response.payload && response.payload?.length && response.payload?.filter((item)=>item?.expenseType === 'resource')
            setMinorTypeOption(minorOptions ? minorOptions : []);
            setMinorTypeValue("");
            if (isEdit && editData && minorOptions?.length > 0) {
              const subCat = minorOptions.filter(
                (option) => option.value === editData.minorType
              );
              if (subCat[0]) {
                setMinorTypeValue({
                  label: subCat[0].label,
                  value: subCat[0].value,
                });
              }
            }
          }
        }
      );
    }
  }, [expenseValue]);
  // Changes on Edit End

  // add Remark for Invoice
  const addRemarkForInvoice = (value, item, listIndex, index) => {
    dispatch(
      fetchAsyncAddResourceInvoiceRemark({
        id: editData._id,
        remarkData: { key: item.Key, remark: value, listIndex, index },
      })
    ).then((res) => {
      setMultipleAddPaymentInfo(res?.payload?.resourceExpense?.paymentInfo?.list)

      refreshEditData();
    });
  };

  const handleAmountApproveBtn = (values)=>{   

    if(!isEdit){
  
      if ((sendPayAmountRequest && !clientValue)  || !minorTypeValue || (sendPayAmountRequest && !projectValue) || !resourceValue || !expenseValue || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
        setErrors({
          ...errors,
          projectValue:sendPayAmountRequest ? 'Project name is required!' : "",
          minorTypeValue:'Minor type is required!',
          resourceValue:'Resource name name is required!',
          expenseValue:'Major type is required!',
          clientValue:sendPayAmountRequest ? 'Client name is required!' : "",
          minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
        }) 
        addToast("Please fill all mandatory fields", { appearance: "error" });
           return;
         }
    }
     
    
    const checkApproval = checkApprovalAmount(
      approvalAmount,
    );
    if (!checkApproval.status) {
      setErrors({
        ...errors,
        approvalAmount:checkApproval.msg
      }) 
      return;
    }
  
    if(approvalAmount <= totalPaymentAmount && remainingAmount >= 0 ){
      const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        total_pay_amount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        // addToast(checkValid.msg, { appearance: "error" });
        return;
      }
    }

    if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
      return;
    }
    let formData = new FormData();
      
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
      formData.append("clientName", clientValue.value || "");
      formData.append("projectName", projectValue.value || "");
      formData.append("minorType", minorTypeValue.value);
      formData.append("officeType", officeTypeValue.value);
      formData.append("minorTypeCategory", minorSubCategoryValue.value ? minorSubCategoryValue.value : '');
      formData.append("resourceId", resourceValue.value || "");
      formData.append("panNumber", panNumber);
      formData.append("remarks", remarksValue);
      formData.append("expenseType", expenseValue.value);
      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
     
      formData.append("isTDS", isTds);
      formData.append("isGST", isGst);
    

      amountTotal && formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
      formData.append("isTDSPO", isGenerateTDSPo);
      formData.append("TDSPONum", JSON.stringify(tdsPoPrefix));
      formData.append("isReimbursement", isReimbursement);
      formData.append("source", sourceValue.value);
      formData.append("invoiceNum", values.invoiceNum);
      invoiceDate && formData.append("invoiceDate", invoiceDate);
      formData.append("amount", reimbursementAmount);
      formData.append("isRefund", values.isRefund);

      if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
        formData.append('quotation_remarks', quotationData?.remarks);
       formData.append('quotation_amount', quotationData?.amount);
       Array.from(quotationData?.files).forEach((file) => {
           formData.append('quotation_files', file);
       });

     }
      if(isEdit && editData){
        dispatch(fetchAsyncSendRequestUpdateApprovalResourceTotalPayAmount
          ({ 
          id: editData._id,
          data:formData
         })).then(
          (res) => {
            if (res.payload && res.payload.updatedData) {
              setIsEdit(true)
              setEditData(res.payload.updatedData)
              setSendPayAmountRequest(true)
              addToast(`Resend updated request for approval for expense ${generatedNum} !`, { appearance: "success" });
              resourceCloseModal();
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
  
          
          }
        );
      }
      else{
  
        dispatch(fetchAsyncSendRequestApprovalResourceTotalPayAmount(formData)).then((res) => {
            if (res.payload) {
              addToast(`Send request for approval for expense ${generatedNum} !`, { appearance: "success" });
              setIsEdit(true)
              setEditData(res.payload)
              setSendPayAmountRequest(true)
              resourceCloseModal();
  
            
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
          });
      }
      
      
      }
  const handleTotalPayAmountIsApproved = (values)=>{
    if(isEdit){
  
      if ((sendPayAmountRequest && !clientValue)  || !minorTypeValue || (sendPayAmountRequest && !projectValue) || !resourceValue || !expenseValue || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
        setErrors({
          ...errors,
          projectValue:sendPayAmountRequest ? 'Project name is required!' : "",
          minorTypeValue:'Minor type is required!',
          resourceValue:'Resource name name is required!',
          expenseValue:'Major type is required!',
          clientValue:sendPayAmountRequest ? 'Client name is required!' : "",
          minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
        }) 
        addToast("Please fill all mandatory fields", { appearance: "error" });
           return;
         }
    }
    const _id = editData._id;
    let formData = new FormData();
    formData.append("poNo", JSON.stringify(poPrefix));
    formData.append("orderMonth", getPoMonth);
    formData.append("orderDate", selectedDate);
    formData.append("clientName", clientValue.value ? clientValue.value : "");
    formData.append("projectName", projectValue?.value ? projectValue?.value : "");
    formData.append("minorType", minorTypeValue.value);
    formData.append("officeType", officeTypeValue.value);
    formData.append("minorTypeCategory", minorSubCategoryValue.value ? minorSubCategoryValue.value : '');
    formData.append("resourceId", resourceValue.value || "");
    formData.append("panNumber", panNumber);
    formData.append("remarks", remarksValue);
    formData.append("expenseType", expenseValue.value);
    formData.append("totalPaymentAmount", totalPaymentAmount);
    formData.append("approvalAmount", approvalAmount);
    formData.append("approveRejectRemarks", approveOrRejectRemarks);

    if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
      formData.append('quotation_remarks', quotationData?.remarks);
     formData.append('quotation_amount', quotationData?.amount);
     Array.from(quotationData?.files).forEach((file) => {
         formData.append('quotation_files', file);
     });

   }
    if(!editData.paymentInfo?.totalPayAmountIsApproved){
      Swal.fire({
        title: `Are You Sure? You Want To Approve Amount <span style="color: orange"><span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)}</span> For <span style="color:orange">${generatedNum}</span>.`,
        text: `Please confirm to proceed with the approval.`,        
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncApproveResourceTotalPayAmount({ 
            id: _id,
            data:formData,
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsApproved) {
                addToast(`Requested Amount for ${generatedNum} has been successfully approved.`, { appearance: "success" });
                resourceCloseModal();
              }
              
            }
          );
        }
      });
     
    }
  
  }

  const handleTotalPayAmountIsRejected = ()=>{
    const _id = editData._id;
   
    if(!editData.paymentInfo?.totalPayAmountIsApproved &&  !editData.paymentInfo?.totalPayAmountIsRejected){
      Swal.fire({
        title: `Are You Sure? You Want To Reject Amount <span style='color:orange'> <span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)} </span> For <span style="color:orange">${generatedNum}.</span>`,
        text: `Please confirm to reject. This action cannot be undone.`,      
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncRejectResourceTotalPayAmount({ 
            id: _id,
            data:{totalPayAmountIsRejected:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsRejected) {
                addToast(`Requested Amount for ${generatedNum} has been successfully rejected.`, { appearance: "success" });
                resourceCloseModal();
              }
              
            }
          );
        }
      });
     
    }
  
  }

  useEffect(()=>{
    if(multipleAddRefundInfo) {
      const array = multipleAddRefundInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalRefundAmount(parseFloat(sum))
        
      }
    }
    
  },[multipleAddRefundInfo])


  useEffect(()=>{
    if(multipleAddPaymentInfo) {
      const array = multipleAddPaymentInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalSpentAmount(parseFloat(sum))

        const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
        setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
      }
    }
  },[multipleAddPaymentInfo])


  useEffect(()=>{
    if(totalPaymentAmount){
      if(totalPaymentAmount && !editData?.tdsApplicable?.totalTDS && isTds){
        setAmountTotal(totalPaymentAmount)
        setIsTds(true)
      }
      else{
        if(totalPaymentAmount && editData?.tdsApplicable?.totalTDS && isTds){
        if(editData?._id){
          dispatch(fetchAsyncGetTDSExpenseByRefrencePO(editData?._id)).then((res)=>{
            const tdsData = res?.payload?.tdsExpense
            if (res?.payload && tdsData) {
              if (tdsData?.referenceResourcePO) {
                setIsTds(true);
                setAmountTotal(tdsData?.totalBillingAmount);
                setTdsAmount(tdsData?.tdsAmount);
                setTdsPercent(tdsData?.tdsPercent);
                setIsGenerateTDSPo(true);
                setGeneratedTDSpoNum(poNumberFormate(tdsData?.poNo));
              }
              setFullTdsData(tdsData);
            } else {
              if (editData.tdsApplicable?.isTDS) {
                setIsTds(editData.tdsApplicable?.isTDS);
                editData.tdsApplicable?.totalAmount &&
                  setAmountTotal(editData.tdsApplicable?.totalAmount);
                editData.tdsApplicable?.totalTDS &&
                  setTdsAmount(editData.tdsApplicable?.totalTDS);
                editData.tdsApplicable?.tdsPercent &&
                  setTdsPercent(editData.tdsApplicable?.tdsPercent);
              }
              
              
            }
          }).catch((err)=>{
            console.log(err)
          })
        }
      }
      }
    }
  },[isTds])

  useEffect(()=>{
    if(totalPaymentAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[tdsAmount || totalPaymentAmount])

  useEffect(()=>{
    if(!isTds){

      setAmountTotal(0)
      setTdsAmount(0)
      // setTdsPercent(10)
      setIsTds(false)

    }
  },[!isTds])
  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resourceCloseModal();
        resetAllStateOnCloseModal();
      }
    });
  };

  const handleRemarksChange = (e)=>{
    const value = e?.target?.value;
    const encoder = new TextEncoder(); // Default is 'utf-8'
    const byteLength = encoder.encode(value).length;
    if(byteLength <=128){

      setRemarksValue(value)
      setErrors({
        ...errors,
        remarks:''
      })
    }
    else{
      setErrors({
        ...errors,
        remarks:'Remarks must be within the 128 byte limit!'
      })
    }
  }

 
  const deleteMultipleListItem = (listIndex)=>{
    Swal.fire({
      title: "Are you sure? Delete Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteResourcePaymentListItem(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.resourceExpense?.paymentInfo?.list)
          refreshEditData();
        });
      }
    });
}


const deleteMultipleRefundListItem = (listIndex)=>{
  Swal.fire({
    title: "Are you sure? Delete Refund Amount Details Permanently.",
    text: ``,
    icon: "warning",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete",
    showCancelButton: true,
    customClass: {
      container: "sweet_containerImportant",
      confirmButton: "sweet_confirmbuttonImportant",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const sendData = {
        id: editData._id,
        listIndex:listIndex
      };
      dispatch(fetchAsyncDeleteResourceRefundListItem(sendData)).then((res) => {
        setMultipleAddRefundInfo(res?.payload?.resourceExpense?.refundInfo?.list)
        refreshEditData();
      });
    }
  });
}
const removeItemFromState = (index) => {
  // Update your local state to remove the item at the specified index
  setMultipleAddGstInfo(prevState => prevState.filter((_, i) => i !== index));
}
const deleteMultipleGstItem = (id,index)=>{
  if(id){

    Swal.fire({
      title: "Are you sure? Delete Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(fetchAsyncDeleteResourceGstListItem(id)).then((res) => {
          refreshEditData();
          removeItemFromState(index);
        });
      }
    });
  }
  else{
    removeItemFromState(index)
  }
}


const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      Amount Spent: ${200}
    </Popover.Body>
  </Popover>
);
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={resourceOpenModal}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          top="0" // Position it at the top
          margin="0 auto" // Center it horizontally
          bottom="0"
          className="add-form-modal-content"
        >
          <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
              {isEdit && "Edit "}Resource Expense
            </ModalHeader>
            <ModalCloseButton />
          </div>

          <ModalBody>
            <Form className="addForm" onSubmit={formik.handleSubmit}>
              <div className="form-card">
                <h5> Purchase Order Details</h5>
                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Number <span className="span-star">*</span>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="poNo"
                        placeholder="No"
                        value={generatedNum && generatedNum}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </Box>
                  </Col>

                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order - Month <span className="span-star">*</span>
                      </Form.Label>
                      <DatePicker
                        className="monthDate"
                        dateFormat="MM-yyyy"
                        showMonthYearPicker
                        name="orderMonth"
                        onChange={(e) => {
                          const dateTime = moment(e).format("yyyy-MM");
                          setGetPoMonth(dateTime);
                        }}
                        // defaultValue={new Date()}
                        selected={
                          getPoMonth ? new Date(moment(getPoMonth)) : ""
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Date <span className="span-star">*</span>
                      </Form.Label>
                      <DatePicker
                        name="orderDate"
                        className="monthDate"
                        dateFormat="dd-MMM-yyyy"
                        // disabled={isEdit}
                        onChange={(date) => {
                          setSelectedDate(date);
                        }}
                        selected={selectedDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box">

                    <Form.Label>Resource Name <span className="span-star">*</span></Form.Label>
                   
                    <ClientProjectSelect
                      options={resourceOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => {
                        setResourceValue(newValue);
                      }}
                      isSearchable
                      defaultValue={resourceValue}
                      role={role}
                    />
                  </Box>
                    {oldResourceName && (
                      <Form.Text className="text-muted previous_text">
                        <Box className="previous-text">
                          Previous Resource Name : {oldResourceName}
                        </Box>
                      </Form.Text>
                    )}

                    {errors?.resourceValue && !resourceValue && (
                      <p className="form_error">{errors?.resourceValue}</p>
                    )}
                  </Col>
                  <Col>
                  
                    <PanNumberField
                      panNumber={panNumber}
                      handlePanNumber={(pan) => setPanNumber(pan)}
                      totalPayAmount={totalPayAmountByPanNumber}
                      role={role}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-card mt-3">
                <h5>Expense</h5>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>Major Type <span className="span-star">*</span></Form.Label>
                    
                    <CustomSelect
                      options={expenseOption}
                      placeHolder={<div>Type or select...</div>}
                      onChange={(newValue) => setExpenseValue(newValue)}
                      isSearchable
                      onCreateOption={(e) => {
                        createNewFormOption(createOption(e), "expense");
                      }}
                      onDeleteOption={(option) => {
                        deleteFromOption(option, "expense");
                      }}
                      onEditOption={(option, newValue) => {
                        editFromOption(option, newValue, "expense");
                      }}
                      defaultValue={expenseValue}
                      role={role}
                      isInvalid={errors?.expenseValue && !expenseValue}
                    />
                    </Box>
                    {errors?.expenseValue && !expenseValue && (
                      <p className="form_error">{errors?.expenseValue}</p>
                    )}
                  </Col>
                  {expenseValue ? (
                    <Col className="custom-select">
                      <Box display="flex" className="input-box">

                      <Form.Label>Minor Type <span className="span-star">*</span></Form.Label>
                      
                      <CustomSelect
                        options={minorTypeOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => setMinorTypeValue(newValue)}
                        isSearchable
                        
                        onCreateOption={(e) => {
                          if (expenseValue?.value) {
                            createSubCategory(
                              createOption(e),
                              expenseValue.value
                            );
                          } else {
                            addToast("Please Select Major Type", {
                              appearance: "error",
                            });
                          }
                        }}
                       
                        onDeleteOption={(option) => {
                          deleteSubCategory(option);
                        }}
                        onEditOption={(option, newValue) => {
                          editSubCategory(option, newValue);
                        }}
                        defaultValue={minorTypeValue}
                        role={role}
                        isInvalid={errors?.minorTypeValue && !minorTypeValue}
                      />
                      </Box>
                      {errors?.minorTypeValue && !minorTypeValue && (
                        <p className="form_error">{errors?.minorTypeValue}</p>
                      )}
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                {minorTypeValue && (
                  <Row>
                    <Col className="custom-select">
                    <Box display="flex" className="input-box">
                      <Form.Label>Minor Sub Category  <span className="span-star">
                        {minorSubCategoryOption &&
                        minorSubCategoryOption?.length
                          ? "*"
                          : ""}
                      </span></Form.Label>
                     
                      <CustomSelect
                        options={minorSubCategoryOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) =>
                          setMinorSubCategoryValue(newValue)
                        }
                        isSearchable
                        onCreateOption={(e) => {
                          if (minorTypeValue?.value) {
                            createMinorSubCategory(
                              createOption(e),
                              minorTypeValue.value
                            );
                          } else {
                            addToast("Please Select Minor Type", {
                              appearance: "error",
                            });
                          }
                        }}
                        onDeleteOption={(option) => {
                          deleteMinorSubCategory(option);
                        }}
                        onEditOption={(option, newValue) => {
                          editMinorSubCategory(option, newValue);
                        }}
                        defaultValue={minorSubCategoryValue}
                        role={role}
                        isInvalid={
                          errors?.minorSubCategoryValue &&
                          !minorSubCategoryValue
                        }
                      />
                      </Box>
                      {errors?.minorSubCategoryValue &&
                        !minorSubCategoryValue && (
                          <p className="form_error">
                            {errors?.minorSubCategoryValue}
                          </p>
                        )}
                    </Col>
                    <Col>
                    <Box display="flex" className="input-box">

                      <Form.Label>Client Name {sendPayAmountRequest && (
                        <span className="span-star">*</span>
                      )}</Form.Label>
                      
                      <ClientProjectSelect
                        options={clientOPtion}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => setClientValue(newValue)}
                        isSearchable
                        defaultValue={clientValue}
                        role={role}
                        isInvalid={errors?.clientValue && !clientValue}
                      />
                    </Box>
                      {errors?.clientValue && !clientValue && (
                        <p className="form_error">{errors?.clientValue}</p>
                      )}
                    </Col>
                  </Row>
                )}
                {minorTypeValue && clientValue && (
                  <>
                    <Row>
                      <Col className="custom-select">
                      <Box display="flex" className="input-box">
                        <Form.Label>Project Name {sendPayAmountRequest && (
                          <span className="span-star">*</span>
                        )}</Form.Label>
                        
                        <ClientProjectSelect
                          options={projectOption}
                          placeHolder={<div>Type or select...</div>}
                          onChange={(newValue) => setProjectValue(newValue)}
                          isSearchable
                          defaultValue={projectValue}
                          role={role}
                          isInvalid={errors?.projectValue && !projectValue}
                        />
                        </Box>
                        {errors?.projectValue && !projectValue && (
                          <p className="form_error">{errors?.projectValue}</p>
                        )}
                      </Col>
                      <Col></Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>Remarks </Form.Label>

                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="remarks"
                          placeholder="Type Here..."
                          value={remarksValue}
                          // defaultValue={remarksValue}
                          onChange={(e) => handleRemarksChange(e)}
                          isInvalid={!!errors?.remarks}
                        />
                        </Box>
                      <p className="form_error">{errors?.remarks}</p>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              <div className="form-card mt-3">
                <h5>Payment Information</h5>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box">

                  
                    <Form.Label >Requested Amount <span className="span-star">*</span></Form.Label>
                    
                    <InputGroup>
                      <InputGroup.Text>
                        <span className="rupee-symbol">₹</span>{" "}
                      </InputGroup.Text>
                      <CurrencyFormat
                        customInput={Form.Control}
                        type="text"
                        disabled={
                          sendPayAmountRequest &&
                          !totalPayAmountIsApproved &&
                          (role === "Admin" || role === "Manager") &&
                          currUser.name !== editData?.paymentInfo?.requestedBy
                        }
                        name="approvalAmount"
                        placeholder="Requested Amount"
                        value={approvalAmount || ""}
                        onValueChange={(e) => {
                          setApprovalAmount(e.value);
                        }}
                        isInvalid={
                          (remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount) ||
                          (!approvalAmount && errors?.approvalAmount)
                        }
                        thousandSeparator={true}
                        // prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                      <InputGroup.Text
                        className="quotation-upload"
                        onClick={() => {
                          approvalAmount && setOpenQuotation(!openQuotation);
                        }}
                      >
                        <Tooltip
                          label="Upload Quotation"
                          fontSize="md"
                          borderRadius="2xl"
                        >
                          <div
                            disabled={!approvalAmount}
                            className="position-relative"
                          >
                            <FaFileUpload size={20} />
                            <span className="position-absolute top-0 start-100 translate-middle badge  bg-primary text-white">
                              {quotationData?.files || quotationData?.remarks
                                ? allQuotation?.length + 1
                                : allQuotation?.length || 0}
                            </span>
                          </div>
                        </Tooltip>
                      </InputGroup.Text>
                    </InputGroup>
                    </Box>
                    {((remainingAmount < 0 &&
                      approvalAmount <= totalPaymentAmount) ||
                      !approvalAmount) && (
                      <p className="form_error">{errors?.approvalAmount}</p>
                    )}
                  </Col>
                  {totalPayAmountIsApproved ? (
                    <Col>
                     <Box display="flex" className="input-box">

                      <Form.Label>Approved Amount</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>
                      
                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="totalPayAmount"
                          placeholder="Total Amount"
                          value={totalPaymentAmount ? totalPaymentAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </InputGroup>
                     </Box>
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                {totalPayAmountIsApproved && (
                  <>
                    <Row>
                      <Col>
                      <Box display="flex" className="input-box">

                        <Form.Label>Net Payable Amount</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <span className="rupee-symbol">₹</span>{" "}
                          </InputGroup.Text>
                         
                          <CurrencyFormat
                            customInput={Form.Control}
                            type="text"
                            disabled
                            name="Net Payable Amount"
                            
                            value={remainingAmount ? remainingAmount : 0}
                            thousandSeparator={true}

                            decimalScale={2}
                            isInvalid={
                              remainingAmount < 0 &&
                              approvalAmount <= totalPaymentAmount
                            }
                            fixedDecimalScale={true}
                          />
                        </InputGroup>
                      </Box>
                        {remainingAmount < 0 &&
                          approvalAmount <= totalPaymentAmount && (
                            <p className="form_error">
                              Net payable amount cannot be negative. Please
                              revise the request with an increased requested
                              amount and resubmit for approval.
                            </p>
                          )}
                      </Col>
                      <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>Total Spent Amount</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <span className="rupee-symbol">₹</span>{" "}
                          </InputGroup.Text>
                          <CurrencyFormat
                            customInput={Form.Control}
                            type="text"
                            disabled
                            name="totalPayAmount"
                            placeholder="Total Amount"
                            value={
                              parseFloat(totalSpentAmount) -
                              parseFloat(totalRefundAmount)
                            }
                            thousandSeparator={true}
                            // prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </InputGroup>
                        </Box>
                      </Col>
                    </Row>
                  

                   
                  </>
                )}
               
              </div>
              {totalPayAmountIsApproved && (
                <div>
                  <Tabs
                    defaultActiveKey="spent"
                    transition={false}
                    id="noanim-tab-example"
                    className="add-form-tabs"
                  >
                    <Tab eventKey="spent" title="Spent Amount" className="add-form-tab mx-0">
                    <div className="form-card">
                        
                     
                    {totalPayAmountIsApproved &&
                  multipleAddPaymentInfo.map((item, index) => {
                    return (
                      <>
                        <PaymentInfoComponent
                          getData={(data) => {
                            handleUpdateMultiplePayData(data, index);
                          }}
                          autoCount={index}
                          paymentData={item}
                          editData={editData}
                          refreshEditData={refreshEditData}
                          isEdit={isEdit}
                          handleInvoiceChange={(item) =>
                            handleInvoiceChange(item)
                          }
                          multipleInvoiceData={
                            item?.invoice ? item?.invoice : [""]
                          }
                          handleUpdateInvoiceData={(item, index) =>
                            handleUpdateInvoiceData(item, index)
                          }
                          handelDeleteInvoice={(index, id) =>
                            handelDeleteInvoice(index, id)
                          }
                          handleDeleteInvoiceOnEdit={(key, listIndex) =>
                            handleDeleteInvoiceOnEdit(key, listIndex)
                          }
                          addNewInvoiceOnEdit={(file, index, listIndex) =>
                            addNewInvoiceOnEdit(file, index, listIndex)
                          }
                          addRemarkForInvoice={(
                            value,
                            item,
                            listIndex,
                            index
                          ) =>
                            addRemarkForInvoice(value, item, listIndex, index)
                          }
                          deleteMultipleListItem={() =>
                            deleteMultipleListItem(index)
                          }
                          expenseType="resource"
                          role={role}
                        />
                      </>
                    );
                  })}
                  <Box display="flex" alignItems="center" onClick={() => {
                              handleMultiplePayInfo(
                                multipleAddPaymentInfo.length + 1
                              );
                            }}>
                          <h5>Add Spent Amount</h5>

                          <Button
                            className="add-project mt-2 mx-1"
                            minWidth={0}
                            
                          >
                            <FaPlusCircle
                              className="add-project-icon"
                              size={15}
                            />
                          </Button>
                        </Box>
                    </div>
                    </Tab>
                    {(role === 'Admin' || role === 'Manager') &&
                    <Tab
                      eventKey="excel"
                      title="Download Excel"
                      className="add-form-tab"
                    >
                      <DownloadExcelFile
                        prNo={generatedNum}
                        payAmount={totalPaymentAmount}
                        role={role}
                      />
                    </Tab>
                    }
                    <Tab 
                    eventKey="tds" 
                    title={
                      <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                        <div className="d-flex align-items-center">
                        TDS
                          {isTds &&
                          <FaCheck size={12}  className="tab-icon"/> 
                          }
                        </div>
                      </OverlayTrigger>
                    }                    
                    className="add-form-tab"
                    >
                      <div className="form-card">
                        <Box display="flex" alignItems="center">
                          <h5>TDS Applicable</h5>
                          <Form.Check
                            className="mx-1 mt-2"
                            type="switch"
                            id="custom-switch"
                            onChange={() => setIsTds(!isTds)}
                            checked={isTds}
                          />
                        </Box>

                        {isTds && (
                          <Box>
                            <Row>
                              <Col>
                                <Box display="flex" className="input-box">
                                  <Form.Label>Total Amount</Form.Label>
                                  <InputGroup className="input-group">
                                    <InputGroup.Text>
                                      <span className="rupee-symbol">₹</span>{" "}
                                    </InputGroup.Text>
                                    <CurrencyFormat
                                      customInput={Form.Control}
                                      type="text"
                                      placeholder="Total Amount "
                                      onValueChange={(e) => {
                                        setAmountTotal(e.value);
                                      }}
                                      name="Total Amount "
                                      value={amountTotal || ""}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                    />
                                  </InputGroup>
                                </Box>
                              </Col>
                              <Col>
                                <Box display="flex" className="input-box">
                                  <Form.Label>TDS Percent</Form.Label>
                                  <InputGroup className="input-group">                                    
                                  <Form.Control
                                      type="number"
                                      placeholder="TDS Percent"
                                      defaultValue={tdsPercent}
                                      disabled={editPercentNum}
                                      onChange={(e) =>
                                        setTdsPercent(e.target.value)
                                      }
                                    />
                                    <InputGroup.Text>
                                    {editPercentNum ? (
                                      <img
                                        src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                                        alt="edit"
                                        className="edit-button"
                                        onClick={handleEditTDSPercentange}
                                      />
                                    ) : (
                                      <img
                                        src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                                        alt="edit"
                                        className="edit-button"
                                        onClick={() => setEditPercentNum(true)}
                                      />
                                    )}
                                    </InputGroup.Text>
                                    </InputGroup>
                                </Box>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Box display="flex" className="input-box">
                                  <Form.Label>TDS Amount</Form.Label>
                                
                                    <InputGroup className="input-group">
                                      <InputGroup.Text>
                                        <span className="rupee-symbol">₹</span>{" "}
                                      </InputGroup.Text>
                                      <CurrencyFormat
                                        customInput={Form.Control}
                                        type="text"
                                        onValueChange={(e) => {
                                          setTdsAmount(e.value);
                                        }}
                                        name="TDS Amount "
                                        value={
                                          tdsAmount
                                            ? Number(tdsAmount).toFixed(2)
                                            : Number(0).toFixed(2)
                                        }
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        disabled={editTDSAmount}
                                        fixedDecimalScale={true}
                                      />
                                      <InputGroup.Text>
                                      {editTDSAmount ? (
                                      <img
                                        src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                                        alt="edit"
                                        className="edit-button"
                                        onClick={handleEditTDS}
                                      />
                                    ) : (
                                      <img
                                        src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                                        alt="edit"
                                        className="edit-button"
                                        onClick={handleEditSubmit}
                                      />
                                    )}
                                      </InputGroup.Text>
                                    </InputGroup>
                                   
                                </Box>
                              </Col>
                              <Col>
                              <Box display="flex">
                                <Form.Label>
                                  Do you want to generate TDS PO?
                                </Form.Label>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  className="mx-1"

                                  onChange={() =>
                                    setIsGenerateTDSPo(!isGenerateTDSPo)
                                  }
                                  checked={isGenerateTDSPo}
                                  label={isGenerateTDSPo && generatedTDSpoNum}
                                />
                                </Box>
                              </Col>
                            </Row>
                          </Box>
                        )}
                      </div>
                    </Tab>
                    {(role === 'Admin' || role === 'Manager') &&
                    <Tab 
                      eventKey="gst" 
                      title={
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                          <div className="d-flex align-items-center">
                          GST
                            {isGst &&
                            <FaCheck size={12}  className="tab-icon"/> 
                            }
                          </div>
                        </OverlayTrigger>
                      }                                     
                      className="add-form-tab"
                      >
                    <div className="form-card">
                        <Box display="flex" alignItems="center">
                          <h5>GST Applicable</h5>
                          <Form.Check
                            className="mx-1 mt-2"
                            type="switch"
                            id="custom-switch"
                            onChange={() => setIsGst(!isGst)}
                            checked={isGst}
                          />
                           
                        </Box>
                   
                    {isGst && (
                      <Box>
                        <div>
                         

                          {multipleAddGstInfo &&
                            multipleAddGstInfo.map((item, index) => {
                              return (
                                <>
                                  <GstInfoComponent
                                    getData={(data) => {
                                      handleUpdateMultipleGstData(data, index);
                                    }}
                                    editData={editData}
                                    autoCount={index}
                                    gstData={item}
                                    refreshEditData={refreshEditData}
                                    isEdit={isEdit}
                                    expenseType="resource"
                                    role={role}
                                    deleteMultipleGstItem={() =>
                                      deleteMultipleGstItem(
                                        item?._id?._id,
                                        index
                                      )
                                    }
                                  />
                                </>
                              );
                            })}
                        </div>
                      </Box>
                      )}
                       {isGst && (
                      <Box display="flex" alignItems="center" onClick={() => {
                                  handleMultipleGstInfo(
                                    multipleAddGstInfo.length + 1
                                  );
                                }}>
                          <h5>Add GST</h5>
                         
                           <Button
                                className="add-project mt-2"
                                
                                disabled={!isGst}
                              >
                                <FaPlusCircle
                                  className="add-project-icon"
                                  size={15}
                                />
                              </Button>
                        </Box>
                       )}
                    </div>
                    </Tab>
                    }
                    {(role === 'Admin' || role === 'Manager') &&
                    <Tab
                      eventKey="refund"
                      title={
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                          <div className="d-flex align-items-center">
                          Refund
                            {formik.values.isRefund &&
                            <FaCheck size={12}  className="tab-icon"/> 
                            }
                          </div>
                        </OverlayTrigger>
                      }          
                      className="add-form-tab"
                    >
                  <div className="form-card">
                        <Box display="flex" alignItems="center">
                        <h5>Refund Information</h5>
                          <Form.Check
                            className="mx-1 mt-2"
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.isRefund}
                            {...formik.getFieldProps("isRefund")}
                            />
                          
                        </Box>
                      
                      {formik.values.isRefund && (
                        <Box>
                          

                          {multipleAddRefundInfo &&
                            multipleAddRefundInfo.map((item, index) => {
                              return (
                                <RefundInfoListComponent
                                  key={`refundInfo-${index}`}
                                  getData={(data) => {
                                    handleUpdateMultipleRefundData(data, index);
                                  }}
                                  autoCount={index}
                                  paymentData={item}
                                  editData={editData}
                                  refreshEditData={refreshEditData}
                                  deleteMultipleListItem={() =>
                                    deleteMultipleRefundListItem(index)
                                  }
                                  expenseType="resource"
                                  role={role}
                                />
                              );
                            })}
                        </Box>

                      )}
                      {formik.values.isRefund && (
                      <Box display="flex" alignItems="center"  onClick={() => {
                        handleMultipleRefundInfo(
                          multipleAddRefundInfo.length + 1
                        );
                      }}>
                        <h5>Refund Information</h5>
                          
                            <Button
                                className="add-project mt-2"
                               
                                disabled={!formik.values.isRefund}
                              >
                                <FaPlusCircle
                                  className="add-project-icon"
                                  size={15}
                                />
                              </Button>
                        </Box>
                      )}
                    </div>
                    </Tab>
                    }
                    {(role === 'Admin' || role === 'Manager') &&
                    <Tab
                      eventKey="reimbursement"
                      title={
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                          <div className="d-flex align-items-center">
                          Reimbursement
                            {isReimbursement &&
                            <FaCheck size={12}  className="tab-icon"/> 
                            }
                          </div>
                        </OverlayTrigger>
                      }          
                      className="add-form-tab"
                    >
                      <div className="form-card">
                    <Box display="flex" className="mb-3" alignItems="center">
                    <h5>Reimbursement Applicable</h5>
                    <Form.Check
                        className="mx-1 mt-2"
                        type="switch"
                        id="custom-switch"
                        onChange={() =>
                          setIsReimbursement(!isReimbursement)
                        }
                        checked={isReimbursement}
                      />
                    </Box>
                     
                     
                      {isReimbursement && (
                        <Box>
                          <Row>
                            <Col >
                            <Box display="flex" className="input-box">
                              <Form.Label>Source</Form.Label>
                              <CustomSelect
                                options={sourceOption}
                                placeHolder={<div>Type or select...</div>}
                                onChange={(newValue) =>
                                  setSourceValue(newValue)
                                }
                                isSearchable
                                onCreateOption={(e) => {
                                  createNewFormOption(
                                    createOption(e),
                                    "source"
                                  );
                                }}
                                onDeleteOption={(option) => {
                                  deleteFromOption(option, "source");
                                }}
                                onEditOption={(option, newValue) => {
                                  editFromOption(option, newValue, "source");
                                }}
                                defaultValue={sourceValue}
                                role={role}
                              />
                              </Box>
                            </Col>

                            <Col className="switch-col">
                            <span
                                className="ms-2"
                                style={{ fontSize: "12px", color: "red" }}
                              >
                                {formik.errors.invoiceNum}
                              </span>
                            <Box display="flex" className="input-box">

                              <Form.Label>Invoice Number</Form.Label>
                             
                              <Form.Control
                                name="invoiceNum"
                                type="text"
                                placeholder="Invoice Number"
                                {...formik.getFieldProps("invoiceNum")}
                                defaultValue=""
                              />
                            </Box>
                            
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                            <Box display="flex" className="input-box">
                              <Form.Label>Date</Form.Label>
                              <DatePicker
                                className="monthDate"
                                dateFormat="dd-MMM-yyyy"
                                selected={invoiceDate}
                                onChange={(date) => setInvoiceDate(date)}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              </Box>
                            </Col>

                            <Col>
                            <Box display="flex" className="input-box">

                              <Form.Label>Amount</Form.Label>
                              <InputGroup className="input-group">
                                <InputGroup.Text>
                                  <span className="rupee-symbol">₹</span>{" "}
                                </InputGroup.Text>
                                <CurrencyFormat
                                  customInput={Form.Control}
                                  type="text"
                                  onValueChange={(e) => {
                                    setReimbursementAmount(e.value);
                                  }}
                                  name="reimbursementAmount"
                                  defaultValue="0"
                                  value={reimbursementAmount || ""}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              
                              </InputGroup>
                            </Box>
                            </Col>
                          </Row>
                        </Box>
                      )}
                    </div>
                
                    </Tab>
                    }
                </Tabs>
                </div>
              )}
             

              {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest && (
                  <>
                    <div className="form-card">
                      <h5>Approve OR Reject Remark</h5>

                      <Row>
                        <Col>
                        <Box display="flex" className="input-box">

                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Type Here..."
                            value={approveOrRejectRemarks}
                            onChange={(e) =>
                              setApproveOrRejectRemarks(e.target.value)
                            }
                            isInvalid={
                              !approveOrRejectRemarks &&
                              errors?.approveOrRejectRemarks
                            }
                          />
                          </Box>
                          {!approveOrRejectRemarks && (
                            <p className="form_error">
                              {errors?.approveOrRejectRemarks}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              <Row className="mt-4">
                {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest ? (
                  <ModalFooter
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    {!totalPayAmountIsApproved && (
                      <div className="d-flex">
                        <Button
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => {
                            handleTotalPayAmountIsApproved(formik.values);
                          }}
                          className="submit-btn me-2"
                        >
                          Approve
                        </Button>

                        <Button
                          className="submit-btn"
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => handleTotalPayAmountIsRejected()}
                          style={{ background: "#191063" }}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  </ModalFooter>
                ) : (
                  <ModalFooter>
                    {(!totalPayAmountIsApproved && !sendPayAmountRequest) ||
                    remainingAmount < 0 ||
                    parseFloat(approvalAmount) !==
                      parseFloat(totalPaymentAmount) ? (
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleAmountApproveBtn(formik.values);
                        }}
                        className="submit-btn"
                      >
                        Send Request
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="brand"
                        className="submit-btn"
                        onClick={() => setCompareDataModal(true)}
                        style={{ background: "#191063" }}
                      >
                        Submit
                      </Button>
                    )}
                  </ModalFooter>
                )}
              </Row>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {compareDataModal && comparingData && (
        <DataChangeModalOnEdit
          openModal={compareDataModal}
          closeModal={closeComparedDataModal}
          editData={editData}
          currentData={comparingData}
          isConfirmed={() => {
            submitUpdateData(comparingData, editData);
          }}
          type={"resource"}
        />
      )}
      {openQuotation && (
        <QuotationForm
          isOpen={openQuotation}
          closeModal={() => {
            handleCloseQuotation();
          }}
          requestedAmount={approvalAmount}
          handleData={(data) => handleQuotationData(data)}
          uploadedData={quotationData}
          expenseId={editData?._id || ""}
          allQuotation={allQuotation}
        />
      )}
    </>
  );
};

export default ResourceAddNew;
