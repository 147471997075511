import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";

import Col from "react-bootstrap/Col";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaEye } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import { useToasts } from "react-toast-notifications";

const InvoiceComponent = ({
  getInvoice,
  handleDelete,
  selectedFile,
  handleDeleteOnEdit,
  isEditing,
  addNewInvoiceOnEdit,
  submitInvRemark,
  role
}) => {
  const [editPan, setEditPan] = useState(false);
  const [remark, setRemark] = useState(selectedFile.remark || "");

  

  const { addToast } = useToasts();
  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size < 2 * 1024 * 1024) {
        if (isEditing) {
          addNewInvoiceOnEdit(e.target.files[0]);
        } else {
          getInvoice(e.target.files[0]);
        }
      } else {
        addToast("Please attach file less then 2Mb", { appearance: "warning" });
      }
    }
  };
  const apiURL = process.env.REACT_APP_BASE_URL;

  const handlePreview = async() => {
    
    try {
      const response = await fetch(`${apiURL}/preview-invoice?key=${encodeURIComponent(selectedFile?.Key)}`, {
        method: 'GET',
        headers: {
          // Add any necessary headers, like authorization
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      
      const blob = await response.blob();
  
      const pdfUrl = window.URL.createObjectURL(blob);
    

  
      window.open(pdfUrl, '_blank'); 
     
    } catch (error) {
      addToast("Error fetching PDF preview:", { appearance: "error" });
    }
  };

  
 

  return (
    <>
      <div>
        <Row className="mb-3">
          {selectedFile.Key ? (
            <>
              <Col >
              <Box display="flex" className="input-box">
                <Form.Label>Invoice Attached</Form.Label>
                <Box display="flex" >
                <InputGroup>
                <Form.Control
                  type="input"
                
                  className="invoice-input" 
                  name="invoice"
                  placeholder="PDF"
                  disabled
                  accept="application/pdf"
                  value={selectedFile.Key?.split("/").at(-1)}
                  title={selectedFile.Key?.split("/").at(-1)}
                />
                <InputGroup.Text>
                 <FaEye 
                color="#000d55"
                cursor="pointer"
                
                  size={20}
                  onClick={handlePreview}
                />
                </InputGroup.Text>
                </InputGroup>
                </Box>
               
                </Box>
              </Col>
              {isEditing && (
                <Col className="align-items-center" >
                  <Box display="flex" className="input-box mt-0">

                  <Form.Label>Invoice Remark</Form.Label>
                  
                  <Box display="flex" alignItems="center" width={240}>
                    <InputGroup>
                    <Form.Control
                      type="text"
                      name="panNumber"
                      className="invoice-input"

                      placeholder="Remark"
                      disabled={!editPan}
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    />
                    <InputGroup.Text>
                    {!editPan ? (
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                        alt="edit"
                        className="edit-button panEdit"
                        onClick={() => {
                          setEditPan(true);
                        }}
                      />
                    ) : (
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                        alt="right"
                        className="edit-button panEdit"
                        onClick={() => {
                          submitInvRemark(remark);
                          setEditPan(false);
                        }}
                      />
                    )}
                    </InputGroup.Text>
                    
                      </InputGroup>
                    {(role === "Admin" || role === "SuperAdmin") && (
                    
                      <FaTrash
                        style={{
                          fontSize: "16px",
                          color: "red",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteOnEdit(selectedFile.Key)}
                      />
                  )}
                  </Box>
                  </Box>

                </Col>
              )}
              {/* <Col className="d-flex align-items-center">
              
              </Col> */}
            </>
          ) : (
            <>
              <Col>
              <Box display="flex" className="input-box" >

                <Form.Label>
                  Invoice (<span>*</span>.pdf)
                </Form.Label>

                <Form.Control
                  type="file"
                  name="invoice"
                  className=""
                  placeholder="PDF"
                  onChange={(e) => handleFileChange(e)}
                  accept="application/pdf"
                />
                </Box>
              </Col>
              <Col className="d-flex ">
                <div className="mt-0">
                  <FaTrash
                    style={{
                      fontSize: "16px",
                      color: "red",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete()}
                  />
                </div>
              </Col>
            </>
          )}
           {/* PDF Preview Modal */}
    
        </Row>
      </div>
     
    </>
  );
};

export default InvoiceComponent;
