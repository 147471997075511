import React, { useEffect, useState } from "react";

// import "antd/dist/antd.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { createListForSingleOption, createOption } from "./createableSelect";
import "rsuite/dist/rsuite.min.css";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import CustomSelect from "components/CustomSelect";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { Box, Button } from "@chakra-ui/react";
import InvoiceComponent from "./seprateFormComponent/InvoiceComponent";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { FaTrash } from "react-icons/fa6";
import { InputGroup } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { currencyFormate } from "jsFunctions/helperFunctions";
import { FaPlusCircle } from "react-icons/fa";


const PaymentInfoComponent = ({ getData, autoCount, editData,paymentData , deleteMultipleListItem,isEdit,handleInvoiceChange,multipleInvoiceData, handleUpdateInvoiceData, handelDeleteInvoice,handleDeleteInvoiceOnEdit, addNewInvoiceOnEdit, addRemarkForInvoice,expenseType, role}) => {
  const formOptionsList1 = useSelector((state) => state.formOptions);

  const getOptionDataOnEdit = (formOptionsList, editData, value, type) => {
    const data = formOptionsList.find(
      (item) => item.value === editData[value] && item.type === type
    );
    return data;
  };

  const [payeeValue, setPayeeValue] = useState("");
  const [bankValue, setBankValue] = useState("");
  const [refNo, setRefNo] = useState("");
  const [formOptionsList, setFormOptionsList] = useState([])

  const [selectedDate, setSelectedDate] = useState(
    paymentData?.paymentDate ? new Date(paymentData?.paymentDate) : new Date()
  );
  const [paymentAmount, setPaymentAmount] = useState("");
  const [addTogglePaymentIdx, setAddTogglePaymentIdx] = useState(true);
  const [payeeOption, setPayeeOption] = useState([]);
  const [bankOption, setBankOption] = useState([]);
  const dispatch = useDispatch();

  const [preAmount, setPreAmount] = useState(0)
  const onChangeValues = (payValue, bankVal, payAmount, ref, date) => {
    const data = {
      payee: payValue,
      bank: bankVal,
      amount: payAmount,
      refNo: ref,
      paymentDate: date,
    };
    getData(data);
  };

  useEffect(()=>{
    const data = editData?.paymentInfo?.list[autoCount]
    const amount = data?.amount ? data?.amount : 0
    setPreAmount(amount)
  },[editData])


  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === expenseType)
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

  useEffect(() => {
    if (paymentData && formOptionsList.length > 0) {
      const payeeData = getOptionDataOnEdit(
        formOptionsList,
        paymentData,
        "payee",
        "payee"
      );
      if (payeeData) {
        setPayeeValue(payeeData);
      }
      const bakData = getOptionDataOnEdit(
        formOptionsList,
        paymentData,
        "bank",
        "bank"
      );
      if (bakData) {
        setBankValue(bakData);
      }
      setRefNo(paymentData.refNo);
      setPaymentAmount(paymentData?.amount);
    }
    else{
      setPaymentAmount(paymentData?.amount);

    }
  }, [formOptionsList, paymentData]);

  // form option list

  useEffect(() => {
    let payeeList = createListForSingleOption(formOptionsList, "payee");
    let bankList = createListForSingleOption(formOptionsList, "bank");
    setPayeeOption(payeeList);
    setBankOption(bankList);
  }, [formOptionsList]);

  const createNewFormOption = (data, type) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        expenseType:expenseType
      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
          };
          if (type === "payee") {
            setPayeeValue(setData);
          }

          if (type === "bank") {
            setBankValue(setData);
          }
          Swal.fire({
            title: "Successfully Added",

            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {});
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",

          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {});
      });
  };

  const deleteFromOption = (option, type) => {
    const data = formOptionsList.find(
      (item) => item.value === option.value && item.type === type
    );
    dispatch(fetchAsyncDeleteFormOptions(data._id));
  };
  const editFormOption = (option,newValue, type) => {
    const data = formOptionsList.find(
      (item) => item.value === option.value && item.type === type
    );
    
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:expenseType
      }
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
        };
        if (type === "payee") {
          setPayeeValue(setData);
        }

        if (type === "bank") {
          setBankValue(setData);
        }
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };

  return (
    <div
      className={`payment-listing_box ${addTogglePaymentIdx ? "show" : "hide"}`}
    >
      <div className="payment-list_array">
        <Box display="flex"  alignItems="center" >
          <span className="payment-details">
            Spent Amount Detail  {autoCount + 1}
          </span>
          <span className="amountSpan">
          {paymentAmount ? currencyFormate(Number(paymentAmount)) : 0}
          </span>
        </Box>
        <div
          className="d-flex toggleImage-content"
          onClick={() => {
            setAddTogglePaymentIdx(!addTogglePaymentIdx);
          }}
        >
          <span className="toggleImage">
            <img
              src="https://cdn-icons-png.flaticon.com/128/4655/4655143.png"
              alt="minus"
              style={{ width: "20px", height: "35px" }}
              className={addTogglePaymentIdx ? "closed" : "open"}
            />
          </span>
        </div>
        {(role === "Admin" || role === "SuperAdmin") && preAmount !== 0 ? (
          <div className="d-flex" onClick={() => deleteMultipleListItem()}>
            <span className="mt">
              <FaTrash
                style={{
                  fontSize: "16px",
                  color: "red",
                  // marginTop: "10px",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        ) : (
          preAmount === 0 && (
            <div className="d-flex" onClick={() => deleteMultipleListItem()}>
              <span className="mt">
                <FaTrash
                  style={{
                    fontSize: "16px",
                    color: "red",
                    // marginTop: "10px",
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
          )
        )}
      </div>

      <div
        className={`payment-inner_box ${
          addTogglePaymentIdx ? "show-content" : "hide-content"
        }`}
      >
        <Row className="">
          <Col>
            <Box display="flex" className="input-box">
              <Form.Label>Payee</Form.Label>
              <CustomSelect
                options={payeeOption}
                placeHolder={<div>Type or select...</div>}
                onChange={(newValue) => {
                  onChangeValues(
                    newValue.value,
                    bankValue.value,
                    paymentAmount,
                    refNo,
                    selectedDate
                  );
                  setPayeeValue(newValue);
                }}
                isSearchable
                onCreateOption={(e) => {
                  createNewFormOption(createOption(e), "payee");
                }}
                onDeleteOption={(option) => {
                  deleteFromOption(option, "payee");
                }}
                onEditOption={(option, newValue) => {
                  editFormOption(option, newValue, "payee");
                }}
                defaultValue={payeeValue}
                role={role}
              />
            </Box>
          </Col>
          <Col>
            <Box display="flex" className="input-box">
              <Form.Label>
                Spent Amount <span className="span-star">*</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <span className="rupee-symbol">₹</span>{" "}
                </InputGroup.Text>
                <CurrencyFormat
                  customInput={Form.Control}
                  type="text"
                  defaultValue={paymentAmount}
                  value={paymentAmount}
                  onValueChange={(e) => {
                    onChangeValues(
                      payeeValue.value,
                      bankValue.value,
                      e.value,
                      refNo,
                      selectedDate
                    );
                    setPaymentAmount(e.value);
                  }}
                  thousandSeparator={true}
                  // prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </InputGroup>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box display="flex" className="input-box">
              <Form.Label>Bank</Form.Label>
              <CustomSelect
                options={bankOption}
                placeHolder={<div>Type or select...</div>}
                onChange={(newValue) => {
                  onChangeValues(
                    payeeValue.value,
                    newValue.value,
                    paymentAmount,
                    refNo,
                    selectedDate
                  );
                  setBankValue(newValue);
                }}
                isSearchable
                onCreateOption={(e) => {
                  createNewFormOption(createOption(e), "bank");
                }}
                onDeleteOption={(option) => {
                  deleteFromOption(option, "bank");
                }}
                onEditOption={(option, newValue) => {
                  editFormOption(option, newValue, "bank");
                }}
                defaultValue={bankValue}
                role={role}
              />
            </Box>
          </Col>

          <Col>
            <Box display="flex" className="input-box">
              <Form.Label>Reference Number</Form.Label>
              <Form.Control
                type="text"
                name="referenceNo"
                placeholder="Reference Number"
                onChange={(e) => {
                  onChangeValues(
                    payeeValue.value,
                    bankValue.value,
                    paymentAmount,
                    e.target.value,
                    selectedDate
                  );
                  setRefNo(e.target.value);
                }}
                defaultValue={refNo}
              />
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box display="flex" className="input-box">
              <Form.Label>Date</Form.Label>

              <DatePicker
                name="paymentdate"
                className="monthDate"
                dateFormat="dd-MMM-yyyy"
                selected={selectedDate}
                onChange={(date) => {
                  onChangeValues(
                    payeeValue.value,
                    bankValue.value,
                    paymentAmount,
                    refNo,
                    date
                  );
                  setSelectedDate(date);
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </Box>
          </Col>
          <Col></Col>
        </Row>
        {["internal", "external", "resource", "tds", "directTax"].includes(
          expenseType
        ) && (
          <div className="form-card" style={{ marginBottom: "0px" }}>
            <Box display="flex" alignItems="center">

            <h5>Invoice</h5>
            <Button className="add-project mt-1"  onClick={() => {
                handleInvoiceChange({
                  id: autoCount,
                  invNumb: multipleInvoiceData.length + 1,
                });
              }}>
              <FaPlusCircle className="add-project-icon" size={15} />
            </Button>
            
            
            </Box>
            <Box display="flex">
            <span className=" mt-2" style={{ color: "red", fontSize: "14px" }}>
              * File must be less then 2Mb
            </span>
            {multipleInvoiceData && multipleInvoiceData?.length >= 5 && (
              <strong className="mx-4 mt-2">Maximum 5 allowed</strong>
            )}
            </Box>
            {multipleInvoiceData &&
              multipleInvoiceData?.map((item, index) => {
                return (
                  <InvoiceComponent
                    key={`invoiceData-${index}`}
                    getInvoice={(data) => handleUpdateInvoiceData(data, index)}
                    selectedFile={item}
                    handleDelete={() => {
                      handelDeleteInvoice(index, autoCount);
                    }}
                    handleDeleteOnEdit={(key) => {
                      handleDeleteInvoiceOnEdit(key, autoCount);
                    }}
                    isEditing={isEdit}
                    addNewInvoiceOnEdit={(file) => {
                      addNewInvoiceOnEdit(file, index, autoCount);
                    }}
                    submitInvRemark={(value) => {
                      addRemarkForInvoice(value, item, autoCount, index);
                    }}
                    role={role}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentInfoComponent;
