import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Thead,
} from "@chakra-ui/react";
import { MdOutlineFileDownload } from "react-icons/md";
import { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

const PaymentDetailInfo = ({ selectedItem }) => {
  const [excelData, setExcelData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const splitTitle = (Key) => {
    let label = "";
    if (Key) {
      let splitKey = Key.split("/");
      label = Key && splitKey[splitKey.length - 1];
    }
    return label;
  };

  const handleDownload = (loc) => {
    const s3Link = loc;
    const link = document.createElement("a");
    link.href = s3Link;
    link.download = ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (selectedItem?.excelData && selectedItem?.excelData.length > 0) {
      
      setExcelData(selectedItem?.excelData);
    }
  }, [selectedItem]);

  // Calculate pagination details
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = excelData.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(excelData.length / itemsPerPage);

  return (
    <>
      <Row className="info-row">
        <Col className="check-cross-mark mt-0">
          <Form.Label>
            <span className={excelData.length ? "checkmark" : "crossmark"}></span>{" "}
            Excel Sheet Attached?
          </Form.Label>
        </Col>
      </Row>
      {excelData.length ? (
        <div className="info-table">
          <Table size="md" variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Title</Th>
                <Th>Payment Type</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((item, index) => (
                <Tr key={index}>
                  <Td>{indexOfFirstItem + index + 1}</Td>
                  <Td>{splitTitle(item?.Key)}</Td>
                  <Td>{item?.excelType}</Td>
                  <Td style={{ textAlign: "center" }}>
                    <MdOutlineFileDownload
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload(item?.Location);
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Pagination Component */}
          <Pagination className="justify-content-end" style={{ marginTop: '20px' }}>
            <Pagination.Prev 
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} 
              disabled={currentPage === 1} 
            />
            {Array.from({ length: pageCount }, (_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next 
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))} 
              disabled={currentPage === pageCount} 
            />
          </Pagination>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PaymentDetailInfo;
