import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  Box,
  Divider,
  Text,
} from '@chakra-ui/react';
import { Col, Form } from 'react-bootstrap';
import { formatAmountInINR } from 'jsFunctions/formatAmountInINR';

const PanNumberField = ({ panNumber, handlePanNumber, totalPayAmount, role }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const showPopover = role === 'Admin' || role === 'Manager';

  return (
    <Col onMouseEnter={showPopover ? onOpen : undefined} onMouseLeave={showPopover ? onClose : undefined}>
      <Box display="flex" className="input-box">

      {showPopover ? (
        <Popover isOpen={isOpen} onClose={onClose} placement="top-start">
          <PopoverTrigger>
            <Form.Label>PAN Number</Form.Label>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Pay Details</PopoverHeader>
            <PopoverBody>
              <Box display="flex" justifyContent="space-between" color="gray.800" width="100%">
                <Text flex="1" fontWeight="bold">Total Amount:</Text>
                <Text flex="1" textAlign="right" color="green.500" fontWeight="bold">
                  {formatAmountInINR(totalPayAmount?.totalResource || 0)}
                </Text>
              </Box>
              <Box display="flex" justifyContent="space-between" color="gray.800" width="100%">
                <Text flex="1" fontWeight="bold">Total TDS:</Text>
                <Text flex="1" textAlign="right" color="red.500" fontWeight="bold">
                  {formatAmountInINR(totalPayAmount?.totalTds || 0)}
                </Text>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" color="gray.800" width="100%">
                <Text flex="1" fontWeight="bold">Total Pay Amount:</Text>
                <Text flex="1" textAlign="right" color="blue.500" fontWeight="bold">
                  {formatAmountInINR(totalPayAmount?.totalPayAmount || 0)}
                </Text>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <Form.Label>PAN Number <span className="span-star">*</span></Form.Label>
      )}
        

      <div>
        <Form.Control
          type="text"
          name="panNumber"
          placeholder="Resource Pan Number"
          disabled
          value={panNumber}
          onChange={(e) => {
            handlePanNumber(e.target.value);
          }}
        />
      </div>
      </Box>
    </Col>
  );
};

export default PanNumberField;
