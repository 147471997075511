import { string, object } from "yup";

export const UserSchema = object().shape({
  name: string().required("Name is required"),
  // role: string().required("Role is required"),

  email: string()
    .email("Please Enter valid email")
    .required("Email is required"),
});

export const UpdateUserSchema = object().shape({
  name: string().required("Name is required"),
  // role: string().required("Role is required"),
  email: string()
    .email("Please Enter valid email")
    .required("Email is required"),
});

export const FormValidation = object().shape({
  invoiceNum: string().matches(
    /^INV-[0-9]{6,12}$/,
    "Must Match invoice number (INV-***)"
  ),
  gstNumber: string()
  .matches(/^[0-9]{2}[A-Z]{5}[0-9A-Z]{4}[A-Z][A-Z0-9][Z]{1}[A-Z0-9]{1}$/gm, "GSTIN number is not in the correct format. ex: 06BZAHM6385P6Z2 (State Code + PAN + Entity Code + Checksum)"),
});

export const ResourceFormValidation = object().shape({
  
  invoiceNum: string().matches(
    /^INV-[0-9]{6,12}$/,
    "Must Match invoice number (INV-***)"
  ),
  gstNumber: string()
  .matches(/^[0-9]{2}[A-Z]{5}[0-9A-Z]{4}[A-Z][A-Z0-9][Z]{1}[A-Z0-9]{1}$/gm, "GSTIN number is not in the correct format. ex: 06BZAHM6385P6Z2 (State Code + PAN + Entity Code + Checksum)"),
});
export const tdsFormValidation = object().shape({
  // panNumber: string()
  //   .required("Please Enter Pan Number")
  //   .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm, "Pan Number Should AAAAA0000A"),
  invoiceNum: string().matches(
    /^INV-[0-9]{6,12}$/,
    "Must Match invoice number (INV-***)"
  ),
  gstNumber: string()
  .matches(/^[0-9]{2}[A-Z]{5}[0-9A-Z]{4}[A-Z][A-Z0-9][Z]{1}[A-Z0-9]{1}$/gm, "GSTIN number is not in the correct format. ex: 06BZAHM6385P6Z2 (State Code + PAN + Entity Code + Checksum)"),

  
   
  
});
export const internalFormValidation = object().shape({
  
  gstNumber: string()
  .matches(/^[0-9]{2}[A-Z]{5}[0-9A-Z]{4}[A-Z][A-Z0-9][Z]{1}[A-Z0-9]{1}$/gm, "GSTIN number is not in the correct format. ex: 06BZAHM6385P6Z2 (State Code + PAN + Entity Code + Checksum)"),

  
   
  
});
