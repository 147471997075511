import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddDirectTaxExpense = createAsyncThunk(
  "/fetchAsyncAddDirectTaxExpense",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/direct-tax/create`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetPONumDirectTaxExpense = createAsyncThunk(
  "/fetchAsyncGetPONumDirectTaxExpense",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/getPoNum`;
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncGetDirectTaxExpense = createAsyncThunk(
  "/fetchAsyncGetDirectTaxExpense",
  async () => {
    try {
      let url = `${API_URL}/direct-tax/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteDirectTaxExpense = createAsyncThunk(
  "/fetchAsyncDeleteDirectTaxExpense",
  async (id) => {
    try {
      let url = `${API_URL}/direct-tax/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUpdateDirectTaxExpense = createAsyncThunk(
  "/fetchAsyncUpdateDirectTaxExpense",
  async (data) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/direct-tax/update/${data.id}`,data.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncUploadDirectPdf = createAsyncThunk(
  "/fetchAsyncUploadDirectPdf",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/upload_pdf/${data.id}`;
      const response = await axiosInstance.post(url, data.formData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncDeleteDirectPdf = createAsyncThunk(
  "/fetchAsyncDeleteDirectPdf",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/delete_pdf/${data.id}`;
      const response = await axiosInstance.post(url,{ key: data.key,listIndex:data?.listIndex  });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncAddDirectTaxInvoiceRemark = createAsyncThunk(
  "/fetchAsyncAddDirectTaxInvoiceRemark",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/invoice-remark/${data.id}`;
      const response = await axiosInstance.put(url, data.remarkData)
     
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncSearchDTData = createAsyncThunk(
  "/fetchAsyncSearchDTData",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/search`;
      if (data.value) {
        url = url + `?search=${data.value}`;
      }
      if (data.startDate && data.endDate && data.value) {
        url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      if (data.startDate && data.endDate && !data.value) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url)
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

// Dashboard Api
export const fetchAsyncGetDirectTaxTotalPaymentAmount = createAsyncThunk(
  "/fetchAsyncGetDirectTaxTotalPaymentAmount",
  async () => {
    try {
      let url = `${API_URL}/direct-tax/graph-totalAmount`;
      const response = await axiosInstance.get(url)

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const fetchAsyncApproveDirectTaxTotalPayAmount = createAsyncThunk(
  "/fetchAsyncApproveDirectTaxTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestApprovalDirectTaxTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestApprovalDirectTaxTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/send-request-approve-total-pay-amount`;
      const response = await axiosInstance.post(url, data);
     
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestUpdateApprovalDirectTaxTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestUpdateApprovalDirectTaxTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/send-request-update-approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncRejectDirectTaxTotalPayAmount = createAsyncThunk(
  "/fetchAsyncRejectDirectTaxTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/reject-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncGetDirectTaxExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetDirectTaxExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/directTaxFinencialYearData`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);
export const fetchAsyncDeleteDirectTaxPaymentListItem = createAsyncThunk(
  "/fetchAsyncDeleteDirectTaxPaymentListItem",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/delete-direct-tax-payment-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);


export const fetchAsyncDirectTaxXlsxData = createAsyncThunk(
  "/fetchAsyncDirectTaxXlsxData",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/direct-tax-xlsx`;
     
      
      if (data?.role && data.startDate && data.endDate) {
        url = url + `?role=${data.role}&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncDeleteDirectTaxRefundListItem = createAsyncThunk(
  "/fetchAsyncDeleteDirectTaxRefundListItem",
  async (data) => {
    try {
      let url = `${API_URL}/direct-tax/delete-direct-tax-refund-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

const directTaxSlice = createSlice({
  name: "directTaxData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetDirectTaxExpense.pending]: (state) => {},
    [fetchAsyncGetDirectTaxExpense.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncSearchDTData.pending]: (state) => {},
    [fetchAsyncSearchDTData.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const getAllDirectTaxExpense = (state) => state.directTaxData;

export default directTaxSlice.reducer;
