import React, {useCallback, useEffect, useState } from "react";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { AiFillDelete, AiFillEye } from "react-icons/ai";

import {
  Box,
  Button,
  Text,
  Tooltip,
  useColorModeValue,
  Menu,
  
  MenuButton,
  
  MenuList,
  Flex, 
} from "@chakra-ui/react";

import ResourceAddNew from "components/allForm/resourceAddNew";
import ColumnTable from "./embrillTable/ColumnTable";
import { columnsDataColumns ,pendingDataColumns,rejectedDataColumns} from "./embrillTable/variables/resourceColData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetResourceExpense } from "redux/resourceSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import PdfListModel from "components/models/PdfListModel";
import { fetchAsyncDeleteResourceExpense } from "redux/resourceSlice";
import { fetchAsyncDeleteResourcePdf } from "redux/resourceSlice";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { currencyFormate } from "jsFunctions/helperFunctions";

import Swal from "sweetalert2";
import { FcApproval } from "react-icons/fc";
import { MdModeEdit, MdOutlinePendingActions } from "react-icons/md";
import { CiExport, CiViewTable } from "react-icons/ci";
import { PiGraph } from "react-icons/pi";
import { GrResources } from "react-icons/gr";
import ExpenseReports from "./expenseGraph";
import ResourceInfoModel from "components/models/ResourceInfoModel";
import { RxCrossCircled } from "react-icons/rx";
import ExportDataModel from "components/exportData/ExportDataModel";
import { fetchAsyncDeleteExpense } from "redux/userSlice";
import { useLocation } from "react-router-dom";
import { performSearch } from "components/navbar/searchUtils";
import { fetchAsyncGetClient } from "redux/clientProjectSlice";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import SpentAmountPopover from "components/models/spentAmountPopover";
import { FaEllipsisV,FaPlus } from "react-icons/fa";
import { TbFileExport } from "react-icons/tb";

const EmbrillResourceExpense = () => {
  const [addNewModal, setAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [resourceEditData, setResourceEditData] = useState(null);
  const [resourceListData, setResourceListData] = useState([]);
  const dispatch = useDispatch();
  const resourceList = useSelector((state) => state.resourceData.resourceExpense);
  const resourceData = useSelector((state) => state.resourceData);
  const currUser = useSelector((state) => state.auth.currUser);
  const [resourcePdfList, setResourcePdfList] = useState(null);
  const [showPdfList, setShowPdfList] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [activeDataList,setActiveDataList] = useState('approved')
  const [invoiceData,setInvoiceData] = useState([])
  const [activeGraph,setActiveGraph] = useState(false)
  const [openResourceInfoModel, setOpenResourceInfoModel] = useState(false);
  const dateRange = useSelector((state) => state.dateRange); // Access the dateRange slice
  const { startDate, endDate } = dateRange;
  const searchValue = useSelector((state) => state.searchValue); // Access the dateRange slice
  const {searchText} = searchValue;
  const location = useLocation();

  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Resource/Service Expense');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  const handleModalOpen = () => {
    setAddNewModal(true);
  };
  const handleModalClose = () => {
    setAddNewModal(false);
    setOpenEditModal(false);
    setResourceEditData(null);
    handlerRefreshData();
  };
  
  useEffect(() => {
    dispatch(fetchAsyncGetResourceExpense());
    dispatch(fetchAsyncGetClient())

  }, []);

  const handlerRefreshData = useCallback(
    () => {
      performSearch(dispatch, location, searchText, startDate, endDate);
    },
    [location, dispatch,,searchText,startDate,endDate]
  );


  const deleteResourceData = (data) => {
    dispatch(fetchAsyncDeleteResourceExpense(data._id)).then(() => {
      handlerRefreshData();
    });
  };


  const deleteFileFromList = (sendData) => {
    dispatch(fetchAsyncDeleteResourcePdf(sendData)).then((response) => {
      handlerRefreshData();
    });
  };

  const hardDeleteResourceData = (data) => {
    const objData = {
      id:data._id,
      type:'resource'
    }
    dispatch(fetchAsyncDeleteExpense(objData)).then(() => {
      handlerRefreshData();
    });
  };
  const getAllResourceData = (listData)=>{
    if (listData && listData.length > 0) {      
      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;

        const array = data?.paymentInfo?.list;
        
        const spentAmounts = array.map(item => parseFloat(item?.amount || 0)); 
        const totalSpent = array.reduce((acc, item) => acc + (parseFloat(item?.amount) || 0), 0);
        const refundAmount = data?.refundInfo?.list
        const totalRefund = refundAmount.reduce((acc, item) => acc + (parseFloat(item?.amount) || 0), 0);

        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id:data._id,
          no: index + 1,
          poNo: (
            <span 
              style={{ cursor: "pointer",color:'blue'}}
            >
              {data.poNo && poNumberFormate(data.poNo)}
            </span>
          ),
          purchasedate: dateTimeFormat(data.orderDate),
          resourceName: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {(data?.resourceId && data?.resourceId?.companyName) ? data?.resourceId?.companyName :data?.resourceName || ""}
            </span>
          ),
          majorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.expense?.majorType}
            </span>
          ),
          minorType: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {data.minorType}
            </span>
          ),
          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={!data.paymentInfo?.totalPayAmountIsApproved ? 'red' :'green'}

            >
              {data.paymentInfo?.approvalAmount ? currencyFormate(data.paymentInfo?.approvalAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
          spentAmount:  
          <Box display="flex" >
         <span
              style={{ cursor: "pointer" }}
              className={ parseFloat(totalSpent) - parseFloat(totalRefund) ? 'green' :''}

            >
              {formatAmountInINR(totalSpent ? parseFloat(totalSpent) - parseFloat(totalRefund || 0) : 0)}
            </span>
          
          <SpentAmountPopover spentAmounts={spentAmounts} refundAmount={refundAmount}/>
           </Box>
          ,
          approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ), 
          invoice:
          foundInvoiceWithLength ? (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPdfList(true);
                  setResourcePdfList(data);
                }}
              />
            ) : (
              <BsFillFileEarmarkPdfFill
                style={{ cursor: "not-allowed" }}
                color="gray"
              />
            ),
            status:'Rejected',

          action: (
            <div className="d-flex">
              <MdModeEdit
                className="me-2"
                color="blue"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleRowClick(e,data._id)
                }}
              />
              <AiFillEye
                className="me-2"
                color="#000D55"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedItem(data);
                  setOpenResourceInfoModel(true);
                }}
              />
              {role === "Admin" && (
                <>
                <AiFillDelete
                  style={{ cursor: "pointer" }}
                  size={16}

                  onClick={(e) => {
                    e.preventDefault();
                    
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteResourceData(data);
                      }
                    });
                  }}
                />
                <AiFillDelete
                  style={{ cursor: "pointer",color:'red',marginLeft:'3px' }}
                  size={16}

                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${data.poNo && poNumberFormate(data.poNo)}</span> ?`,
                      text:'This Expense Data cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        hardDeleteResourceData(data);
                      }
                    });
                  }}
                />
                </>
              )}
            </div>
          ),
        };
      });

      setResourceListData(tableData);
      if (resourcePdfList) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourcePdfList._id
        );
        setResourcePdfList(dataGet[0]);
      }
      if (resourceEditData) {
        const dataGet = resourceList.filter(
          (item) => item._id === resourceEditData._id
        );
        setResourceEditData(dataGet[0]);
      }
    } else {
      setResourceListData([]);
    }
  }

  const handleResourceListData = ()=>{
    if (!resourceList || resourceList.length === 0) {
      setResourceListData([]);
      return;
    }
  
    const listData = resourceList.filter(item => {
      if (activeDataList === 'approved') {
        return item.paymentInfo.totalPayAmountIsApproved === true;
      }
      if (activeDataList === 'pending') {
        return item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === false;
      }
      return item.paymentInfo.totalPayAmountIsApproved !== true && item.paymentInfo.totalPayAmountIsRejected === true;
    });
  
    getAllResourceData(listData);
  }

  useEffect(() => {
    handleResourceListData()
  }, [resourceList , activeDataList]);

  const handleGraph = ()=>{
    setActiveGraph(true)
  }
  const handleTable = ()=>{
    setActiveGraph(false)
  }

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = resourceList.filter((item)=> item?._id === _id)
    setResourceEditData(data[0]);
    setOpenEditModal(true);
    handleModalOpen();
  }

  const closeResourceInfoModel = () => {
    setOpenResourceInfoModel(false);
    setSelectedItem({});
  };

  useEffect(()=>{
    if (resourcePdfList) {
      const dataGet = resourceList.filter(
        (item) => item._id === resourcePdfList._id
      );
      const data = dataGet[0];
      const paymentList = data?.paymentInfo?.list
      let invoices = []
      if(paymentList?.length > 0){
        invoices = paymentList?.flatMap((item, index) => {
          if (item?.invoice) {
            return item.invoice.map(invoice => ({...invoice, listIndex: index }));
          }
          return []; // or return an empty array to avoid undefined values
        });
      }

      setInvoiceData(invoices)
      setResourcePdfList(dataGet[0]);
    }
  },[resourcePdfList])
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  let menuBg = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const inputBg = useColorModeValue('white', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const textColor = useColorModeValue("secondaryGray.900", "white");


  const [isOpen, setIsOpen] = useState(false);
  const [activeBtn,setActiveBtn] = useState("")

  const toggleMenu = (btn) => {
    setIsOpen(!isOpen);
    setActiveBtn(btn)
  };
  const handleClose = () => {   
    setActiveBtn("");   
    setIsOpen(false)
  };
  return (
    <>
      <Box display="flex" className="mx-0" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" className="tab-btn mx-2">
        {!activeGraph &&
          <Box className="tab-btn-div">
            <div
              className={`div-btn ${
                activeDataList === "approved" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("approved")}
            >
              Approved
            </div>
            <div
              className={`div-btn ${
                activeDataList === "pending" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("pending")}
            >
              Pending
            </div>
            <div
              className={`div-btn ${
                activeDataList === "rejected" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("rejected")}
            >
              Rejected
            </div>
          </Box>
        }
        </Box>

        <div 
        className="graph_button internal-graph-btn mx-2"        
       >
        <button title="Data View" className={`graph_icon_button ${!activeGraph ? 'active_graph' : ''}`}  onClick={()=>handleTable()}><CiViewTable  /></button>
        {role !== 'Accountant' ?
        <button  title="Analytics View" className={`graph_icon_button ${activeGraph ? 'active_graph' : ''}`} onClick={()=>handleGraph()}><PiGraph/></button>
        :
        <button title="Analytics View"  className={`graph_icon_button ${activeGraph ? 'active_graph' : ''}`} style={{opacity:'0.3',cursor:'not-allowed'}}><PiGraph/></button>
        }
        
       </div>
       {!activeGraph && 
        <Box display="flex" mt={2} className="add-new-btn">
          <Box
            display="flex"
            alignItems="center"
            className="new-btn"
            onClick={handleModalOpen}
          >
            <FaPlus size={12} className="new-plus-icon"/> New
          </Box>
            
          <Box>
          <Menu>
          <MenuButton p="0px">
          <Box  className='menu-ellipsis-btn'><FaEllipsisV /></Box>

          </MenuButton>
          <MenuList
             boxShadow={shadow}
             p="0px"
             mt="10px"
             borderRadius="10px"
             bg={menuBg}
             border="none"
          >
            <Flex w="100%" mb="0px" flexDirection="column">
            <Box
                ps="20px"
                pt="10px"
                pb="8px"
                className="menu-text"
                borderBottom="1px solid gray"
                borderColor={borderColor}
                onClick={()=>toggleMenu('xlsx')}
                display="flex"
               
              >
                <CiExport size={20} className="mx-1"/> <span>Export XLSX</span>
                
              </Box>
             
            </Flex>
            <Flex w="100%" mb="0px" flexDirection="column">
            <Box
                ps="20px"
                pb="8px"
                pt="5px"
                borderBottom="1px solid gray"
                borderColor={borderColor}
                display="flex"
                className="menu-text"
                onClick={()=>toggleMenu('invoice')}


              >
                <TbFileExport size={20} className="mx-1"/> 
                <span>Export Invoice</span>
                
              </Box>
            </Flex>
           <Box marginRight={40}>
           {isOpen && (
            <ExportDataModel
              filename={"Resource_"}
              type="resource"
              role={role}
              isOpen={isOpen}
              handleClose={handleClose}
              activeBtn={activeBtn}
            />
          )}
           </Box>
          </MenuList>
        </Menu>
          </Box>
          
        </Box>
}
      </Box>
     
       <Box pt={{ base: "60px", md: "10px", xl: "10px" }}>

      {activeGraph ?
      <Box className={`graph-card ${activeGraph ? 'graph_active' : ''}`}>
          
      <ExpenseReports 
        type='resource'
        name = 'Resource Total Expense'
        heading = "Resource"
        icon = {GrResources}
        role={role}
      /> 
      </Box>
      :
        <ColumnTable
        columnsData = {activeDataList === 'rejected' ?rejectedDataColumns :activeDataList === 'pending'? pendingDataColumns:columnsDataColumns} 
        tableData={resourceListData}
          type={"Resource/Service Expense"}
          openInfoModal = {handleRowClick}
          expenseType = 'resource'

          activeDataList = {activeDataList}
          role={role}
          paymentData = {resourceData}


        />
      }
      </Box>
      {addNewModal && (
        <ResourceAddNew
          resourceOpenModal={addNewModal}
          resourceCloseModal={handleModalClose}
          isEditForm={openEditModal}
          editItem={resourceEditData}
          refreshEditData={handlerRefreshData}
          activeList = {activeDataList}
          role={role}

        />
      )}
      <PdfListModel
          pdfData={resourcePdfList ? invoiceData : []}
          currentId={resourcePdfList ? resourcePdfList._id : ""}
        openModal={showPdfList}
        closeModal={() => {
          setShowPdfList(false);
          setResourcePdfList(null);
        }}
        deleteFileFromList={deleteFileFromList}
        role={role}
      />
      {openResourceInfoModel && (
        <ResourceInfoModel
          openInfoModal={openResourceInfoModel}
          closeInfoModal={closeResourceInfoModel}
          selectedData={selectedItem}        
          role={role}
        />
      )}
    </>
  );
};

export default EmbrillResourceExpense;
