import {
  
    Table,
    Td,
    Tr,
    Th,
    Tbody,
    Thead,
    Tfoot,
  } from "@chakra-ui/react";
 
  import {  Pagination } from "react-bootstrap";
  import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
  import dateTimeFormat from "jsFunctions/dateTimeFormat";
  import { useEffect, useState } from "react";
  

  
  const RefundInfo = ({ selectedItem }) => {
    const [totalRefundAmount, setTotalRefundAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3; // Number of items to display per page
  
    useEffect(() => {
      if (selectedItem?.refundInfo?.list && selectedItem?.refundInfo?.list.length > 0) {
        const listOfPayment = selectedItem?.refundInfo?.list;
        const calculateTotalAmount = listOfPayment.reduce((acc, current) => {
          if (current && current?.amount && current?.amount !== '') {
            return acc + parseFloat(current.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalRefundAmount(calculateTotalAmount);
      }
    }, [selectedItem]);
  
    // Calculate pagination details
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedItem.refundInfo.list?.slice(indexOfFirstItem, indexOfLastItem) || [];
    const pageCount = Math.ceil(selectedItem.refundInfo.list?.length / itemsPerPage);
  
    return (
      <>
  
        {currentItems.length ? (
          <div className="info-table">
            <Table size="md" className="table-info" variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Payee</Th>
                  <Th>RefundAmount</Th>
                  <Th>Bank</Th>
                  <Th>Reference Number</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map((item, index) => (
                  <Tr key={index}>
                    <Td>{indexOfFirstItem + index + 1}</Td>
                    <Td>{item?.payee}</Td>
                    <Td>
                      <span className="rupee-symbol">₹</span>{" "}
                      {item?.amount ? formatAmountIndianRupees(Number(item?.amount)) : Number(0).toFixed(2)}
                    </Td>
                    <Td>{item?.bank}</Td>
                    <Td>{item?.refNo}</Td>
                    <Td>{dateTimeFormat(item?.paymentDate)}</Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th colSpan={2}>Total Refund Amount</Th>
                  <Td colSpan={4} className="total-spent">
                    <span className="rupee-symbol">₹</span>{" "}
                    {formatAmountIndianRupees(Number(totalRefundAmount))}
                  </Td>
                </Tr>
              </Tfoot>
            </Table>
  
            {/* Pagination Component */}
            <Pagination className="justify-content-end" style={{ marginTop: '20px' }}>
              <Pagination.Prev 
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} 
                disabled={currentPage === 1} 
              />
              {Array.from({ length: pageCount }, (_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next 
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))} 
                disabled={currentPage === pageCount} 
              />
            </Pagination>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  
  export default RefundInfo;
  