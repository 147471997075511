/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { Box, Button, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAsyncDeleteUser } from "redux/userSlice";
import { fetchAsyncGetUser } from "redux/userSlice";
import moment from "moment";
import UserForm from "components/allForm/UserForm";
import { FaPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

const Users = () => {
  const [addNewModal, setAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userEditData, setUserEditData] = useState(null);

  const [userListData, setUserListData] = useState([]);
  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Users');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  const columnsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Full Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    // {
    //   Header: "Role",
    //   accessor: "role",
    // },
    {
      Header: "Last Modified",
      accessor: "updatedAt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const userList = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    setAddNewModal(true);
  };
  const handleModalClose = () => {
    setAddNewModal(false);
    setOpenEditModal(false);
    setUserEditData(null);
  };

  useEffect(() => {
    dispatch(fetchAsyncGetUser());
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      const tableData = userList.map((data, index) => {
        return {
          _id:data?._id,
          no: index + 1,
          name: data.name,
          email: data.email,
          role: data.role,
          updatedAt: moment(data.updatedAt).format("DD-MM-YYYY hh:mm a"),
          action: (
            <div className="d-flex">
              <MdModeEdit
                className="me-2"
                color="blue"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleRowClick(e,data._id)
                }}
              />
             
              {role === "Admin" ? (
                <AiFillDelete
                  style={{ cursor: "pointer",marginLeft:'10px' }}
                  size={16}

                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure? you want to delete user <span style="color: #d33">Name : ${data?.name && data?.name}</span>.`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteUserData(data);
                      }
                    });
                  }}
                />
              ):'No Access'}
            </div>
          ),
        };
      });
      setUserListData(tableData);
    } else {
      setUserListData([]);
    }
  }, [userList]);

  const deleteUserData = (data) => {
    dispatch(fetchAsyncDeleteUser(data._id)).then(() => {
      handlerRefreshData();
    });
  };

  const handlerRefreshData = () => {
    dispatch(fetchAsyncGetUser());
  };

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = userList.filter((item)=> item?._id === _id)
    setOpenEditModal(true);
    setUserEditData(data[0]);
    handleModalOpen();
  }
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <>
       <Box display="flex" className="mx-0" justifyContent="space-between">
       <Box display="flex">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          mt={5}
          mx={2}
        >
          Users
        </Text>
       
        </Box>
        <Box display="flex" mt={2} className="add-new-btn">
          <Box
            display="flex"
            alignItems="center"
            className="new-btn"
            onClick={handleModalOpen}
          >
            <FaPlus size={12} className="new-plus-icon"/> New
          </Box>
            
        </Box>
     
      </Box>
      <Box pt={{ base: "40px", md: "10px", xl: "10px" }}>
        <ColumnTable
          columnsData={columnsDataColumns}
          tableData={userListData}
          type={"User List"}
          role={role}
          openInfoModal = {handleRowClick}
          expenseType = 'users'

        />
      </Box>
      {addNewModal && (
        <UserForm
          openModel={addNewModal}
          closeModel={handleModalClose}
          isEdit={openEditModal}
          userData={userEditData}
          refreshList={handlerRefreshData}
          role={role}

        />
      )}
    </>
  );
};

export default Users;
