// AddColumnPopover.js
import React, { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
} from "@chakra-ui/react";
import { FaPlus, FaPlusCircle } from 'react-icons/fa';
import { Form } from 'react-bootstrap';

const AddColumnPopover = ({ onAddColumn }) => {
  const [newHeading, setNewHeading] = useState('');
  const [insertPosition, setInsertPosition] = useState(0); // Default position

  const handleAddColumn = () => {
    if (newHeading.trim() === '') return; // Prevent adding empty headings
    onAddColumn(newHeading, insertPosition);
    setNewHeading(''); // Clear input
  };

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
      <Button className="add-project mt-0"
               >
                     <FaPlusCircle 
                     className="add-project-icon"
                     size={20}
                     />
                     
                </Button>
        
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Add New Column</PopoverHeader>
        <PopoverBody>
          <Form>
            <Form.Group className='mb-2'>
              <Form.Label>New Column Heading</Form.Label>
              <Form.Control
                type="text"
                value={newHeading}
                onChange={(e) => setNewHeading(e.target.value)}
                placeholder="Enter heading"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Insert Position (0 for first)</Form.Label>
              <Form.Control
                type="number"
                value={insertPosition}
                onChange={(e) => setInsertPosition(parseInt(e.target.value))}
                min={0}
              />
            </Form.Group>
            <Button colorScheme="green" className='mt-3' onClick={handleAddColumn}>
              Add
            </Button>
          </Form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AddColumnPopover;
