import React, { Suspense, useEffect, useState } from "react";
import "assets/css/App.css";
import "assets/css/SideBar.css";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "layouts/admin";
import "bootstrap/dist/css/bootstrap.min.css";
import SignIn from "views/auth/signIn";
import { useSelector, useDispatch } from "react-redux";
import { setToken, clearToken } from "redux/slices/Auth";
import UserNotFound from "views/auth/userNotFound";
import axios from "axios";

// base usrl
const apiURL = process.env.REACT_APP_BASE_URL;
const App = () => {
  // Get user data
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${apiURL}/check-session`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.data?.message?.username) {
          // setUserData(response.data?.message);
          localStorage.setItem(
            "userData",
            JSON.stringify(response.data?.message)
          );
          dispatch(
            setToken({
              // token: response?.data?.token,
              currUser: response.data?.message,
            })
          );
        } else {
          dispatch(clearToken({}));
          setUserData(null);
        }
        // setIsLoading(false);
      });
  }, []);
  const auth = useSelector((state) => state.auth);
  return (
    <Suspense>
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/user-not-found/:email" element={<UserNotFound />} />
        <Route path="*" element={auth.isAuth  ? <AdminLayout /> : <SignIn />} />
        {/* <Route path="/external" element={<EmbrillExternal />} /> */}
      </Routes>
    </Suspense>
  );
};

export default App;
