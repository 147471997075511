import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddResourceExpense = createAsyncThunk(
  "/fetchAsyncAddResourceExpense",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/resource/create`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetPONumresourceExpense = createAsyncThunk(
  "/fetchAsyncGetPONumresourceExpense",
  async (data) => {
    try {
      let url = `${API_URL}/resource/getPoNum`;
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteResourceExpense = createAsyncThunk(
  "/fetchAsyncDeleteResourceExpense",
  async (id) => {
    try {
      let url = `${API_URL}/resource/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncAddPanWithResourceExpense = createAsyncThunk(
  "/fetchAsyncAddPanWithResourceExpense",
  async (data) => {
    try {
      let url = `${API_URL}/resource/add-resourcePan`;
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncAddGstWithCompanyExpense = createAsyncThunk(
  "/fetchAsyncAddGstWithCompanyExpense",
  async (data) => {
    try {
      let url = `${API_URL}/resource/add-companyGst`;
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncDeleteResourceGstListItem = createAsyncThunk(
  "/fetchAsyncDeleteResourceGstListItem",
  async (id) => {
    try {
      let url = `${API_URL}/resource/delete-gst/${id}`;
      const response = await axiosInstance.delete( url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncDeleteResourceRefundListItem = createAsyncThunk(
  "/fetchAsyncDeleteResourceRefundListItem",
  async (data) => {
    try {
      let url = `${API_URL}/resource/delete-resource-refund-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
export const fetchAsyncGetPanResourceExpense = createAsyncThunk(
  "/fetchAsyncGetPanResourceExpense",
  async (data) => {
    try {
      let url = `${API_URL}/resource/get-resourcePan`;
      const response = await axiosInstance.post(url,{ data });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetGstCompanyExpense = createAsyncThunk(
  "/fetchAsyncGetGstCompanyExpense",
  async (data) => {
    try {
      let url = `${API_URL}/resource/get-companyGst`;
      const response = await axiosInstance.post(url,{ data });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetResourceExpense = createAsyncThunk(
  "/fetchAsyncGetResourceExpense",
  async () => {
    try {
      let url = `${API_URL}/resource/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteResourcePdf = createAsyncThunk(
  "/fetchAsyncDeleteResourcePdf",
  async (data) => {
    try {
      let url = `${API_URL}/resource/delete_pdf/${data.id}`;
      const response = await axiosInstance.post(url,{ key: data.key, listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncUpdateResourceExpense = createAsyncThunk(
  "/fetchAsyncUpdateResourceExpense",
  async (data) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/resource/update/${data.id}`, data.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUploadResourcePdf = createAsyncThunk(
  "/fetchAsyncUploadResourcePdf",
  async (data) => {
    try {
      let url = `${API_URL}/resource/upload_pdf/${data.id}`;
      const response = await axiosInstance.post(url,data.formData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncAddResourceInvoiceRemark = createAsyncThunk(
  "/fetchAsyncAddResourceInvoiceRemark",
  async (data) => {
    try {
      let url = `${API_URL}/resource/invoice-remark/${data.id}`;
      const response = await axiosInstance.put(url,data.remarkData);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncSearchResourceData = createAsyncThunk(
  "/fetchAsyncSearchResourceData",
  async (data) => {
    try {
      let url = `${API_URL}/resource/search`;
      if (data.value) {
        url = url + `?search=${data.value}`;
      }
      if (data.startDate && data.endDate && data.value) {
        url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      if (data.startDate && data.endDate && !data.value) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

// Dashboard Api
export const fetchAsyncGetResourceTotalPaymentAmount = createAsyncThunk(
  "/fetchAsyncGetResourceTotalPaymentAmount",
  async () => {
    try {
      let url = `${API_URL}/resource/graph-totalAmount`;
      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncApproveResourceTotalPayAmount = createAsyncThunk(
  "/fetchAsyncApproveResourceTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/resource/approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestApprovalResourceTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestApprovalResourceTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/resource/send-request-approve-total-pay-amount`;
      const response = await axiosInstance.post(url, data);
     
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestUpdateApprovalResourceTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestUpdateApprovalResourceTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/resource/send-request-update-approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncRejectResourceTotalPayAmount = createAsyncThunk(
  "/fetchAsyncRejectResourceTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/resource/reject-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
const AllResourceSlice = createSlice({
  name: "resourceData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetResourceExpense.pending]: (state) => {},
    [fetchAsyncGetResourceExpense.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncSearchResourceData.pending]: (state) => {},
    [fetchAsyncSearchResourceData.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const fetchAsyncGetResourceExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetResourceExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/resource/resourceFinencialYearData`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);

export const fetchAsyncResourceXlsxData = createAsyncThunk(
  "/fetchAsyncResourceXlsxData",
  async (data) => {
    try {
      let url = `${API_URL}/resource/resource-xlsx`;
     
      
      if (data?.role && data.startDate && data.endDate) {
        url = url + `?role=${data.role}&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
export const fetchAsyncDeleteResourcePaymentListItem = createAsyncThunk(
  "/fetchAsyncDeleteResourcePaymentListItem",
  async (data) => {
    try {
      let url = `${API_URL}/resource/delete-resource-payment-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
export const getAllResourceExpense = (state) => state.resourceData;

export default AllResourceSlice.reducer;
