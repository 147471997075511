export const columnsDataColumns = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Po No",
    accessor: "poNo",
  },
  {
    Header: "Purchase Order Date",
    accessor: "purchasedate",
  },
  {
    Header: "Major Type",
    accessor: "majorType",
  },
  {
    Header: "Minor Type",
    accessor: "minorType",
  },
  {
    Header: "Merchant Name",
    accessor: "merchantName",
  },
  {
    Header: "Approved Amount",
    accessor: "approvedAmount",
  },
  {
    Header: "Spent Amount",
    accessor: "spentAmount",
  },
  {
    Header: "Invoice",
    accessor: "invoice",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export const pendingDataColumns = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Po No",
    accessor: "poNo",
  },
  {
    Header: "Purchase Order Date",
    accessor: "purchasedate",
  },
  {
    Header: "Major Type",
    accessor: "majorType",
  },
  {
    Header: "Minor Type",
    accessor: "minorType",
  },
  {
    Header: "Merchant Name",
    accessor: "merchantName",
  },
  {
    Header: "Requested Amount",
    accessor: "approvalAmount",
  },

  {
    Header: "Invoice",
    accessor: "invoice",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
export const rejectedDataColumns = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Po No",
    accessor: "poNo",
  },
  {
    Header: "Purchase Order Date",
    accessor: "purchasedate",
  },
  {
    Header: "Major Type",
    accessor: "majorType",
  },
  {
    Header: "Minor Type",
    accessor: "minorType",
  },
  {
    Header: "Requested Amount",
    accessor: "approvalAmount",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];