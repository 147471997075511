import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   value: [{ startDate: new Date(), endDate: new Date() }],
// };
export const searchTextSlice = createSlice({
  name: "searchValue",
  initialState: {
    searchText:""
  },
  reducers: {
    setSearchTextGlobal: (state, { payload }) => {
      return {
        searchText: payload.searchText,
      };
    },
  },
});
export const { setSearchTextGlobal } = searchTextSlice.actions;
