/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
const apiURL = process.env.REACT_APP_BASE_URL;
import { clearToken } from "redux/slices/Auth";
import { MdOutlineLogout } from "react-icons/md";
export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("white", "white");
  let textColor = useColorModeValue("white", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  const dispatch = useDispatch();

  const { routes } = props;
  const { currUser } = useSelector((state) => state?.auth);

  const auth = currUser?.auth;
  // verifies if routeName is the one active (in browser input)
  const moduleAccess = module => auth?.some(a=> a.module === module)
  
  const roleAccess = (route)=>{
    const name = route?.module;
    const access = route?.access;
    const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === name);
    if(!roleObj){
      return false
    } else {
      return access.includes(roleObj?.access)
    }
  }
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  const logOutHandle = async () => {
    try {
      localStorage.removeItem("userData");
      dispatch(clearToken());
      window.location.href = `${apiURL}/signout`;
    } catch (error) {
      console.log(error);
    }
  };
  const innerData = (routesList) => {
    return routesList.map((route, index) => {
      if (currUser && route?.module !=='Log Out' && moduleAccess(route?.module) && roleAccess(route)) {
        if (route.category) {
          return (
            <>
              <Text
                fontSize={"md"}
                color={activeColor}
                fontWeight="bold"
                mx="auto"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                pt="18px"
                pb="12px"
                key={index}
                className="nav-text"

              >
                {route.name}
              </Text>
              {createLinks(route.items)}
            </>
          );
        } else if (route.layout === "/" && route.name !== "Log Out") {
          return (
            <NavLink key={index} to={route.path} className="sidebar-link">
              {route.icon ? (
                <Box>
                  <HStack
                    className="nav-stack"
                  >
                    <Flex w="100%"   className={`${activeRoute(route.path.toLowerCase())?'active-route':""}`}>
                      <Box

                        me="18px"
                        className="nav-item"
                        display="flex"
                      >
                        <span className="nav-icon ">{route.icon}</span>
                      <Text
                        me="auto"
                        className="nav-text mt-1"

                      >
                        {route.name}
                      </Text>
                      </Box>
                    </Flex>
                   
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                      className="nav-stack"
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </NavLink>
          );
        } else if (route.layout === "/" && route.name == "Log Out") {
          return (
            <div
              key={index}
              onClick={() => {
                logOutHandle();
              }}
              style={{ cursor: "pointer" }}
            >
              {route.icon ? (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                    className="nav-stack"
                  >
                    <Flex w="100%" alignItems="center" justifyContent="center" className={`${activeRoute(route.path.toLowerCase())?'active-route':""}`}>
                      <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me="18px"
                        className="nav-icon"

                      >
                        {route.icon}
                      </Box>
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                        className="nav-text"
                      >
                        {route.name}
                      </Text>
                    </Flex>
                    <Box
                      h="36px"
                      w="4px"
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius="5px"
                    />
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </div>
          );
        }
      }
      else{
        if (route.layout === "/" && route.name == "Log Out") {
          return (
            <div
              key={index}
              onClick={() => {
                logOutHandle();
              }}
              style={{ cursor: "pointer" }}
            >
              {route.icon ? (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                    className="nav-stack"
                  >
                   
                    <Box
                      display="flex"
                      w={200}
                      justifyContent="center"
                      className="logout-btn"
                    >
                      <MdOutlineLogout size={20} className="mx-2" />{" "}
                      <span>Log out</span>
                    </Box>
                    <Box
                      h="36px"
                      w="4px"
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius="5px"
                    />
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </div>
          );
        }
      }
    });
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    let topData = routes.filter((route) => route.place !== "bottom");
    let bottomData = routes.filter((route) => route.place === "bottom");
    // return [...innerData(topData), ...innerData(bottomData)];
    return (
      <Flex direction={"column"} justifyContent={"space-between"} height="100%">
        <Box>{innerData(topData)}</Box>
        {bottomData.length > 0 && <Box>{innerData(bottomData)}</Box>}
      </Flex>
    );
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
