import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddInternalExpense = createAsyncThunk(
  "/fetchAsyncAddInternalExpense",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/internal/create`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetInternalExpense = createAsyncThunk(
  "/fetchAsyncGetInternalExpense",
  async () => {
    try {
      let url = `${API_URL}/internal/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetPONumInternalExpense = createAsyncThunk(
  "/fetchAsyncGetPONumInternalExpense",
  async (data) => {
    try {
      let url = `${API_URL}/internal/getPoNum`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteInternalExpense = createAsyncThunk(
  "/fetchAsyncDeleteInternalExpense",
  async (id) => {
    try {
      let url = `${API_URL}/internal/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteInternalGstListItem = createAsyncThunk(
  "/fetchAsyncDeleteInternalGstListItem",
  async (id) => {
    try {
      let url = `${API_URL}/internal/delete-gst/${id}`;
      const response = await axiosInstance.delete( url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
export const fetchAsyncDeleteInternalPdf = createAsyncThunk(
  "/fetchAsyncDeleteInternalPdf",
  async (data) => {
    try {
      let url = `${API_URL}/internal/delete_pdf/${data.id}`;
      const response = await axiosInstance.post( url,{ key: data.key,listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncUpdateInternalExpense = createAsyncThunk(
  "/fetchAsyncUpdateInternalExpense",
  async (data) => {
    try {
      const response = await axiosInstance.put( `${API_URL}/internal/update/${data.id}`,data.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUploadInternalPdf = createAsyncThunk(
  "/fetchAsyncUploadInternalPdf",
  async (data) => {
    try {
      let url = `${API_URL}/internal/upload_pdf/${data.id}`;
      const response = await axiosInstance.post(url, data.formData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncAddInternalInvoiceRemark = createAsyncThunk(
  "/fetchAsyncAddInternalInvoiceRemark",
  async (data) => {
    try {
      let url = `${API_URL}/internal/invoice-remark/${data.id}`;
      const response = await axiosInstance.put(url, data.remarkData);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncSearchInternalData = createAsyncThunk(
  "/fetchAsyncSearchInternalData",
  async (data) => {
    try {
      let url = `${API_URL}/internal/search`;
      if (data.value) {
        url = url + `?search=${data.value}`;
      }
      if (data.startDate && data.endDate && data.value) {
        url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      if (data.startDate && data.endDate && !data.value) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

// Dashboard Api
export const fetchAsyncGetInternalTotalPaymentAmount = createAsyncThunk(
  "/fetchAsyncGetInternalTotalPaymentAmount",
  async () => {
    try {
      let url = `${API_URL}/internal/graph-totalAmount`;
      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const fetchAsyncApproveTotalPayAmount = createAsyncThunk(
  "/fetchAsyncApproveTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/internal/approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncApproveSpentAmount = createAsyncThunk(
  "/fetchAsyncApproveSpentAmount",
  async (data) => {
    try {
      let url = `${API_URL}/internal/approve-spent-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncSendRequestApprovalTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestApprovalTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/internal/send-request-approve-total-pay-amount`;
      const response = await axiosInstance.post(url, data);
     
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestUpdateApprovalTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestUpdateApprovalTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/internal/send-request-update-approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestUpdateApprovalSpentAmount = createAsyncThunk(
  "/fetchAsyncSendRequestUpdateApprovalSpentAmount",
  async (data) => {
    try {
      let url = `${API_URL}/internal/send-request-update-approve-spent-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncRejectTotalPayAmount = createAsyncThunk(
  "/fetchAsyncRejectTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/internal/reject-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncRejectSpentAmount = createAsyncThunk(
  "/fetchAsyncRejectSpentAmount",
  async (data) => {
    try {
      let url = `${API_URL}/internal/reject-spent-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteInternalPaymentListItem = createAsyncThunk(
  "/fetchAsyncDeleteInternalPaymentListItem",
  async (data) => {
    try {
      let url = `${API_URL}/internal/delete-internal-payment-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
export const fetchAsyncDeleteInternalRefundListItem = createAsyncThunk(
  "/fetchAsyncDeleteInternalRefundListItem",
  async (data) => {
    try {
      let url = `${API_URL}/internal/delete-internal-refund-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncInternalXlsxData = createAsyncThunk(
  "/fetchAsyncInternalXlsxData",
  async (data) => {
    try {
      let url = `${API_URL}/internal/internal-xlsx`;
     
      
      if (data?.role && data.startDate && data.endDate) {
        url = url + `?role=${data.role}&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
    
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

const AllInternalDetails = createSlice({
  name: "internalData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetInternalExpense.pending]: (state) => {},
    [fetchAsyncGetInternalExpense.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncSearchInternalData.pending]: (state) => {},
    [fetchAsyncSearchInternalData.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const getAllInternalExpense = (state) => state.internalData;

export default AllInternalDetails.reducer;
