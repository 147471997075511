import {
  Flex,
} from "@chakra-ui/react";
import React from "react";

export default function SidebarDocs() {
  const appVersion = process.env.REACT_APP_VERSION;
  return (
    <Flex
      justify="flex-start"
      // direction="column"
      ml={8}
      align="center"
      opacity={0.4}
      position="relative"
      fontWeight={700}
      fontSize={14}
      color="#fff"
      // className="nav-text"
    >
      {appVersion}
    </Flex>
  );
}
