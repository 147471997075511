import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,

} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { InputGroup, Tab, Tabs } from "react-bootstrap";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import GstInfo from "./components/gstInfo";
import PaymentInfo from "./components/paymentInfo";
import InvoiceInfo from "./components/invoicceInfo";
import RefundInfo from "./components/refundInfo";

const DataRow = ({ label, value , type }) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {type === 'Amount' 
      ?
      <InputGroup>
        <InputGroup.Text style={{height:'2rem', border:0, background:'lightGray'}}><span className="rupee-symbol">₹</span> </InputGroup.Text>
        <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? formatAmountIndianRupees(value): " "}
        disabled
      />
      </InputGroup>
      :
      <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? value : " "}
        disabled
      />
      }
    </>
  );
};

const InternalInfoModel = ({ openInfoModal, closeInfoModal, selectedData ,role}) => {
  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
  const [selectedItem, setSelectedItem] = useState(selectedData);

  const [activeTab, setActiveTab] = useState("information");
  const [historyTab, setHistoryTab] = useState("requested");


  useEffect(() => {
    setSelectedItem(selectedData);
  }, [selectedData]);

  useEffect(() => {
    if (selectedItem.orderMonth) {
      const dateString = selectedItem.orderMonth;
      const date = moment(dateString);

      const month = date.format("MMM");
      const year = date.format("YYYY");
      setGetPoMonth(`${year}-${month}`);
    } else {
      setGetPoMonth("");
    }
    
   
  }, [selectedItem]);

  const [remainingAmount, setRemainingAmount] = useState('')
  const merchantName = useMemo(() => {
    if (selectedItem?.merchantId) {
        return selectedItem.merchantId.companyName || "";
    }
    return selectedItem?.merchantName || "";
}, [selectedItem]);
  useEffect(()=>{
    if(selectedItem.paymentInfo.totalPayAmount){
      if(selectedItem?.paymentInfo?.list) {
        const array = selectedItem?.paymentInfo?.list;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          const total_pay_amount = parseFloat(selectedItem.paymentInfo.totalPayAmount) - parseFloat(selectedItem.tdsApplicable.totalTDS)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(selectedItem.paymentInfo.totalPayAmount) - parseFloat(selectedItem.tdsApplicable.totalTDS)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }
      else{
        const total_pay_amount = parseFloat(selectedItem.paymentInfo.totalPayAmount) - parseFloat(selectedItem.tdsApplicable.totalTDS)
        setRemainingAmount(parseFloat(total_pay_amount))
      }

    }
  },[selectedItem.paymentInfo.totalPayAmount])

 
  return (
    <div>
      <Modal
        isOpen={openInfoModal}
        onClose={closeInfoModal}
        size="xl"
        className="modal-main"
      >
        <ModalOverlay />
        <ModalContent className="info-model">
          <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
              <span>Internal - Local Expenses Information</span>
            </ModalHeader>
            <ModalCloseButton className="expense-modal-header" />
          </div>
          <ModalBody>
            <Form className="addForm">
              <Tabs
                id="justify-tab-example1"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className=""
                style={{ marginTop: "4rem" }}
                justify
              >
                <Tab
                  eventKey="information"
                  title="Information"
                  className="main-tab-item"
                >
                  <div className="card">
                    <h5 className="heading-h5"> Purchase Order Details</h5>

                    <Row className="info-row">
                      <Col>
                        <DataRow
                          label="Order Number"
                          value={poNumberFormate(selectedItem.poNo)}
                        />
                      </Col>
                      <Col>
                        <DataRow label="Order Month" value={getPoMonth} />
                      </Col>
                    </Row>
                    <Row className="info-row">
                      <Col>
                        <DataRow
                          label="Order Date"
                          value={
                            selectedItem.orderDate
                              ? moment(selectedItem.orderDate).format(
                                  "DD-MMM-yyyy"
                                )
                              : ""
                          }
                        />
                      </Col>
                      <Col></Col>
                    </Row>

                    <Divider
                      className="info-divider"
                      orientation="horizontal"
                      m={3}
                    />
                    <h5 className="heading-h5">Expense</h5>

                    <Row className="info-row">
                      <Col>
                        <DataRow
                          label="Office"
                          value={selectedItem.officeType}
                        />
                      </Col>
                      <Col>
                        
                        <DataRow
                        label="Merchant Name"
                        value={merchantName}
                        />
                    </Col>
                    </Row>
                    <Row className="info-row">
                      <Col>
                        <DataRow
                          label="Major Type"
                          value={selectedItem.expense.majorType}
                        />
                      </Col>
                      <Col>
                        <DataRow
                          label="Client Name"
                          value={selectedItem?.clientName?.clientName}
                        />
                      </Col>
                    </Row>
                    <Row className="info-row">
                      <Col>
                        <DataRow
                          label="Minor Type"
                          value={selectedItem.minorType}
                        />
                      </Col>
                      <Col>
                        <DataRow
                          label="Minor Sub Category"
                          value={selectedItem.minorSubCategory}
                        />
                      </Col>
                    </Row>
                    <Row className="info-row">
                        <Col>
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled
                            rows={4}
                            value={selectedItem?.remarks}
                          />
                        </Col>
                      </Row>
                   
                    <Row className="info-row">
                      <Col className="check-cross-mark mt-2 mb-2">
                        <Form.Label>
                          <span
                            className={
                              selectedItem.expense.isExpense
                                ? "checkmark"
                                : "crossmark"
                            }
                          ></span>{" "}
                          Is it Freight Expense?
                        </Form.Label>
                      </Col>
                    </Row>

                    {selectedItem.expense.isExpense && (
                      <Row className="info-row mb-3">
                        <Col>
                          <Flex direction="column">
                            <DataRow
                              label="Delivery Partner"
                              value={selectedItem.expense.agency}
                            />
                          </Flex>
                        </Col>
                        <Col>
                          <Flex direction="column">
                            <DataRow
                              label="Tracking Number"
                              value={selectedItem.expense.trackingNum}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    )}
                   

                   
                    <Divider
                      className="info-divider"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">TDS Applicable</h5>
                    <Row className="info-row">
                      <Col className="check-cross-mark mt-0">
                        <Form.Label>
                          <span
                            className={
                              selectedItem.tdsApplicable.isTDS
                                ? "checkmark"
                                : "crossmark"
                            }
                          ></span>{" "}
                          Is TDS applicable?
                        </Form.Label>
                      </Col>
                    </Row>

                    {selectedItem.tdsApplicable.isTDS && (
                      <>
                        <Row className="info-row">
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Total Amount "
                                value={selectedItem.tdsApplicable.totalAmount}
                                type="Amount"
                              />
                            </Flex>
                          </Col>
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="TDS Percent "
                                value={selectedItem.tdsApplicable.tdsPercent}
                              />
                            </Flex>
                          </Col>
                        </Row>
                        <Row className="info-row">
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                type="Amount"
                                label="TDS Amount  "
                                value={selectedItem.tdsApplicable.totalTDS}
                              />
                            </Flex>
                          </Col>
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="TDS PO   "
                                value={
                                  selectedItem.tdsApplicable.generateTdsPO
                                    .isTDSPO
                                    ? poNumberFormate(
                                        selectedItem.tdsApplicable.generateTdsPO
                                          .TDSPONum
                                      )
                                    : ""
                                }
                              />
                            </Flex>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Divider
                      className="info-divider"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">Reimbursement Information</h5>
                    <Row className="info-row mb-3">
                      <Col>
                        <Flex direction="column">
                          <DataRow
                            label="Invoice Number "
                            value={selectedItem.reimbursementInfo?.invoiceNum}
                          />
                        </Flex>
                      </Col>
                      <Col>
                        <Flex direction="column">
                          <DataRow
                            label="Invoice Date"
                            value={
                              selectedItem.reimbursementInfo?.invoiceDate
                                ? moment(
                                    selectedItem.reimbursementInfo?.invoiceDate
                                  ).format("DD-MMM-yyyy")
                                : ""
                            }
                          />
                        </Flex>
                      </Col>
                    </Row>
                    <Row className="info-row">
                      <Col className="check-cross-mark mt-0">
                        <Form.Label>
                          <span
                            className={
                              selectedItem.reimbursementInfo.isReimbursement
                                ?.isReimburs
                                ? "checkmark"
                                : "crossmark"
                            }
                          ></span>{" "}
                          Is it Reimbursed?
                        </Form.Label>
                      </Col>
                    </Row>

                    {selectedItem.reimbursementInfo.isReimbursement
                      ?.isReimburs && (
                      <>
                        <Row className="info-row">
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Payment Date"
                                value={
                                  selectedItem.reimbursementInfo.isReimbursement
                                    ?.paymentDate
                                    ? moment(
                                        selectedItem.reimbursementInfo
                                          .isReimbursement?.paymentDate
                                      ).format("DD-MMM-yyyy")
                                    : ""
                                }
                              />
                            </Flex>
                          </Col>
                          <Col></Col>
                        </Row>
                        <Row className="info-row">
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Currency "
                                value={
                                  selectedItem.reimbursementInfo.isReimbursement
                                    ?.currency
                                }
                              />
                            </Flex>
                          </Col>
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                type="Amount"
                                label="Amount  "
                                value={
                                  selectedItem.reimbursementInfo.isReimbursement
                                    ?.amount
                                }
                              />
                            </Flex>
                          </Col>
                        </Row>
                        <Row className="info-row mb-3">
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                label="Coversion Rate  "
                                value={
                                  selectedItem.reimbursementInfo.isReimbursement
                                    ?.conversionRate
                                }
                              />
                            </Flex>
                          </Col>
                          <Col>
                            <Flex direction="column">
                              <DataRow
                                type="Amount"
                                label="Amount in INR "
                                value={
                                  selectedItem.reimbursementInfo.isReimbursement
                                    ?.amountInINR
                                }
                              />
                            </Flex>
                          </Col>
                        </Row>
                      </>
                    )}
                   
                    <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">Payment Information</h5>
                    <PaymentInfo selectedItem = {selectedItem} remainingAmount={remainingAmount}/>

                    <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">Refund Amount Information</h5>
                    <RefundInfo selectedItem = {selectedItem}/>

                    <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">Invoice</h5>
                    <InvoiceInfo selectedItem={selectedItem} />
                    <Divider
                      className="info-divider"
                      orientation="horizontal"
                    />
                    <h5 className="heading-h5">GST Applicable</h5>
                    <GstInfo selectedItem={selectedItem} />
                  </div>
                </Tab>
                {role === "Manager" || role === "Admin" ? (
                  <Tab eventKey="history" title="History">
                    <Form className="addForm addform2">
                      <Tabs
                        id="justify-tab-example3"
                        activeKey={historyTab}
                        onSelect={(k) => setHistoryTab(k)}
                        transition={true}
                        className="mt-0"
                      >
                        <Tab
                          eventKey="requested"
                          className="inner-tab"
                          title="Requested"
                        >
                          <div className="inner-card">
                            <h5 className="heading-h5">
                              Requested Amount Details
                            </h5>
                            <Row className="info-row">
                              <Col className="check-cross-mark mt-2">
                                <Form.Label>
                                  <span
                                    className={
                                      selectedItem.paymentInfo
                                        ?.payAmountApproveIsRequest
                                        ? "checkmark"
                                        : "crossmark"
                                    }
                                  ></span>{" "}
                                  Is requested amount send for approval?
                                </Form.Label>
                                <br />
                              </Col>
                            </Row>
                            <Row className="info-row">
                              <Col>
                                <DataRow
                                  label="Requested At"
                                  value={
                                    selectedItem.paymentInfo?.requestedAt
                                      ? moment(
                                          selectedItem.paymentInfo?.requestedAt
                                        ).format("DD-MMM-yyyy hh:mm A")
                                      : ""
                                  }
                                />
                              </Col>
                              <Col>
                                <DataRow
                                  label="Requested By"
                                  value={selectedItem.paymentInfo?.requestedBy}
                                />
                              </Col>
                            </Row>

                            <Divider
                              className="info-divider mt-3"
                              orientation="horizontal"
                            />
                            <h5 className="heading-h5">Last Updated Details</h5>

                            <Row className="info-row mb-3">
                              <Col>
                                <DataRow
                                  label="Last Updated At"
                                  value={
                                    selectedItem.lastUpdatedAt
                                      ? moment(
                                          selectedItem.lastUpdatedAt
                                        ).format("DD-MMM-yyyy hh:mm A")
                                      : ""
                                  }
                                />
                              </Col>
                              <Col>
                                <DataRow
                                  label="Last Updated By"
                                  value={selectedItem.updatedBy}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Tab>
                        {!selectedItem.paymentInfo?.totalPayAmountIsRejected ? (
                          <Tab eventKey="approved" title="Approved">
                            <div className="inner-card">
                              <h5 className="heading-h5">
                                Requested Amount Details
                              </h5>
                              <Row className="info-row">
                                <Col className="check-cross-mark mt-2">
                                  <Form.Label>
                                    <span
                                      className={
                                        selectedItem.paymentInfo
                                          ?.totalPayAmountIsApproved
                                          ? "checkmark"
                                          : "crossmark"
                                      }
                                    ></span>{" "}
                                    Is requested amount is approved ?
                                  </Form.Label>
                                  <br />
                                </Col>
                              </Row>
                              <Row className="info-row">
                                <Col>
                                  <DataRow
                                    label="Approved At"
                                    value={
                                      selectedItem.paymentInfo?.approvedAt
                                        ? moment(
                                            selectedItem.paymentInfo?.approvedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Approved By"
                                    value={selectedItem.paymentInfo?.approvedBy}
                                  />
                                </Col>
                              </Row>

                              <Divider
                                className="info-divider mt-3"
                                orientation="horizontal"
                              />
                              <h5 className="heading-h5">
                                Last Updated Details
                              </h5>

                              <Row className="info-row mb-3">
                                <Col>
                                  <DataRow
                                    label="Last Updated At"
                                    value={
                                      selectedItem.lastUpdatedAt
                                        ? moment(
                                            selectedItem.lastUpdatedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Last Updated By"
                                    value={selectedItem.updatedBy}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Tab>
                        ) : (
                          <Tab eventKey="approved" title="Rejected">
                            <div className="inner-card">
                              <h5 className="heading-h5">
                                Requested Amount Details
                              </h5>
                              <Row className="info-row">
                                <Col className="check-cross-mark mt-2">
                                  <Form.Label>
                                    <span
                                      className={
                                        selectedItem.paymentInfo
                                          ?.totalPayAmountIsRejected
                                          ? "checkmark"
                                          : "crossmark"
                                      }
                                    ></span>{" "}
                                    Is requested amount is rejected ?
                                  </Form.Label>
                                  <br />
                                </Col>
                              </Row>
                              <Row className="info-row">
                                <Col>
                                  <DataRow
                                    label="Rejected At"
                                    value={
                                      selectedItem.paymentInfo?.rejectedAt
                                        ? moment(
                                            selectedItem.paymentInfo?.rejectedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Rejected By"
                                    value={selectedItem.paymentInfo?.rejectedBy}
                                  />
                                </Col>
                              </Row>

                              <Divider
                                className="info-divider mt-3"
                                orientation="horizontal"
                              />
                              <h5 className="heading-h5">
                                Last Updated Details
                              </h5>

                              <Row className="info-row mb-3">
                                <Col>
                                  <DataRow
                                    label="Last Updated At"
                                    value={
                                      selectedItem.lastUpdatedAt
                                        ? moment(
                                            selectedItem.lastUpdatedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Last Updated By"
                                    value={selectedItem.updatedBy}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Tab>
                        )}
                      </Tabs>
                    </Form>
                  </Tab>
                ) : (
                  <Tab eventKey="" title="" disabled></Tab>
                )}
              </Tabs>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default InternalInfoModel;
