/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

import { Badge, Box, Button, IconButton, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import { AiFillDelete, AiFillEye, AiOutlineFileExcel } from "react-icons/ai";
import EmbrillProfileAddNew from "components/allForm/EmbrillProfile/embrillProfileAddNew";
import EmbrillProfileInfoModel from "components/models/EmbrillProfileInfoModal";
import { fetchAsyncGetEmbrillProfile } from "redux/embrillProfileSlice";
import { fetchAsyncDeleteEmbrillProfile } from "redux/embrillProfileSlice";
import { RiFileExcel2Fill } from "react-icons/ri";
import { performSearch } from "components/navbar/searchUtils";
import { useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

const EmbrillProfile = () => {
  
  const dispatch = useDispatch();

  const [embrillProfileListData, setEmbrillProfileListData] = useState([]);


  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  
  const searchValue = useSelector((state) => state.searchValue); // Access the dateRange slice
  const {searchText} = searchValue;
  const location = useLocation();

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Client and Projects');
      if(roleObj){
        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  
  const columnsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    
    {
        Header: "Bank Name",
        accessor: "bankName",
    },
    {
        Header: "Branch Name",
        accessor: "branchName",
    },
    {
      Header: "Account Number",
      accessor: "accountNumber",
    },
   
    {
      Header: "IFSC Code",
      accessor: "ifscCode",
    },
    {
      Header: "Contact Person Name",
      accessor: "contactPerson",
   },
   {
    Header: "Payment Attached",
    accessor: "attachePayment",
   },
    
    {
      Header: "Action",
      accessor: "action",
    },
    
  ];
  const [openEmbrillProfileModal, setOpenEmbrillProfileModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [embrillProfileEditData, setEmbrillProfileEditData] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [openEmbrillProfileInfoModel, setOpenEmbrillProfileInfoModel] = useState(false);

  const handleOpenEmbrillProfileModal = () => {
    setOpenEmbrillProfileModal(true);
  };


  const handleCloseEmbrillProfileModal = () => {
    setOpenEmbrillProfileModal(false);
    setOpenEditModal(false);
    setEmbrillProfileEditData(null);
    handlerRefreshData();
  };
  const handlerRefreshData = useCallback(
    () => {
      performSearch(dispatch, location, searchText, "", "");
    },
    [location, dispatch,searchText]
  );

  
  const embrillProfileList = useSelector((state) => state.embrillProfileData);

  const deleteEmbrillProfile = (data) => {
    dispatch(fetchAsyncDeleteEmbrillProfile(data._id)).then(() => {
      handlerRefreshData();
    });
  };
  
  useEffect(() => {
    if (embrillProfileList && embrillProfileList.length > 0) {
        const tableData = embrillProfileList.map((data, index) => {
            return {
              _id:data._id,
              no: index + 1,
             
              accountNumber: data?.accountNumber,
              ifscCode: data?.ifscCode,
              branchName: data?.branchName,

              bankName: (
            <span 
              style={{ cursor: "pointer",color:'blue'}}
            >
              {data?.bankName}
            </span>
          ),
              attachePayment: (
                <div class="position-relative" >
                <RiFileExcel2Fill size={20} />
                <span class="position-absolute top-0 start-100 translate-middle badge  bg-primary text-white">
                {data?.excelData && data?.excelData?.length ? data?.excelData?.length : 0}
                </span>
                </div>
              ),
              // attachePayment:(
              //   <Badge variant='subtle' colorScheme='green'>
              //    {data?.excelData?.length}
              // </Badge>
              // ),
            contactPerson: data?.contactPerson,
              action: (
                <div className="d-flex">
              <MdModeEdit
                className="me-2"
                color="blue"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleRowClick(e,data._id)
                }}
              />
              <AiFillEye
                className="me-2"
                color="#000D55"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedItem(data);
                  setOpenEmbrillProfileInfoModel(true);
                }}
              />
                  {role === "Admin" ? (
                    <AiFillDelete
                      style={{ cursor: "pointer",marginLeft:'10px' }}
                      size={16}
    
                      onClick={(e) => {
                        e.preventDefault();
                        Swal.fire({
                          title: `Are you sure? you want to delete profile Data</span>.`,
                          text:'This action cannot be undone.',
                          icon: "warning",
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete",
                          showCancelButton: true,
                          customClass: {
                            container: "sweet_containerImportant",
                            confirmButton: "sweet_confirmbuttonImportant",
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteEmbrillProfile(data);
                          }
                        });
                      }}
                    />
                  ):'No Access'}
                </div>
              ),
            };
          });
      setEmbrillProfileListData(tableData);
    } else {
      setEmbrillProfileListData([]);
    }
  }, [embrillProfileList]);


  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = embrillProfileList.filter((item)=> item?._id === _id)
    setOpenEditModal(true);
    setEmbrillProfileEditData(data[0]);
    handleOpenEmbrillProfileModal();
  }

  const closeEmrillProfileInfoModal = () => {
    setOpenEmbrillProfileInfoModel(false);
    setSelectedItem({});
  };

useEffect(() => {
    dispatch(fetchAsyncGetEmbrillProfile());
  }, []);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <>
      <Box display="flex" className="mx-0" justifyContent="space-between">
      <Box display="flex">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          mt={5}
          mx={2}
        >
          Embrill Profile
        </Text>
       
        </Box>
        <Box display="flex" mt={2} className="add-new-btn">
          <Box
            display="flex"
            alignItems="center"
            className="new-btn"
            onClick={handleOpenEmbrillProfileModal}
          >
            <FaPlus size={12} className="new-plus-icon"/> New
          </Box>
            
        
         
        </Box>
     
      </Box>
      <Box pt={{ base: "40px", md: "10px", xl: "10px" }}>
        <ColumnTable
          columnsData={columnsDataColumns}
          tableData={embrillProfileListData}
          type={"Embrill Profile"}
          role={role}
          expenseType = 'embrill-profile'
          openInfoModal = {handleRowClick}

        />
      </Box>
      {openEmbrillProfileModal && (
        <EmbrillProfileAddNew
          openModal={openEmbrillProfileModal}
          closeModal={handleCloseEmbrillProfileModal}
          isEditForm={openEditModal}
          editItem={embrillProfileEditData}
          role={role}
          refreshEditData={handlerRefreshData}

        />
      )}
      {openEmbrillProfileInfoModel && (
        <EmbrillProfileInfoModel
          openInfoModal={openEmbrillProfileInfoModel}
          closeInfoModal={closeEmrillProfileInfoModal}
          selectedData={selectedItem}
          role={role}
        />
      )}
    </>
  );
};

export default EmbrillProfile
