/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa6";
import { FaCheckCircle, FaEdit } from "react-icons/fa";

// Icon component
const Icon = ({ isOpen }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="#222"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={isOpen ? "translate" : ""}
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

// CustomSelect component
const CustomSelect = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  align,
  defaultValue,
  role,
  isInvalid
}) => {
  // State variables using React hooks
  const [showMenu, setShowMenu] = useState(false); // Controls the visibility of the dropdown menu
  const [selectedClientProjectValue, setSelectedClientProjectValue] = useState(isMulti ? [] : null); // Stores the selected value(s)
  const [searchValue, setSearchValue] = useState(""); // Stores the value entered in the search input
  const searchRef = useRef(); // Reference to the search input element
  const inputRef = useRef(); // Reference to the custom select input element

 
  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    if (defaultValue && defaultValue.value) {
      setSelectedClientProjectValue(defaultValue);
    } else {
      setSelectedClientProjectValue(null);
    }
  }, [defaultValue]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, [inputRef]);

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedClientProjectValue || selectedClientProjectValue.length === 0) {
      return placeHolder;
    }
    return selectedClientProjectValue.label;
  };

  const removeOption = (option) => {
    return selectedClientProjectValue.filter((o) => o.value !== option.value);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (selectedClientProjectValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedClientProjectValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedClientProjectValue(newValue);
    onChange(newValue);
    setShowMenu(false);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedClientProjectValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedClientProjectValue) {
      return false;
    }

    return selectedClientProjectValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

 

  return (
    <div ref={inputRef} className="custom--dropdown-container">
      <div onClick={handleInputClick} className={`dropdown-input`}>
        <div className={`dropdown-selected-value`}>{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon isOpen={showMenu} />
          </div>
        </div>
      </div>

      {showMenu && (
        <div className={`dropdown-menu alignment--${align || "auto"}`}>
          {isSearchable && (
            <div className="search-box">
              <input
                className="form-control"
                onChange={onSearch}
                value={searchValue}
                ref={searchRef}
                isInvalid = {isInvalid}

              />
            </div>
          )}
          {getOptions().map((option) => (
            <div className="d-flex" key={option.value}>
             
              <div
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(option);
                }}
                className={`dropdown-item ${isSelected(option) && "selected"}`}
              >
                {option.label}
                
              </div>
              
             
            </div>
          ))}
         
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
