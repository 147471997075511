import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { poMonthFormate } from "jsFunctions/helperFunctions";

export const getResourceExcelData = (resourceList) => {
    let list = JSON.parse(JSON.stringify(resourceList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data.poNo),
        "Current Order Date": data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
        "Current Order Month": data.orderMonth && poMonthFormate(data.orderMonth),

        "Major Type": data.expense?.majorType,
        "Minor Type": data?.minorType,
        "Minor Sub Category": data?.minorSubCategory,
        "Client Name": data.clientName?.clientName,
        "Project Name": data.projectName?.projectName,
        "Resource Name": data.resourceName,
        "Remarks": data.remarks,
        
        "Requested Amount":`₹ ${data.paymentInfo?.approvalAmount  ? formatAmountIndianRupees(data.paymentInfo?.approvalAmount) : Number(0).toFixed(2)}`,
        "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,
        
        "Is It Refund Amount?": data?.refundInfo?.isRefund ? "true" : "false",
        "Refund Payee": data?.refundInfo?.payee || "",
        "Refund Amount": `₹ ${data?.refundInfo?.amount ? formatAmountIndianRupees(data?.refundInfo.amount ) : Number(0).toFixed(2)}`,
        "Refund Bank": data?.refundInfo?.bank || "",
        "Refund Reference Number": data?.refundInfo?.referenceNumber || "",
        "Refund Date": data?.refundInfo?.refundDate && moment(data?.refundInfo?.refundDate).format("DD-MMM-YYYY"),

        "Reimbursement Applicable?": data?.reimbursementInfo?.isReimbursement
          ? "true"
          : "false",
        "Reimbursement Amount": `₹ ${data?.reimbursementInfo?.amount  ? formatAmountIndianRupees(data?.reimbursementInfo?.amount) : Number(0).toFixed(2)}`,
        "Reimbursement Source": data?.reimbursementInfo?.source,
        "Reimbursement Invoice Number": data?.reimbursementInfo?.invoiceNum,
        "Reimbursement Invoice Date":
          data?.reimbursementInfo?.invoiceDate &&
          moment(data?.reimbursementInfo?.invoiceDate).format("DD-MMM-YYYY"),

        "TDS Applicable?": data?.tdsApplicable?.isTDS ? "true" : "false",
        "TDS Generated PO Number":
          data?.tdsApplicable?.generateTdsPO?.isTDSPO &&
          poNumberFormate(data?.tdsApplicable?.generateTdsPO?.TDSPONum),
      };
      if (data?.paymentInfo?.list?.length > 0) {
        data.paymentInfo.list.forEach((element, index) => {
          content[`Payment ${index + 1} Spent Amount`] = `₹ ${element?.amount  ? formatAmountIndianRupees(element?.amount) : Number(0).toFixed(2)}`;
          content[`Payment ${index + 1} Bank`] = element?.bank;
          content[`Payment ${index + 1} Payee`] = element?.payee;
          content[`Payment ${index + 1} Date`] = element?.paymentDate &&
            moment(element?.paymentDate).format("DD-MMM-YYYY");
  
        });
      }
     
      content["Space Added"] = "--------------";
    

      
      content["Created AT"] = data.createdAt ? moment(data.createdAt).format("DD-MMM-YYYY") : '';

      return content;
    });
    return exportData;
  };