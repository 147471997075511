import React, { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  List,
  ListItem,
  ListIcon,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons"; // Close icon
import { AiFillInfoCircle } from "react-icons/ai"; // Info icon
import { currencyFormate } from "jsFunctions/helperFunctions";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";

const SpentAmountPopover = ({ spentAmounts ,refundAmount}) => {
  return (
    <Popover placement="left" trigger="hover" closeOnBlur={true}>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton
              icon={<AiFillInfoCircle />}
              aria-label="Show spent amounts"
              size={10}
              border="none"
            ml={2}
              colorScheme="blue"
              variant="outline"
            />
          </PopoverTrigger>
          <PopoverContent width={150}>
            <PopoverHeader display="flex" justifyContent="space-between">
              <span>Spent Amounts</span>
              {/* <IconButton
                icon={<CloseIcon />}
                aria-label="Close"
                size="sm"
                onClick={onClose}
              /> */}
            </PopoverHeader>
            <PopoverBody>
              <List spacing={2}>

                {spentAmounts && spentAmounts.length ?
                spentAmounts.map((amount, index) => (
                  <ListItem key={`spent_${index}`} display="flex">
                    <ListIcon mt={.8} as={AiFillInfoCircle} color="blue.600" />
                    {formatAmountInINR(amount)} 
                  </ListItem>
                ))
                :
                <ListItem display="flex">
                <ListIcon mt={0.9} as={AiFillInfoCircle} color="blue.600" />
                {formatAmountInINR(0)}
              </ListItem>
              }
              {refundAmount && refundAmount?.length > 0 &&
                refundAmount?.map((item, index) => (
                  <ListItem key={`refund_${index}`} display="flex">
                    <ListIcon mt={.8} as={AiFillInfoCircle} color="red.600" />
                    {formatAmountInINR(-item?.amount)} 
                  </ListItem>
                ))
               
              }
              </List>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default SpentAmountPopover;
