import moment from "moment";

export const poNumberFormate = (poNumberObj) => {
  if (poNumberObj) {
    return `${poNumberObj?.prefix}${
      poNumberObj?.month > 9
        ? poNumberObj?.month
        : "0" + parseInt(poNumberObj?.month)
    }${`${poNumberObj?.year}`?.slice(2, 4)}-${
      poNumberObj?.number > 9 ? poNumberObj?.number : "0" + poNumberObj?.number
    }`;
  } else {
    return "";
  }
};

export const checkApprovalAmount = (approvalAmount) => {
  let isValid = { status: false, msg: "" };
   
    if (!approvalAmount) {
      isValid.status = false;
      isValid.msg = "Requested amount is required";
    } else {
      isValid.status = true;
      isValid.msg = "";
    }
  

  return isValid;
};

export const poMonthFormate = (orderMonth)=>{
  
  if (orderMonth) {
    const date = moment(orderMonth);

    const month = date.format("MMM");
    const year = date.format("YYYY");
    return `${year}-${month}`;
  } else {
    return "";
  }
 
  
}
export const validateGSTIN = (gstin) => {
  let isValid = { status: false, msg: "" };
  const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9A-Z]{4}[A-Z][A-Z0-9][Z]{1}[A-Z0-9]{1}$/;

  if (gstin.length !== 15) {
    isValid.status = false;
    isValid.msg = "GST number must be 15 characters long. ex: 06BZAHM6385P6Z2";
  } else if (!gstinRegex.test(gstin)) {
    isValid.status = false;
    isValid.msg = "GSTIN number is not in the correct format. ex: 06BZAHM6385P6Z2 (State Code + PAN + Entity Code + Checksum)";
  }  else {
    isValid.status = true;
    isValid.msg = "";
  }
  
  return isValid;
}
export const checkSpentAmountisEmpty = (list)=>{
  let isValid = { status: true, msg: "" };

  if (!list || list.length === 0) {
    isValid.status = true;
    isValid.msg = "";
  }

  for (const item of list) {
    if (!item.amount || item.amount === 0) {
      isValid.status = false;
      isValid.msg = "Spent amount should not be empty!";
    }
  }

  return isValid;
  
}
export const checkTotalAmountWithPayment = (approvalAmount, listOfPayment) => {
  let isValid = { status: false, msg: "" };
  if (approvalAmount && listOfPayment.length > 0) {
    let totalAmountData = parseFloat(approvalAmount);
    // let calculateTotalAmount = 0;
    const calculateTotalAmount = listOfPayment.reduce((acc, current) => {
      if (current && current?.amount && current?.amount !== '') {
        return acc + parseInt(current.amount);
      } else {
        return acc;
      }
    }, 0);
    // listOfPayment.forEach((element) => {
    //   calculateTotalAmount += parseFloat(element.amount);
    // });
    if (totalAmountData >= calculateTotalAmount) {
      isValid.status = true;
    } else {
      isValid.status = false;
      isValid.msg = "Payment Amount must be less then approved amount!";
    }
  } else {
    if (!approvalAmount) {
      isValid.status = false;
      isValid.msg = "Requested amount is required";
    } else {
      isValid.status = true;
      isValid.msg = "";
    }
  }

  return isValid;
};

export const checkTotalApprovedAmountWithSpentAmount = (approvedAmount, listOfPayment) => {
  let isValid = { status: false, msg: "" };
  if (approvedAmount && listOfPayment.length > 0) {
    let totalAmountData = parseFloat(approvedAmount);
    // let calculateTotalAmount = 0;
    const calculateTotalAmount = listOfPayment.reduce((acc, current) => {
      if (current && current?.amount && current?.amount !== '') {
        return acc + parseInt(current.amount);
      } else {
        return acc;
      }
    }, 0);
    
    if (totalAmountData >= calculateTotalAmount) {
      isValid.status = true;
    } else {
      isValid.status = false;
      isValid.msg = "Please increased requested amount and resubmit for approval.";
    }
  } 
  else{
    isValid.status = true;
    isValid.msg = "";

  }

  return isValid;
};
export const currencyFormate = (number) => {
  if (number) {
    // number = Number(number)?.toFixed(2)
    const formattedNumber =  number.toLocaleString("en-IN", {
      style: "decimal",
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const html = `<span style="font-family: sans-serif" ">₹ </span> ${(formattedNumber)}`;

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  } else {
    return "-";
  }
};

export const getStartDate = (date) => {
  return moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z";
};

export const getEndDate = (date) => {
  return moment(date).format("YYYY-MM-DDT23:59:59.000") + "Z";
};

export const getDifferenceFromPrevVal = (arr) => {
  var results = [];
  for (var i = 0; i <= arr.length - 1; i++) {
    if (i === 0) {
      results.push(arr[i]);
    } else {
      results.push(arr[i] - arr[i - 1]);
    }
  }
  return results;
};
