/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { BsDoorOpen, BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { AiFillDelete, AiFillEye } from "react-icons/ai";

import { Box, Button, Text, Tooltip, useColorModeValue,Flex,
  
  Menu,
  
  MenuButton,
  
  MenuList, } from "@chakra-ui/react";
import AddNew from "components/allForm/addNew";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import {
  columnsDataColumns,
  pendingDataColumns,
  rejectedDataColumns,
} from "./embrillTable/variables/colData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetExternalExpense } from "redux/externalSlice";
import { fetchAsyncDeleteExternalExpense } from "redux/externalSlice";
import PdfListModel from "components/models/PdfListModel";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import Swal from "sweetalert2";
import { fetchAsyncDeleteExternalPdf } from "redux/externalSlice";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { currencyFormate } from "jsFunctions/helperFunctions";

import { FcApproval } from "react-icons/fc";
import { MdModeEdit, MdOutlinePendingActions } from "react-icons/md";
import { PiGraph } from "react-icons/pi";
import ExpenseReports from "./expenseGraph";
import ExternalInfoModel from "components/models/ExternalInfoModel";
import { RxCrossCircled } from "react-icons/rx";
import ExportDataModel from "components/exportData/ExportDataModel";
import { fetchAsyncDeleteExpense } from "redux/userSlice";
import { useLocation } from "react-router-dom";
import { performSearch } from "components/navbar/searchUtils";
import { fetchAsyncGetClient } from "redux/clientProjectSlice";
import SpentAmountPopover from "components/models/spentAmountPopover";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import { FaEllipsisV,FaPlus } from "react-icons/fa";
import { CiExport, CiViewTable } from "react-icons/ci";
import { TbFileExport } from "react-icons/tb";

const EmbrillExternal = () => {
  const [addNewModal, setAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [externalEditData, setExternalEditData] = useState(null);

  const [externalListData, setExternalListData] = useState([]);
  const [externalPdfList, setExternalPdfList] = useState(null);
  const [showPdfList, setShowPdfList] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [activeDataList, setActiveDataList] = useState("approved");
  const [invoiceData, setInvoiceData] = useState([]);
  const [activeGraph, setActiveGraph] = useState(false);
  const [openExternalInfoModel, setOpenExternalInfoModel] = useState(false);
  const dateRange = useSelector((state) => state.dateRange); // Access the dateRange slice
  const { startDate, endDate } = dateRange;
  const searchValue = useSelector((state) => state.searchValue); // Access the dateRange slice
  const {searchText} = searchValue;
  const location = useLocation();
  const externalList = useSelector(
    (state) => state.externalData.externalExpense
  );
  const externalData = useSelector(
    (state) => state.externalData
  );
  const currUser = useSelector((state) => state.auth.currUser);

  const [role, setRole] = useState("");

  useEffect(() => {
    if (currUser && currUser?.auth) {
      const auth = currUser?.auth;
      const roleObj =
        auth &&
        auth?.length > 0 &&
        auth.find((item) => item?.module === "External-Client/Production");
      if (roleObj) {
        setRole(roleObj?.access);
      }
    }
  }, [currUser]);

  const dispatch = useDispatch();
  const handleModalOpen = () => {
    setAddNewModal(true);
  };
  const handleModalClose = () => {
    setAddNewModal(false);
    setOpenEditModal(false);
    setExternalEditData(null);
    handlerRefreshData();
  };

  useEffect(() => {
    dispatch(fetchAsyncGetExternalExpense());
    dispatch(fetchAsyncGetClient())

  }, []);

  const deleteFileFromList = (sendData) => {
    dispatch(fetchAsyncDeleteExternalPdf(sendData)).then((response) => {
      handlerRefreshData();
    });
  };

  const getMerchantName = (selectedItem) => {
    if (selectedItem?.merchantId) {
        return selectedItem.merchantId.companyName || "";
    }
    return selectedItem?.merchantName || "";
};

  const getAllExternalData = (listData) => {
    if (listData && listData.length > 0) {
      const tableData = listData.map((data, index) => {
        let foundInvoiceWithLength = false;
        const array = data?.paymentInfo?.list;
        const spentAmounts = array.map(item => parseFloat(item?.amount || 0)); 
        const totalSpent = array.reduce((acc, item) => acc + (parseFloat(item?.amount) || 0), 0);
        
        const refundAmount = data?.refundInfo?.list
        const totalRefund = refundAmount.reduce((acc, item) => acc + (parseFloat(item?.amount) || 0), 0);

        for (let i = 0; i < array.length; i++) {
          const item = array[i];
          
          if (item && item.invoice && item.invoice.length > 0) {
            foundInvoiceWithLength = true;
            break;
          }
        }
        return {
          _id: data._id,
          no: index + 1,
          poNo: (
            <span 
              style={{ cursor: "pointer",color:'blue'}}
            >
              {data.poNo && poNumberFormate(data.poNo)}
            </span>
          ),
          purchasedate: dateTimeFormat(data.orderDate),
          majorType: (
            <span style={{ cursor: "pointer" }}>{data.expense?.majorType}</span>
          ),
          minorType: (
            <span style={{ cursor: "pointer" }}>{data.minorType}</span>
          ),
         
          merchantName: (
            <span 
              style={{ cursor: "pointer" }}
            >
              {getMerchantName(data)}
            </span>
          ),
          spentAmount: 
            <Box display="flex" >
          <span
              style={{ cursor: "pointer" }}
              className={ parseFloat(totalSpent) - parseFloat(totalRefund) ? 'green' :''}

            >
              {formatAmountInINR(totalSpent ? parseFloat(totalSpent) - parseFloat(totalRefund || 0) : 0)}
            </span>
          
          <SpentAmountPopover spentAmounts={spentAmounts} refundAmount={refundAmount} />
             </Box>
          ,

          approvalAmount: (
            <span
              style={{ cursor: "pointer" }}
              className={
                !data.paymentInfo?.totalPayAmountIsApproved ? "red" : "green"
              }
            >
              {data.paymentInfo?.approvalAmount ? (
                currencyFormate(data.paymentInfo?.approvalAmount)
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<span style="font-family: sans-serif">₹</span> ${Number(
                      0
                    ).toFixed(2)}`,
                  }}
                />
              )}
            </span>
          ),
          approvedAmount: (
            <span style={{ cursor: "pointer" }}>
              {data.paymentInfo?.totalPayAmount ? (
                currencyFormate(data.paymentInfo?.totalPayAmount)
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<span style="font-family: sans-serif">₹</span> ${Number(
                      0
                    ).toFixed(2)}`,
                  }}
                />
              )}
            </span>
          ),
          invoice: foundInvoiceWithLength ? (
            <BsFillFileEarmarkPdfFill
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setShowPdfList(true);
                setExternalPdfList(data);
              }}
            />
          ) : (
            <BsFillFileEarmarkPdfFill
              style={{ cursor: "not-allowed" }}
              color="gray"
            />
          ),
          status: "Rejected",
          action: (
            <div className="d-flex">
              <MdModeEdit
                className="me-2"
                color="blue"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleRowClick(e,data._id)
                }}
              />
              <AiFillEye
                className="me-2"
                color="#000D55"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedItem(data);
                  setOpenExternalInfoModel(true);
                }}
              />
              {role === "Admin" ? (
                <>
                  <AiFillDelete
                    style={{ cursor: "pointer" }}
                    size={16}

                    onClick={(e) => {
                      e.preventDefault();
                      Swal.fire({
                        title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${
                          data.poNo && poNumberFormate(data.poNo)
                        }</span> ?`,
                        text: "This action cannot be undone.",
                        icon: "warning",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Delete",
                        showCancelButton: true,
                        customClass: {
                          container: "sweet_containerImportant",
                          confirmButton: "sweet_confirmbuttonImportant",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteExternalData(data);
                        }
                      });
                    }}
                  />
                  <AiFillDelete
                    style={{
                      cursor: "pointer",
                      color: "red",
                      marginLeft: "3px",
                    }}
                    size={16}

                    onClick={(e) => {
                      e.preventDefault();
                      Swal.fire({
                        title: `Are you sure you want to delete expense <span style="color: #d33">PoNo : ${
                          data.poNo && poNumberFormate(data.poNo)
                        }</span> ?`,
                        text: "This Expense Data cannot be undone.",
                        icon: "warning",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Delete",
                        showCancelButton: true,
                        customClass: {
                          container: "sweet_containerImportant",
                          confirmButton: "sweet_confirmbuttonImportant",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          hardDeleteExternalData(data);
                        }
                      });
                    }}
                  />
                </>
              ) : null}
            </div>
          ),
        };
      });
      setExternalListData(tableData);
      if (externalPdfList) {
        const dataGet = externalList.filter(
          (item) => item._id === externalPdfList._id
        );
        setExternalPdfList(dataGet[0]);
      }
      if (externalEditData) {
        const dataGet = externalList.filter(
          (item) => item._id === externalEditData._id
        );
        setExternalEditData(dataGet[0]);
      }
    } else {
      setExternalListData([]);
    }
  };

  const handleExternalListData = () => {
    if (!externalList || externalList.length === 0) {
      setExternalListData([]);
      return;
    }

    const listData = externalList.filter((item) => {
      if (activeDataList === "approved") {
        return item.paymentInfo.totalPayAmountIsApproved === true;
      }
      if (activeDataList === "pending") {
        return (
          item.paymentInfo.totalPayAmountIsApproved !== true &&
          item.paymentInfo.totalPayAmountIsRejected === false
        );
      }
      return (
        item.paymentInfo.totalPayAmountIsApproved !== true &&
        item.paymentInfo.totalPayAmountIsRejected === true
      );
    });

    getAllExternalData(listData);
  };

  useEffect(() => {
    handleExternalListData();
  }, [externalList, activeDataList]);

  useEffect(() => {
    if (externalPdfList) {
      const dataGet = externalList.filter(
        (item) => item._id === externalPdfList._id
      );
      const data = dataGet[0];
      const paymentList = data?.paymentInfo?.list;
      let invoices = [];
      if (paymentList?.length > 0) {
        invoices = paymentList?.flatMap((item, index) => {
          if (item?.invoice) {
            return item.invoice.map((invoice) => ({
              ...invoice,
              listIndex: index,
            }));
          }
          return []; // or return an empty array to avoid undefined values
        });
      }

      setInvoiceData(invoices);
      setExternalPdfList(dataGet[0]);
    }
  }, [externalPdfList]);

  const deleteExternalData = (data) => {
    dispatch(fetchAsyncDeleteExternalExpense(data._id)).then(() => {
      handlerRefreshData();
    });
  };

  const hardDeleteExternalData = (data) => {
    const objData = {
      id: data._id,
      type: "external",
    };
    dispatch(fetchAsyncDeleteExpense(objData)).then(() => {
      handlerRefreshData();
    });
  };

  const handlerRefreshData = useCallback(() => {
    performSearch(dispatch, location, searchText, startDate, endDate);
  }, [location, dispatch, searchText,startDate, endDate]);

  const handleGraph = () => {
    setActiveGraph(true);
  };
  const handleTable = () => {
    setActiveGraph(false);
  };

  const handleRowClick = (e, _id) => {
    e.preventDefault();
    const data = externalList.filter((item) => item?._id === _id);
  
    setOpenEditModal(true);
    setExternalEditData(data[0]);
    handleModalOpen();
  };

  const closeExternalInfoModel = () => {
    setOpenExternalInfoModel(false);
    setSelectedItem({});
  };

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  let menuBg = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const inputBg = useColorModeValue('white', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const textColor = useColorModeValue("secondaryGray.900", "white");
  
  const [isOpen, setIsOpen] = useState(false);
  const [activeBtn,setActiveBtn] = useState("")

  const toggleMenu = (btn) => {
    setIsOpen(!isOpen);
    setActiveBtn(btn)
  };
  const handleClose = () => {   
    setActiveBtn("");   
    setIsOpen(false)
  };

  return (
    <>
       <Box display="flex" className="mx-0" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" className="tab-btn mx-2">
        {!activeGraph &&
          <Box className="tab-btn-div">
            <div
              className={`div-btn ${
                activeDataList === "approved" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("approved")}
            >
              Approved
            </div>
            <div
              className={`div-btn ${
                activeDataList === "pending" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("pending")}
            >
              Pending
            </div>
            <div
              className={`div-btn ${
                activeDataList === "rejected" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("rejected")}
            >
              Rejected
            </div>
          </Box>
        }
        </Box>

        <div 
        className="graph_button internal-graph-btn mx-2"        
       >
        <button title="Data View" className={`graph_icon_button ${!activeGraph ? 'active_graph' : ''}`}  onClick={()=>handleTable()}><CiViewTable  /></button>
        {role !== 'Accountant' ?
        <button  title="Analytics View" className={`graph_icon_button ${activeGraph ? 'active_graph' : ''}`} onClick={()=>handleGraph()}><PiGraph/></button>
        :
        <button title="Analytics View"  className={`graph_icon_button ${activeGraph ? 'active_graph' : ''}`} style={{opacity:'0.3',cursor:'not-allowed'}}><PiGraph/></button>
        }
        
       </div>
       {!activeGraph && 
        <Box display="flex" mt={2} className="add-new-btn">
          <Box
            display="flex"
            alignItems="center"
            className="new-btn"
            onClick={handleModalOpen}
          >
            <FaPlus size={12} className="new-plus-icon"/> New
          </Box>
          <Box>
          <Menu>
          <MenuButton p="0px">
          <Box  className='menu-ellipsis-btn'><FaEllipsisV /></Box>

          </MenuButton>
          <MenuList
             boxShadow={shadow}
             p="0px"
             mt="10px"
             borderRadius="10px"
             bg={menuBg}
             border="none"
          >
            <Flex w="100%" mb="0px" flexDirection="column">
            <Box
                ps="20px"
                pt="10px"
                pb="8px"
                className="menu-text"
                borderBottom="1px solid gray"
                borderColor={borderColor}
                onClick={()=>toggleMenu('xlsx')}
                display="flex"
               
              >
                <CiExport size={20} className="mx-1"/> <span>Export XLSX</span>
                
              </Box>
             
            </Flex>
            <Flex w="100%" mb="0px" flexDirection="column">
            <Box
                ps="20px"
                pb="8px"
                pt="5px"
                borderBottom="1px solid gray"
                borderColor={borderColor}
                display="flex"
                className="menu-text"
                onClick={()=>toggleMenu('invoice')}


              >
                <TbFileExport size={20} className="mx-1"/> 
                <span>Export Invoice</span>
                
              </Box>
            </Flex>
           <Box marginRight={40}>
           {isOpen && (
            <ExportDataModel
              filename={"External_"}
              type="external"
              role={role}
              isOpen={isOpen}
              handleClose={handleClose}
              activeBtn={activeBtn}
            />
          )}
           </Box>
          </MenuList>
        </Menu>
          </Box>
        
         
        </Box>
      }
      </Box>
     
      <Box pt={{ base: "40px", md: "10px", xl: "10px" }}>

        {activeGraph ? (
          <Box className={`graph-card ${activeGraph ? "graph_active" : ""}`}>
            <ExpenseReports
              type="external"
              name="External Total Expense"
              heading="External"
              icon={BsDoorOpen}
              role={role}
            />
          </Box>
        ) : (
          <ColumnTable
            columnsData={
              activeDataList === "rejected"
                ? rejectedDataColumns
                : activeDataList === "pending"
                ? pendingDataColumns
                : columnsDataColumns
            }
            tableData={externalListData}
            openInfoModal={handleRowClick}
            expenseType="external"
            type={"External – Client / Production"}
            activeDataList={activeDataList}
            role={role}
            paymentData = {externalData}

          />
        )}
      </Box>
      {addNewModal && (
        <AddNew
          openExternalModal={addNewModal}
          CloseExternalModal={handleModalClose}
          isEditForm={openEditModal}
          editItem={externalEditData}
          refreshEditData={handlerRefreshData}
          activeList={activeDataList}
          role={role}
        />
      )}

      {showPdfList && (
        <PdfListModel
          pdfData={externalPdfList ? invoiceData : []}
          currentId={externalPdfList ? externalPdfList._id : ""}
          openModal={showPdfList}
          closeModal={() => {
            setShowPdfList(false);
            setExternalPdfList(null);
          }}
          deleteFileFromList={deleteFileFromList}
          role={role}
        />
      )}
      {openExternalInfoModel && (
        <ExternalInfoModel
          openInfoModal={openExternalInfoModel}
          closeInfoModal={closeExternalInfoModel}
          selectedData={selectedItem}
          role={role}
        />
      )}
    </>
  );
};

export default EmbrillExternal;
