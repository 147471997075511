import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
} from "@chakra-ui/react";
import { Col, Form, Row } from 'react-bootstrap';
import ExcelCustomSelect from '../EmbrillProfile/excelSelectedFiled';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import AddColumnPopover from './addColuman';


const excelSettingfieldOptions = [
  {label:'_Blank',value:''},

  {label:'NA',value:'NA'},
  {label:'Embrill Account Number',value:'Embrill Account Number'},

  {label:'Embrill IFSC Code',value:'Embrill IFSC Code'},
  {label:'Embrill Bank Name',value:'Embrill Bank Name'},
  {label:'Embrill Branch Name',value:'Embrill Branch Name'},
  {label:'Embrill Contact Person',value:'Embrill Contact Person'},
  {label:'Embrill Email',value:'Embrill Email'},
  {label:'Embrill Contact Number',value:'Embrill Contact Number'},
  
  {label:'Payment Product Type Code',value:'Payment Product Type Code'},
  {label:'Net Payable Amount',value:'Net Payable Amount'},
  {label:'Payment Mode',value:'Payment Mode'},
  {label:'Payment Date',value:'Payment Date'},
  {label:'Debit Narrative',value:'Debit Narrative'},
  {label:'Credit Narrative',value:'Credit Narrative'},
  {label:'Reference Number',value:'Reference Number'},


  {label:'Vendor Name',value:'Vendor Name'},
  {label:'Vendor Account Number',value:'Vendor Account Number'},
  {label:'Vendor Account Holder Name',value:'Vendor Account Holder Name'},

  {label:'Vendor IFSC Code',value:'Vendor IFSC Code'},
  {label:'Vendor Account Type',value:'Vendor Account Type'},
  {label:'Vendor Bank Name',value:'Vendor Bank Name'},
  {label:'Vendor Branch Name',value:'Vendor Branch Name'},
  {label:'Vendor Contact Number',value:'Vendor Contact Number'},
  {label:'Vendor Email',value:'Vendor Email'},
  {label:'Remarks',value:'Remarks'},


]


const ExcelSettingsModal = (
  { isOpen, 
    onClose,
    columnHeadings,
    columnValue,
    updateExcel,
  }) => {

  const [newColumnValue , setNewColumnValue] = useState([]);
  const [newColumnHeading , setNewColumnHeading] = useState([]);
  const [columnsToRemove, setColumnsToRemove] = useState([]);


  const [newHeading, setNewHeading] = useState('');
  const [insertPosition, setInsertPosition] = useState(0);

  const [selectOptions, setSelectOptions] = useState(excelSettingfieldOptions);

  useEffect(()=>{
const filteredOptions = excelSettingfieldOptions.filter(option => 
  !newColumnValue.includes(option.value) || option.value === 'NA' || option.value === ''
);
setSelectOptions(filteredOptions)
  },[newColumnValue])
  
  useEffect(()=>{
    if(columnValue && columnValue?.length){
      setNewColumnValue(columnValue)
    }
  },[columnValue])

  useEffect(()=>{
    if(columnHeadings && columnHeadings?.length){
      setNewColumnHeading(columnHeadings)
    }
  },[columnHeadings])

  const handleChangeValue = (index,key,item)=>{
      const updatedColumnValue = [...newColumnValue];
      if(item !== 'PYMT_PROD_TYPE_CODE'){

        updatedColumnValue[index] = key;
      }
      else{
        updatedColumnValue[index] = 'PAB_VENDOR';
      }
      setNewColumnValue(updatedColumnValue);
  }

 
 

  
  const handleUpdate = (e)=>{
    e?.preventDefault();
    updateExcel(newColumnHeading,newColumnValue,columnsToRemove)
    
  }

  const handleDelete = (index) => {
    setColumnsToRemove(prev => [...prev, index]);
    const updatedHeadings = newColumnHeading.filter((_, i) => i !== index);
    const updatedValues = newColumnValue.filter((_, i) => i !== index);
    setNewColumnHeading(updatedHeadings);
    setNewColumnValue(updatedValues);
  };


  const handleChangeHeading = (index, heading) => {
    const updatedColumnHeading = [...newColumnHeading];
    updatedColumnHeading[index] = heading;
    setNewColumnHeading(updatedColumnHeading);
  };

  const handleAddColumn = (heading, position) => {
    const updatedHeadings = [...newColumnHeading];
    const updatedValues = [...newColumnValue];

    // Insert heading and value at the desired position
    updatedHeadings.splice(position, 0, heading);
    updatedValues.splice(position, 0, ''); // Add an empty value

    setNewColumnHeading(updatedHeadings);
    setNewColumnValue(updatedValues);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
       top="0" // Position it at the top
        margin="0 auto" // Center it horizontally
        bottom="0"
        className="add-form-modal-content"
      >
      <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
             Settings
            </ModalHeader>
            <ModalCloseButton />
          </div>
        <ModalBody>
          <Form className="addForm">
            <div className="form-card">
            <Box display="flex" alignItems="center">

              <h5>Update Excel Sheet</h5>
              <AddColumnPopover onAddColumn={handleAddColumn} />
              </Box>
              {newColumnHeading && newColumnHeading?.length ?
              newColumnHeading?.map((item,index)=>{
                return(
                  <Row>
                 
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label style={{width:'215px'}} ><span>{index + 1} </span>{item} </Form.Label>
                    <Form.Control
                      type="text"
                      name={item || ""}
                      placeholder={""}
                      disabled
                      value={newColumnValue[index] || ""}
                    />
                  <Box display="flex">
                  <ExcelCustomSelect
                        options={selectOptions}
                        placeHolder={<div>Type or select...</div>}
                        isSearchable
                        onChange={(newValue) => handleChangeValue(index,newValue?.value,item)}
                        />
             
               <AiFillDelete
                 style={{ cursor: "pointer",color:'red',marginLeft:'10px' }}
                 size={16}
                className='mt-2'
                 onClick={(e) => {
                   e.preventDefault();
                   Swal.fire({
                     title: `Are you sure? you want to delete</span>.`,
                     text:'This action cannot be undone.',
                     icon: "warning",
                     confirmButtonColor: "#3085d6",
                     cancelButtonColor: "#d33",
                     confirmButtonText: "Yes, delete",
                     showCancelButton: true,
                     customClass: {
                       container: "sweet_containerImportant",
                       confirmButton: "sweet_confirmbuttonImportant",
                     },
                   }).then((result) => {
                     if (result.isConfirmed) {
                      handleDelete(index);
                     }
                   });
                 }}
               />
            
           
                 
                  </Box>
                    </Box>
                  </Col>
                  
                </Row>
                )
                
                
              })
              :
              ''
              }
            </div>
          </Form>
        </ModalBody>
        <ModalFooter style={{ alignItems: "center", justifyContent: "center" }}>
        

          <Button  variant="brand" className='submit-btn' mr={3} onClick={(e)=>handleUpdate(e)}>
            Update Changes
          </Button>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExcelSettingsModal;
