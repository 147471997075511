import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaChevronDown, FaChevronUp, FaTrash } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';

const VenderBankInfo = ({ bankDetail,companyName, index, removeBankDetail, handleChange,handleBlur,errors }) => {
    const [isOpen, setIsOpen] = useState(true);

    const accountTypeOptions = [
        { value: '', label: 'Select Account Type' },
        { value: 'Savings', label: 'Savings' },
        { value: 'Current', label: 'Current' },
        { value: 'Fixed', label: 'Fixed Deposit' },
        { value: 'Rsecurring', label: 'Recurring Deposit' },
      ];

  return (
    <div
    className={`payment-listing_box mb-4 ${isOpen ? "show" : "hide"}`}
    >
    <div className="payment-list_array mt-3">
    <div className="d-flex">
          <span className="payment-details ">Bank Details {index  + 1}</span>
          
        </div>

   
    <div className="d-flex toggleImage-content">
          <span className="toggleImage"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{cursor: "pointer"}}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/4655/4655143.png"
              alt="minus"
              style={{ width: "20px", height: "35px" }}
              className={isOpen ? "closed" : "open"}
            />
          </span>
          <span className="mt-0" >
                   <FaTrash
                     style={{
                       fontSize: "16px",
                       color: "red",
                       cursor: "pointer",
                     }}
                     onClick={() => removeBankDetail(index)}
                   />
                 </span>
        </div>
   </div>
    {isOpen && (
    <>
    <Row>
    <Col>
    <Box display="flex" className="input-box">

        <Form.Label>Account Holder Name</Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.accountHolder`}
          placeholder="Enter Account Holder Name"
          value={bankDetail.accountHolder ?bankDetail.accountHolder :companyName}
          onChange={(e)=>handleChange(e)}
          // isInvalid={!!errors[`bankDetails.${index}.accountHolder`]}
        />
    </Box>
       
      </Col>
      <Col></Col>
    </Row>
    <Row>
      <Col>
      <Box display="flex" className="input-box">

        <Form.Label>Account Number <span className="span-star">*</span></Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.accountNumber`}
          placeholder="Enter Account Number"
          value={bankDetail.accountNumber}
          onChange={(e)=>handleChange(e)}
          isInvalid={!!errors[`bankDetails.${index}.accountNumber`]}
        />
      </Box>
      <p className="form_error"> {errors[`bankDetails.${index}.accountNumber`]}</p>
      </Col>
      <Col>
      <Box display="flex" className="input-box">
        <Form.Label>IFSC Code  <span className="span-star">*</span></Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.ifscCode`}
          placeholder="Enter IFSC Code"
          value={bankDetail.ifscCode}
          onChange={(e)=>handleChange(e)}
          onBlur={() => handleBlur(index)}
          isInvalid={!!errors[`bankDetails.${index}.ifscCode`]}
        />
        </Box>
        <p className="form_error"> {errors[`bankDetails.${index}.ifscCode`]}</p>

       
      </Col>
    </Row>
    <Row>
    <Col>
    <Box display="flex" className="input-box">

          <Form.Label>Account Type</Form.Label>
          <Form.Select
            name={`bankDetails.${index}.accountType`}
            value={bankDetail.accountType}
            onChange={(e) => handleChange(e)}
            className='custom--dropdown-container'
          >
            {accountTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
    </Box>
        
        </Col>
      <Col>
      <Box display="flex" className="input-box">

        <Form.Label>Bank Name</Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.bankName`}
          placeholder="Enter Bank Name"
          value={bankDetail.bankName}
          onChange={(e)=>handleChange(e)}
        />
      </Box>
       
      </Col>
    </Row>
    <Row>
      <Col>
      <Box display="flex" className="input-box">
        <Form.Label>SWIFT Code</Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.swiftCode`}
          placeholder="Enter SWIFT Code"
          value={bankDetail.swiftCode}
          onChange={(e)=>handleChange(e)}
          onBlur={() => handleBlur(index)}
        />
        </Box>
       
      </Col>
      <Col>
      <Box display="flex" className="input-box">
        <Form.Label>Branch Name</Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.branchName`}
          placeholder="Branch Name"
          value={bankDetail.branchName}
          onChange={(e)=>handleChange(e)}
        />
        </Box>
      
      </Col>
    </Row>
    
    <Row>
      <Col>
      <Box display="flex" className="input-box">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.bankAddress.city`}
          placeholder="City"
          value={bankDetail.bankAddress.city }
          onChange={(e)=>handleChange(e)}
        />
        </Box>
      </Col>
      <Col>
      <Box display="flex" className="input-box">
        <Form.Label>State</Form.Label>
        <Form.Control
          type="text"
          name={`bankDetails.${index}.bankAddress.state`}
          placeholder="State"
          value={bankDetail?.bankAddress?.state}
          onChange={(e)=>handleChange(e)}
        />
       </Box>
      </Col>
    </Row>
    {/* <button
    className="btn btn-danger mt-3"
      type="button"
      onClick={() => removeBankDetail(index)}
    >
      Remove
    </button> */}
    </>
    )}
  </div>
  );
};

export default VenderBankInfo;