/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  Box
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import ClientProjectSelect from "components/ClientProjectSelect";

import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

import CurrencyFormat from 'react-currency-format';
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";

import { createListForSingleOption } from "./createableSelect";
import { createOption } from "./createableSelect";
import calculateTds from "jsFunctions/calculateTds";
import PaymentInfoComponent from "./paymentInfoComponent";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { fetchAsyncGetPONumTDSExpense } from "redux/tdsSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";

import { fetchAsyncAddTDSExpense } from "redux/tdsSlice";
// import { fetchAsyncGetResourceExpense } from "redux/resourceSlice";
import moment from "moment";
import { fetchAsyncDeleteTdsPdf } from "redux/tdsSlice";
import { fetchAsyncUploadTdsPdf } from "redux/tdsSlice";
import { fetchAsyncUpdateTdsExpense } from "redux/tdsSlice";
import { tdsFormValidation } from "validation";
import { fetchAsyncAddTDSInvoiceRemark } from "redux/tdsSlice";

import { checkApprovalAmount } from "jsFunctions/helperFunctions";
import { checkTotalApprovedAmountWithSpentAmount } from "jsFunctions/helperFunctions";
import { fetchAsyncRejectTdsTotalPayAmount } from "redux/tdsSlice";
import { fetchAsyncApproveTdsTotalPayAmount } from "redux/tdsSlice";
import { fetchAsyncSendRequestUpdateApprovalTdsTotalPayAmount } from "redux/tdsSlice";
import { fetchAsyncSendRequestApprovalTdsTotalPayAmount } from "redux/tdsSlice";
import { checkSpentAmountisEmpty } from "jsFunctions/helperFunctions";
import { fetchAsyncDeleteTdsPaymentListItem } from "redux/tdsSlice";
import DataChangeModalOnEdit from "components/models/DataChangeModalOnEdit";
import { InputGroup, OverlayTrigger, Popover, Tab, Tabs } from "react-bootstrap";
import { fetchAsyncGetByReferencePoDetails } from "redux/tdsSlice";
import RefundInfoListComponent from "./refundListInfoComponent";
import { fetchAsyncDeleteTdsRefundListItem } from "redux/tdsSlice";
import { fetchAsyncGetVendor } from "redux/vendorSlice";
import { clientProjectOptions } from "jsFunctions/clientProjectOptions";
import { fetchAsyncGetVendorByID } from "redux/vendorSlice";

import QuotationForm from "./seprateFormComponent/quatationForm";
import { FaCheck, FaFileUpload, FaPlusCircle } from "react-icons/fa";
import { fetchAsyncGetAllQuotation } from "redux/quotationSlice";
import { fetchAsyncGetTotalPayAmountByPanNumber } from "redux/vendorSlice";
import PanNumberField from "./seprateFormComponent/panNumberField";
import DownloadExcelFile from "./DownloadExcel/downloadExcel";

// import { Option } from "antd/es/mentions";

// modal style

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});

const TdsAddNew = ({
  tdsOpen,
  tdsClose,
  tdsExpenseData,
  isEditForm = false,
  editItem, 
  refreshEditData,
  activeList,
  role
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const formOptionsList1 = useSelector((state) => state.formOptions);
  const [isEdit, setIsEdit] = useState(isEditForm)
  const [editData, setEditData] = useState(editItem)
  const [poPrefix, setPoPrefix] = useState({
    prefix: "PR-T-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));

  const [generatedNum, setGeneratedNum] = useState("PR-T-");
  const [isTds, setIsTds] = useState(false);
 
  const [formOptionsList, setFormOptionsList] = useState([])

  const [amountTotal, setAmountTotal] = useState("");
  const [tdsAmount, setTdsAmount] = useState("");
  const [resourceOption, setResourceOption] = useState([]);
  const [editTDSAmount, setEditTDSAmount] = useState(true);
  const [tdsPercent, setTdsPercent] = useState(10);

  const [editPercentNum, setEditPercentNum] = useState(true);
  const [setResourcePoNo, setSetResourcePoNo] = useState("");
  const [isResourcePoChange, setIsResourcePoChange] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [multipleInvoiceData, setMultipleInvoiceData] = useState([]);
  const [multipleAddPaymentInfo, setMultipleAddPaymentInfo] = useState([""]);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [approvalAmount, setApprovalAmount] = useState(null);
  const [remarksValue, setRemarksValue] = useState('');
  const [errors, setErrors] = useState({})

  const [comparingData, setComparingData] = useState(null);
  const [approveOrRejectRemarks, setApproveOrRejectRemarks] = useState('')

  const [sendPayAmountRequest, setSendPayAmountRequest] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState()
  const [poNoResource, setPoNoResource] = useState([]);
  const [resourceValue, setResourceValue] = useState("");
  const [compareDataModal, setCompareDataModal] = useState(false);

  const [totalSpentAmount, setTotalSpentAmount] = useState(0)
  const [totalRefundAmount, setTotalRefundAmount] = useState(0)
  const [totalPayAmountByPanNumber, setTotalAmountByPanNumber] = useState({})
  const [totalPayAmountIsApproved, setTotalPayAmountIsApproved] = useState(false);
  const currUser = useSelector((state) => state.auth.currUser);
  const [multipleAddRefundInfo, setMultipleAddRefundInfo] = useState([""]);
  const vendorList = useSelector((state) => state.vendorData);
  const [openQuotation, setOpenQuotation] = useState(false)
  const [oldResourceName, setOldResourceName] = useState("")

  const [panNumber, setPanNumber] = useState("");
  const [quotationData, setQuotationData] = useState({
    remarks:"",
    amount:"",
    files:""
  })

  const [allQuotation,setAllQuotation] = useState([])

  const resetAllStateOnCloseModal = () => {
    setTdsPercent(10);

    setIsTds(false);
    setEditTDSAmount(true);
    setEditPercentNum(true);
  };
  useEffect(
    () => {
      if( amountTotal && tdsPercent ){

        setTdsAmount(calculateTds(amountTotal, tdsPercent));
      }
      else{
        setTdsAmount(0)
      }
    },
    [amountTotal],
    [tdsPercent]
  );


  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === 'tds')
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

  useEffect(() => {
    dispatch(fetchAsyncResourcePONum()).then((res) => {
      if (res.payload) {
        const resourcePoNo = [];
        res.payload.forEach((item, index) => {
          resourcePoNo.push({
            value: item._id,
            label: poNumberFormate(item.poNo),
          });
        });
        setPoNoResource(resourcePoNo);
      }
    });
    dispatch(fetchAsyncGetVendor());

  }, []);

  const closeComparedDataModal = () => {
    setCompareDataModal(false);

    setComparingData(null);
  };
  const submitUpdateData = (formData, editData) => {
    dispatch(
      fetchAsyncUpdateTdsExpense({
        id: editData._id,
        data: formData,
      })
    )
      .then((res) => {
        if (res.payload) {
          addToast(`Expense ${generatedNum} updated successfully!`, { appearance: "success" });
      }
        tdsClose();
        setComparingData(null);
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        addToast("Error", { appearance: "error" });
      });
  };
  const initialValues = {
    poNo: "",
    orderMonth: "",
    orderDate: "",
    resourceName: "",
    remarks: "",

    payee: 0,
    paymentAmount: 0,
    bank: "",
    referance: "",
    date: null,
   
   
    approvalAmount: 0,
    isTDS: false,
    totalAmount: 0,
    tdsPercent: 0,
    totalTDS: 0,

    isTDSPO: false,
    TDSPONum: "",
    source: "",
    invoiceNum: "",
    invoiceDate: null,
    reimbursmentAmount: 0,
    isRefund:
    editData?.refundInfo?.isRefund|| false,
    invoice: "",
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: tdsFormValidation,
    onSubmit: (values, { resetForm }) => {
      if (!resourceValue) {
        setErrors({
          ...errors,
          resourceValue:'Resource name is required!'
        })
        return;
      }
     
     
      const checkApproval = checkApprovalAmount(
        approvalAmount,
      );
      if (!checkApproval.status) {
        setErrors({
          ...errors,
          approvalAmount:checkApproval.msg
        }) 
        return;
      }
      const filteredMultipleAddPaymentInfo = multipleAddPaymentInfo.filter((item) => item?.amount);

      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        filteredMultipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        return;
      }
      let formData = new FormData();
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
      formData.append("resourceId", resourceValue.value || "");

      formData.append("panNumber", panNumber);
      formData.append("remarks", remarksValue);
      formData.append("refResourcePONum", setResourcePoNo?.value);
      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);

      const filteredMultipleAddPaymentInfoPay = filteredMultipleAddPaymentInfo.map((item)=>{
        if(item?.invoice){
          return {
            ...item,
            invoice: item?.invoice.filter(entry => 'Key' in entry)
          }
        }
        else{
          return item
        }
      });
      formData.append("payInfo", JSON.stringify(filteredMultipleAddPaymentInfoPay));
      formData.append("isTDS", isTds);

      formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      formData.append("tdsAmount", tdsAmount);
      formData.append("invoiceNum", values.invoiceNum);
      formData.append("isRefund", values.isRefund);
      const filteredMultipleAddRefundInfoPay = multipleAddRefundInfo.filter((item) => item?.amount);

      formData.append("refundInfo", JSON.stringify(filteredMultipleAddRefundInfoPay));


      if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
        formData.append('quotation_remarks', quotationData?.remarks);
       formData.append('quotation_amount', quotationData?.amount);
       Array.from(quotationData?.files).forEach((file) => {
           formData.append('quotation_files', file);
       });

     }
      if (isEdit) {
        setComparingData(formData);
      } else {
        multipleInvoiceData.forEach((element, i) => {
          formData.append(`file${i}`, element);
        });
        dispatch(fetchAsyncAddTDSExpense(formData))
          .then((res) => {
            if (res?.payload) {
              tdsClose();
              resetForm();
              addToast("Successfully added", { appearance: "success" });
              
            } 
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
  });
  const handleEditTDSPercentange = () => {
    setEditPercentNum(false);
  };
  const handleEditSubmit = () => {
    setEditTDSAmount(true);
  };
  const handleEditTDS = () => {
    setEditTDSAmount(false);
  };
 

  // SET PO NUMBER

  useEffect(() => {
    setGeneratedNum(poNumberFormate(poPrefix));
  }, [poPrefix]);

  const handelDeleteInvoice = (index,id) => {
    // const updateList = multipleInvoiceData.filter((item, i) => i !== index);
    // setMultipleInvoiceData([...updateList]);

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === id) {

        let invoice = []
        if(item?.invoice){

          invoice = item?.invoice.filter((item, i) => i !== index)
        }
        
        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  useEffect(() => {
    if (!isEdit && getPoMonth) {
      const dataSplit = getPoMonth.split("-");
      dispatch(
        fetchAsyncGetPONumTDSExpense({
          month: dataSplit[1],
          year: dataSplit[0],
        })
      )
        .then((res) => {
          if (res.payload?.[0]?.poNo) {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: res.payload?.[0]?.poNo.number + 1,
              };
            });
          } else {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: 1,
              };
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getPoMonth]);

  const finalRef = useRef(null);

  // create option
  const handleInvoiceChange = (data) => {
    // setOpenInvoice(true);
    // if (multipleInvoiceData.length < 10) {
    //   setMultipleInvoiceData([
    //     ...multipleInvoiceData,
    //     { data: "", invNumber: data.data },
    //   ]);
    // }

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === data?.id) {

        let invoice = []

        if(item?.invoice){
           if (item?.invoice?.length < 5) {
          invoice = [
            ...item?.invoice,
            { data: "", invNumber: data.data }
          ]
        }
        else{
          invoice = item?.invoice
        }
        }
        else{
          invoice = [
            { data: "", invNumber: data.data }
          ]
        }

        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  useEffect(() => {
    setTdsAmount(calculateTds(amountTotal, tdsPercent));
  }, [amountTotal, tdsPercent]);

  const handleUpdateInvoiceData = (invoiceData, index) => {
    const updateList = multipleInvoiceData.map((item, i) => {
      if (i === index) {
        return invoiceData;
      } else return item;
    });
    setMultipleInvoiceData([...updateList]);
  };

  // payment info function
  const handleMultiplePayInfo = (data) => {
    setMultipleAddPaymentInfo([
      ...multipleAddPaymentInfo,
      { data: "", paydata: data.data,invoice:[] },
    ]);
  };
  const handleUpdateMultiplePayData = (payData, index) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };

  
  const handleDeleteInvoiceOnEdit = (key,listIndex) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          key: key,
          listIndex:listIndex

        };
        dispatch(fetchAsyncDeleteTdsPdf(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)

          refreshEditData();
        });
      }
    });
  };
  // form list option

  const getAllCompanyOptions = async()=>{
    // const filteredVendors = vendorList.filter(vendor => vendor.isConsultant === true);

      const companyOption = await clientProjectOptions('companyName','_id',vendorList) 
      setResourceOption(companyOption);
  }

  useEffect(()=>{
    if(vendorList){
      getAllCompanyOptions()
    }
  },[vendorList])


const getTotalPayAmount = async(panNo)=>{
  await dispatch(fetchAsyncGetTotalPayAmountByPanNumber(panNo)).then((res)=>{

    if(res?.payload){
    setTotalAmountByPanNumber(res?.payload)
      
    }
    }).catch((err)=>{
      setTotalAmountByPanNumber({})

      console.log(err)
    })
}
useEffect(()=>{
  if(panNumber){
    getTotalPayAmount(panNumber)
  }
},[panNumber])
const isValidObjectId = (id) => {
    return /^[a-f0-9]{24}$/.test(id);
};

  useEffect(() => {
    
    if(resourceValue && resourceValue?.value){
      const id = resourceValue?.value;
      if(isValidObjectId(id)){

        dispatch(fetchAsyncGetVendorByID(id)).then((res)=>{
  
          if(res?.payload){
            const {panNo} = res?.payload?.vendor;  
            setPanNumber(panNo)
          }
          }).catch((err)=>{
            console.log(err)
          })
      }
      }
  }, [resourceValue]);



  const addNewInvoiceOnEdit = (file, index,listIndex) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (editData) {
          const lastInvoiceKey =
            editData.invoice?.length > 0 &&
            editData.invoice[editData.invoice.length - 1].Key;
          let number = index + 1;
          if (lastInvoiceKey) {
            number = lastInvoiceKey.split("PAY_")[1]?.split(".pdf")[0];
            number = parseInt(number) + 1;
          }
          let formData = new FormData();
          const getPoNoById = (id) => {
            const result = poNoResource.find(item => item._id === id);
            return result ? result.poNo : undefined;
          };

          const referenceResourcePO = getPoNoById('');
  
          formData.append("poNo", JSON.stringify(editData.poNo));
          formData.append("number", number);
          formData.append("file", file);
          formData.append("listIndex", listIndex);
          formData.append("index", index);
          formData.append("referenceResourcePO", JSON.stringify(referenceResourcePO || ''));

          const listItem = multipleAddPaymentInfo[listIndex];
          formData.append("listItem", JSON.stringify(listItem));
          dispatch(fetchAsyncUploadTdsPdf({ id: editData._id, formData })).then(
            (res) => {
              setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)

              refreshEditData();
            }
          );
        }
      }
    });
  };
  const getCompanyByID = async(id)=>{
    await dispatch(fetchAsyncGetVendorByID(id)).then((res)=>{

    if(res?.payload){
      const vendor = res?.payload?.vendor;
      const setData = {
        label: vendor.companyName,
        value: vendor?._id,
      };
            
      setResourceValue(setData);
      setPanNumber(vendor?.panNo)
    }
    }).catch((err)=>{
      console.log(err)
    })
  }

  const getReferencePoDetails = async(id)=>{
   
    dispatch(fetchAsyncGetByReferencePoDetails(id)).then((res)=>{
      if(res?.payload){
        const referenceExpense = res?.payload?.referenceExpense;
        if (res?.payload && referenceExpense) {
          if(referenceExpense?.resourceId){
            getCompanyByID(referenceExpense?.resourceId)
          }
          else if(referenceExpense?.resourceName){
            const setData = {
              label: referenceExpense?.resourceName,
              value: referenceExpense?.resourceName,
            };
                  
            // setResourceValue(setData);
          }
          if(isResourcePoChange){

            if(referenceExpense?.tdsApplicable && referenceExpense?.tdsApplicable?.isTDS){
              const tdsData = referenceExpense?.tdsApplicable;
              setAmountTotal(tdsData?.totalAmount)
              setTdsPercent(tdsData?.tdsPercent)
              setTdsAmount(tdsData?.totalTDS)
              if(!editData?.paymentInfo?.approvalAmount){
                setApprovalAmount(tdsData?.totalTDS)
              }
            }
            else if(referenceExpense?.paymentInfo?.approvalAmount){
              setAmountTotal(referenceExpense?.paymentInfo?.approvalAmount)
              if(!editData?.paymentInfo?.approvalAmount){
                setApprovalAmount(referenceExpense?.paymentInfo?.approvalAmount)
              }
            }
          }
          else{
            if(referenceExpense?.tdsApplicable && referenceExpense?.tdsApplicable?.isTDS){
              const tdsData = referenceExpense?.tdsApplicable;
              setAmountTotal(editData?.totalBillingAmount ? editData?.totalBillingAmount : tdsData?.totalAmount)
              setTdsPercent(editData?.percent ?editData?.percent :tdsData?.tdsPercent)
              setTdsAmount(editData?.tdsAmount ? editData?.tdsAmount :tdsData?.totalTDS)
              if(!editData?.paymentInfo?.approvalAmount){
                setApprovalAmount(tdsData?.totalTDS)
              }
            }
            else if(referenceExpense?.paymentInfo?.approvalAmount){
              setAmountTotal(editData?.totalBillingAmount ? editData?.totalBillingAmount  :referenceExpense?.paymentInfo?.approvalAmount)
              if(!editData?.paymentInfo?.approvalAmount){
                setApprovalAmount(referenceExpense?.paymentInfo?.approvalAmount)
              }
            }
          }
        }
        }
      }
    ).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    if(setResourcePoNo && setResourcePoNo?.value){
      getReferencePoDetails(setResourcePoNo?.value);
    }
  },[setResourcePoNo])
  // Changes on Edit
 
  const handleQuotationData = (data)=>{
    setQuotationData(data)
  }

  const handleCloseQuotation = ()=>{
    setOpenQuotation(!openQuotation)
    if (isEdit && editData) {
    getAllQuotation(editData?._id)
    }
  }
  const getAllQuotation = async(_id)=>{
    await dispatch(fetchAsyncGetAllQuotation(_id)).then((res)=>{

        if(res?.payload){
            const quotations = res.payload?.quotations;
            setAllQuotation(quotations)
        }
        }).catch((err)=>{
          console.log(err)
        })
}
  
  useEffect(() => {
    // fill data in form on edit
    if (isEdit && editData) {
      getAllQuotation(editData?._id)

      setPoPrefix(editData.poNo);
      setSelectedDate(new Date(editData.orderDate));

      setGetPoMonth(
        `${editData.poNo.year}-${
          editData.poNo.month <= 9
            ? "0" + editData.poNo.month
            : editData.poNo.month
        }`
      );
     
      if(editData?.resourceId && editData?.resourceId?._id){
        getCompanyByID(editData?.resourceId?._id)
      }
      else if(editData?.resourceName){
        const setData = {
          label: editData?.resourceName,
          value: editData?.resourceName,
        };
        
        setResourceValue(setData);
        setPanNumber(editData?.panNumber)
      }
      if(editData?.resourceName){
        setOldResourceName(editData?.resourceName)
      }
      if (editData.paymentInfo?.totalPayAmount) {
        setTotalPaymentAmount(editData.paymentInfo?.totalPayAmount);
      }
       if (editData.paymentInfo?.payAmountApproveIsRequest) {
        setSendPayAmountRequest(true)
      }
      
       if(editData?.paymentInfo?.totalPayAmountIsApproved){
        setTotalPayAmountIsApproved(true)
      }
     
     
      if (editData.paymentInfo?.approvalAmount) {
        setApprovalAmount(editData.paymentInfo?.approvalAmount);
      }
      if (editData.paymentInfo?.list && editData.paymentInfo?.list.length > 0) {
        setMultipleAddPaymentInfo(editData.paymentInfo?.list);
      }
      if (editData?.approveRejectRemarks) {
        setApproveOrRejectRemarks(editData?.approveRejectRemarks)
      }
     
      if(editData?.referenceResourcePO){
        if(editData?.referenceResourcePO){
         
          dispatch(fetchAsyncResourcePONum()).then((res) => {
            if (res.payload) {
              const resourcePoNo = [];
              res.payload.forEach((item, index) => {
                resourcePoNo.push({
                  value: item._id,
                  label: poNumberFormate(item.poNo),
                });
              });
              const array = resourcePoNo && resourcePoNo?.length > 0 && resourcePoNo.filter((item)=> item.value == editData?.referenceResourcePO)
              const obj = array && array?.length && array[0]
              setSetResourcePoNo(obj);
            }
          });
      
      }
        // setSetResourcePoNo(editData?.referenceResourcePO)
      }
      
      if (editData?.totalBillingAmount) {
        setAmountTotal(editData?.totalBillingAmount);
      }
      if (editData?.remarks) {
        setRemarksValue(editData?.remarks)
      }
      if (editData.refundInfo?.list && editData.refundInfo?.list.length > 0) {
        setMultipleAddRefundInfo(editData.refundInfo?.list);
      }
      if (editData?.tdsPercent) {
        setTdsPercent(editData?.tdsPercent);
      }
      if (editData?.tdsAmount) {
        setTdsAmount(editData?.tdsAmount);
      }
      if (editData.invoice?.length > 0) {
        setMultipleInvoiceData([...editData.invoice]);
      } else {
        setMultipleInvoiceData([]);
      }
    }
  }, [isEdit, editData, formOptionsList]);

  // add Remark for Invoice
  const addRemarkForInvoice = (value, item,listIndex,index) => {
    dispatch(
      fetchAsyncAddTDSInvoiceRemark({
        id: editData._id,
        remarkData: { key: item.Key, remark: value, listIndex, index },
      })
    ).then((res) => {
      setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)

      refreshEditData();
    });
  };

  const handleAmountApproveBtn = (values)=>{   
  
      if (!resourceValue) {
        setErrors({
          ...errors,
          resourceValue:'Resource name is required!'
        })        
        return;
      }
     

    const checkApproval = checkApprovalAmount(
      approvalAmount,
    );
    if (!checkApproval.status) {
      setErrors({
        ...errors,
        approvalAmount:checkApproval.msg
      }) 
      return;
    }
  
    if(approvalAmount <= totalPaymentAmount && remainingAmount >= 0){
  
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        return;
      }
    }
    if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
      return;
    }
      let formData = new FormData();
      
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
     
      formData.append("resourceId", resourceValue.value || "");

      formData.append("panNumber", panNumber);
      formData.append("remarks", remarksValue);
      formData.append("refResourcePONum", setResourcePoNo?.value);

      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
      formData.append("isTDS", isTds);
      

      amountTotal && formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
     
      formData.append("invoiceNum", values.invoiceNum);
      formData.append("isRefund", values.isRefund);


      if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
        formData.append('quotation_remarks', quotationData?.remarks);
       formData.append('quotation_amount', quotationData?.amount);
       Array.from(quotationData?.files).forEach((file) => {
           formData.append('quotation_files', file);
       });

     }
      if(isEdit && editData){
        
       
        dispatch(fetchAsyncSendRequestUpdateApprovalTdsTotalPayAmount
          ({ 
          id: editData._id,
          data:formData
         })).then(
          (res) => {
            if (res.payload && res.payload.updatedData) {
              setIsEdit(true)
              setEditData(res.payload.updatedData)
              setSendPayAmountRequest(true)
              addToast(`Resend updated request for approval for expense ${generatedNum} !`, { appearance: "success" });
              tdsClose();
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
  
          
          }
        );
      }
      else{
  
        dispatch(fetchAsyncSendRequestApprovalTdsTotalPayAmount(formData)).then((res) => {
            if (res.payload) {
              addToast(`Send request for approval for expense ${generatedNum} !`, { appearance: "success" });
              setIsEdit(true)
              setEditData(res.payload)
              setSendPayAmountRequest(true)
              tdsClose();
  
            
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
          });
      }
      
      
      }

      const handleMultipleRefundInfo = (data) => {
        setMultipleAddRefundInfo([
          ...multipleAddRefundInfo,
          { data: "", paydata: data.data },
        ]);
      };

      const handleUpdateMultipleRefundData = (payData, index) => {
        const updateList = multipleAddRefundInfo.map((item, i) => {
          if (i === index) {
            return {...item,...payData};
          } else return item;
        });
        setMultipleAddRefundInfo([...updateList]);
      };
  const handleTotalPayAmountIsApproved = (values)=>{
    const _id = editData._id;
   
    let formData = new FormData();

    formData.append("poNo", JSON.stringify(poPrefix));
    formData.append("orderMonth", getPoMonth);
    formData.append("orderDate", selectedDate);
    formData.append("resourceId", resourceValue.value || "");
    formData.append("panNumber", panNumber);
    formData.append("remarks", remarksValue);
    formData.append("refResourcePONum", setResourcePoNo?.value);
    formData.append("totalPaymentAmount", totalPaymentAmount);
    formData.append("approvalAmount", approvalAmount);
    formData.append("isTDS", isTds);

    formData.append("approveRejectRemarks", approveOrRejectRemarks);
    amountTotal && formData.append("totalAmount", amountTotal);
    formData.append("tdsPercent", tdsPercent);
    !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
    if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
      formData.append('quotation_remarks', quotationData?.remarks);
     formData.append('quotation_amount', quotationData?.amount);
     Array.from(quotationData?.files).forEach((file) => {
         formData.append('quotation_files', file);
     });

   }
    if(!editData.paymentInfo?.totalPayAmountIsApproved){
      Swal.fire({
        title: `Are You Sure? You Want To Approve Amount <span style="color: orange"><span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)}</span> For <span style="color:orange">${generatedNum}</span>.`,
        text: `Please confirm to proceed with the approval.`,        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncApproveTdsTotalPayAmount({ 
            id: _id,
            data:formData,
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsApproved) {
                addToast(`Requested Amount for ${generatedNum} has been successfully approved.`, { appearance: "success" });
                tdsClose();
              }
              
            }
          );
        }
      });
     
    
    }
  }

  const handleTotalPayAmountIsRejected = ()=>{
    const _id = editData._id;
   
    if(!editData.paymentInfo?.totalPayAmountIsApproved &&  !editData.paymentInfo?.totalPayAmountIsRejected){
      Swal.fire({
        title: `Are You Sure? You Want To Reject Amount <span style='color:orange'> <span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)} </span> For <span style="color:orange">${generatedNum}.</span>`,
        text: `Please confirm to reject. This action cannot be undone.`,        
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncRejectTdsTotalPayAmount({ 
            id: _id,
            data:{totalPayAmountIsRejected:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsRejected) {
                addToast(`Requested Amount for ${generatedNum} has been successfully rejected.`, { appearance: "success" });
                tdsClose();
              }
              
            }
          );
        }
      });
     
    }
  
  }

  useEffect(()=>{
    if(multipleAddRefundInfo) {
      const array = multipleAddRefundInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalRefundAmount(parseFloat(sum))
        
      }
    }
    
  },[multipleAddRefundInfo])

  useEffect(()=>{
    if(multipleAddPaymentInfo) {
      const array = multipleAddPaymentInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalSpentAmount(parseFloat(sum))

        setRemainingAmount(parseFloat(totalPaymentAmount) - parseFloat(sum))
      }



    }
  },[multipleAddPaymentInfo])

  useEffect(()=>{
    if(totalPaymentAmount && !remainingAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[totalPaymentAmount])

  useEffect(()=>{
    if(totalPaymentAmount){
      if(totalPaymentAmount && !editData?.tdsApplicable?.totalTDS && isTds){
        setAmountTotal(parseFloat(totalPaymentAmount))
      }
    }
  },[totalPaymentAmount])
  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        tdsClose();
        resetAllStateOnCloseModal();
      }
    });
  };

  const handleRemarksChange = (e)=>{
    const value = e?.target?.value;
    const encoder = new TextEncoder(); // Default is 'utf-8'
    const byteLength = encoder.encode(value).length;
    if(byteLength <=128){

      setRemarksValue(value)
      setErrors({
        ...errors,
        remarks:''
      })
    }
    else{
      setErrors({
        ...errors,
        remarks:'Remarks must be within the 128 byte limit!'
      })
    }
  }

 
  const deleteMultipleListItem = (listIndex)=>{
    Swal.fire({
      title: "Are you sure? Delete Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteTdsPaymentListItem(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.tdsExpense?.paymentInfo?.list)
          refreshEditData();
        });
      }
    });
}
const deleteMultipleRefundListItem = (listIndex)=>{
  Swal.fire({
    title: "Are you sure? Delete Refund Amount Details Permanently.",
    text: ``,
    icon: "warning",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete",
    showCancelButton: true,
    customClass: {
      container: "sweet_containerImportant",
      confirmButton: "sweet_confirmbuttonImportant",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const sendData = {
        id: editData._id,
        listIndex:listIndex
      };
      dispatch(fetchAsyncDeleteTdsRefundListItem(sendData)).then((res) => {
        setMultipleAddRefundInfo(res?.payload?.tdsExpense?.refundInfo?.list)
        refreshEditData();
      });
    }
  });
}

const handleResourceNumber = (newValue)=>{
  const value = newValue;
  setSetResourcePoNo(value)
  setIsResourcePoChange(true)
  
}


const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      Amount Spent: ${200}
    </Popover.Body>
  </Popover>
);
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={tdsOpen}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          top="0" // Position it at the top
          margin="0 auto" // Center it horizontally
          bottom="0"
          className="add-form-modal-content"
        >
          <div className="fixed-header">
            <ModalHeader className=" expense-modal-header">
              {tdsExpenseData && "Edit"} TDS Expense
            </ModalHeader>
            {/* <p>Tax Deducted at Source (TDS)</p> */}
            <ModalCloseButton />
          </div>
          <ModalBody>
            <Form className="addForm" onSubmit={formik.handleSubmit}>
              <div className="form-card">
                <h5>Purchase Order Details</h5>
                <Row className="mt-2">
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Number <span className="span-star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="No"
                        value={generatedNum && generatedNum}
                        disabled
                      />
                    </Box>
                  </Col>

                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order - Month <span className="span-star">*</span>
                      </Form.Label>

                      <DatePicker
                        className="monthDate"
                        dateFormat="MM-yyyy"
                        showMonthYearPicker
                        name="orderMonth"
                        onChange={(e) => {
                          const dateTime = moment(e).format("yyyy-MM");
                          setGetPoMonth(dateTime);
                        }}
                        disabled={isEdit}
                        // defaultValue={new Date()}
                        selected={
                          getPoMonth ? new Date(moment(getPoMonth)) : ""
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Date <span className="span-star">*</span>
                      </Form.Label>
                      <DatePicker
                        name="orderDate"
                        className="monthDate"
                        dateFormat="dd-MMM-yyyy"
                        // disabled={isEdit}
                        onChange={(date) => {
                          setSelectedDate(date);
                        }}
                        selected={selectedDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                  <Col className="mb-0">
                    <Box display="flex" className="input-box">
                      <Form.Label style={{ width: "115px" }}>
                        Reference Resource PO
                      </Form.Label>

                      <ClientProjectSelect
                        options={poNoResource}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => handleResourceNumber(newValue)}
                        isSearchable
                        value={setResourcePoNo}
                        defaultValue={setResourcePoNo}
                        role={role}
                      />
                    </Box>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Resource Name <span className="span-star">*</span>
                      </Form.Label>

                      <ClientProjectSelect
                        options={resourceOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => {
                          setResourceValue(newValue);
                        }}
                        isSearchable
                        defaultValue={resourceValue}
                        role={role}
                      />
                    </Box>
                    {errors?.resourceValue && !resourceValue && (
                      <p className="form_error">{errors?.resourceValue}</p>
                    )}
                    {oldResourceName && (
                      <Form.Text className="text-muted previous_text">
                        <Box className="previous-text">
                          Previous Resource Name : {oldResourceName}
                        </Box>
                      </Form.Text>
                    )}
                  </Col>
                  <Col>
                    <PanNumberField
                      panNumber={panNumber}
                      handlePanNumber={(pan) => setPanNumber(pan)}
                      totalPayAmount={totalPayAmountByPanNumber}
                      role={role}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Remarks{" "}
                      </Form.Label>

                      <Form.Control
                        as="textarea"
                        rows={4}
                        name="remarks"
                        placeholder="Type Here..."
                        value={remarksValue}
                        // defaultValue={remarksValue}
                        onChange={(e) => handleRemarksChange(e)}
                        isInvalid={!!errors?.remarks}
                      />
                    </Box>
                    <p className="form_error">{errors?.remarks}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>Total Amount</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>

                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          placeholder="Total Amount "
                          onValueChange={(e) => {
                            setAmountTotal(e.value);
                          }}
                          name="Total Amount "
                          value={amountTotal || ""}
                          thousandSeparator={true}
                          // prefix={"$"}

                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </InputGroup>
                    </Box>
                  </Col>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>TDS Percent</Form.Label>
                      <InputGroup className="input-group">
                        <Form.Control
                          type="number"
                          placeholder="TDS Percent"
                          defaultValue={tdsPercent}
                          disabled={editPercentNum}
                          onChange={(e) => setTdsPercent(e.target.value)}
                        />
                        <InputGroup.Text>
                          {editPercentNum ? (
                            <img
                              src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                              alt="edit"
                              className="edit-button"
                              onClick={handleEditTDSPercentange}
                            />
                          ) : (
                            <img
                              src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                              alt="edit"
                              className="edit-button"
                              onClick={() => setEditPercentNum(true)}
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Box>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>TDS Amount</Form.Label>

                      <InputGroup>
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>

                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          onValueChange={(e) => {
                            setTdsAmount(e.value);
                          }}
                          name="TDS Amount "
                          value={
                            tdsAmount
                              ? Number(tdsAmount).toFixed(2)
                              : Number(0).toFixed(2)
                          }
                          thousandSeparator={true}
                          decimalScale={2}
                          disabled={editTDSAmount}
                          fixedDecimalScale={true}
                        />
                        <InputGroup.Text>
                          {editTDSAmount ? (
                            <img
                              src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                              alt="edit"
                              className="edit-button"
                              onClick={handleEditTDS}
                            />
                          ) : (
                            <img
                              src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                              alt="edit"
                              className="edit-button"
                              onClick={handleEditSubmit}
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Box>
                  </Col>
                  <Col></Col>
                </Row>
              </div>

              <div className="form-card mt-3">
                <h5>Payment Information</h5>
                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Requested Amount <span className="span-star">*</span>
                      </Form.Label>
                      <InputGroup className="input-group">
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>

                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled={
                            sendPayAmountRequest &&
                            !totalPayAmountIsApproved &&
                            (role === "Admin" || role === "Manager") &&
                            currUser.name !== editData?.paymentInfo?.requestedBy
                          }
                          name="approvalAmount"
                          placeholder="Requested Amount"
                          value={approvalAmount || ""}
                          onValueChange={(e) => {
                            setApprovalAmount(e.value);
                          }}
                          isInvalid={
                            (remainingAmount < 0 &&
                              approvalAmount <= totalPaymentAmount) ||
                            (!approvalAmount && errors?.approvalAmount)
                          }
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          // prefix={"$"}
                          decimalScale={2}
                        />
                        <InputGroup.Text
                          className="quotation-upload"
                          onClick={() => {
                            approvalAmount && setOpenQuotation(!openQuotation);
                          }}
                        >
                          <Tooltip
                            label="Upload Quotation"
                            fontSize="md"
                            borderRadius="2xl"
                          >
                            <div
                              disabled={!approvalAmount}
                              className="position-relative"
                            >
                              <FaFileUpload size={20} />
                              <span className="position-absolute top-0 start-100 translate-middle badge  bg-primary text-white">
                                {quotationData?.files || quotationData?.remarks
                                  ? allQuotation?.length + 1
                                  : allQuotation?.length || 0}
                              </span>
                            </div>
                          </Tooltip>
                        </InputGroup.Text>
                      </InputGroup>
                    </Box>
                    {((remainingAmount < 0 &&
                      approvalAmount <= totalPaymentAmount) ||
                      !approvalAmount) && (
                      <p className="form_error">{errors?.approvalAmount}</p>
                    )}
                  </Col>
                  {totalPayAmountIsApproved ? (
                    <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>Approved Amount</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <span className="rupee-symbol">₹</span>{" "}
                          </InputGroup.Text>

                          <CurrencyFormat
                            customInput={Form.Control}
                            type="text"
                            disabled
                            name="totalPayAmount"
                            placeholder="Total Amount"
                            value={totalPaymentAmount ? totalPaymentAmount : 0}
                            thousandSeparator={true}
                            // prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </InputGroup>
                      </Box>
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>

                {totalPayAmountIsApproved && (
                  <>
                    <Row>
                      <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>Net Payable Amount</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <span className="rupee-symbol">₹</span>{" "}
                            </InputGroup.Text>

                            <CurrencyFormat
                              customInput={Form.Control}
                              type="text"
                              disabled
                              name="Net Payable Amount"
                              value={remainingAmount ? remainingAmount : 0}
                              thousandSeparator={true}
                              decimalScale={2}
                              isInvalid={
                                remainingAmount < 0 &&
                                approvalAmount <= totalPaymentAmount
                              }
                              fixedDecimalScale={true}
                            />
                          </InputGroup>
                        </Box>
                        {remainingAmount < 0 &&
                          approvalAmount <= totalPaymentAmount && (
                            <p className="form_error">
                              Net payable amount cannot be negative. Please
                              revise the request with an increased requested
                              amount and resubmit for approval.
                            </p>
                          )}
                      </Col>
                      <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>Total Spent Amount</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <span className="rupee-symbol">₹</span>{" "}
                            </InputGroup.Text>
                            <CurrencyFormat
                              customInput={Form.Control}
                              type="text"
                              disabled
                              name="totalPayAmount"
                              placeholder="Total Amount"
                              value={
                                parseFloat(totalSpentAmount) -
                                parseFloat(totalRefundAmount)
                              }
                              thousandSeparator={true}
                              // prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </InputGroup>
                        </Box>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              {totalPayAmountIsApproved && (
                <div>
                  <Tabs
                    defaultActiveKey="spent"
                    transition={false}
                    id="noanim-tab-example"
                    className="add-form-tabs"
                  >
                    <Tab
                      eventKey="spent"
                      title="Spent Amount"
                      className="add-form-tab mx-0"
                    >
                      <div className="form-card">
                        
                        {totalPayAmountIsApproved &&
                          multipleAddPaymentInfo.map((item, index) => {
                            return (
                              <>
                                <PaymentInfoComponent
                                  key={`payment-info-${index}`}
                                  getData={(data) => {
                                    handleUpdateMultiplePayData(data, index);
                                  }}
                                  autoCount={index}
                                  paymentData={item}
                                  editData={editData}
                                  refreshEditData={refreshEditData}
                                  isEdit={isEdit}
                                  handleInvoiceChange={(item) =>
                                    handleInvoiceChange(item)
                                  }
                                  multipleInvoiceData={
                                    item?.invoice ? item?.invoice : [""]
                                  }
                                  handleUpdateInvoiceData={(item, index) =>
                                    handleUpdateInvoiceData(item, index)
                                  }
                                  handelDeleteInvoice={(index, id) =>
                                    handelDeleteInvoice(index, id)
                                  }
                                  handleDeleteInvoiceOnEdit={(key, listIndex) =>
                                    handleDeleteInvoiceOnEdit(key, listIndex)
                                  }
                                  addNewInvoiceOnEdit={(
                                    file,
                                    index,
                                    listIndex
                                  ) =>
                                    addNewInvoiceOnEdit(file, index, listIndex)
                                  }
                                  addRemarkForInvoice={(
                                    value,
                                    item,
                                    listIndex,
                                    index
                                  ) =>
                                    addRemarkForInvoice(
                                      value,
                                      item,
                                      listIndex,
                                      index
                                    )
                                  }
                                  expenseType="tds"
                                  deleteMultipleListItem={() =>
                                    deleteMultipleListItem(index)
                                  }
                                  role={role}
                                />
                              </>
                            );
                          })}
                           <Box display="flex" alignItems="center"  onClick={() => {
                              handleMultiplePayInfo(
                                multipleAddPaymentInfo.length + 1
                              );
                            }}>
                          <h5>Add Spent Amount</h5>

                          <Button
                            className="add-project mt-2 mx-1"
                            minWidth={0}
                           
                          >
                            <FaPlusCircle
                              className="add-project-icon"
                              size={15}
                            />
                          </Button>
                        </Box>
                      </div>
                    </Tab>
                    {(role === "Admin" || role === "Manager") && (
                      <Tab
                        eventKey="excel"
                        title="Download Excel"
                        className="add-form-tab"
                      >
                        <DownloadExcelFile
                          prNo={generatedNum}
                          payAmount={totalPaymentAmount}
                          role={role}
                        />
                      </Tab>
                    )}
                    {(role === "Admin" || role === "Manager") && (
                      <Tab
                        eventKey="refund"
                        title={
                          <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                            <div className="d-flex align-items-center">
                            Refund
                              {formik.values.isRefund &&
                              <FaCheck size={12}  className="tab-icon"/> 
                              }
                            </div>
                          </OverlayTrigger>
                        }                                  
                        className="add-form-tab"
                      >
                        <div className="form-card">
                          <Box display="flex" alignItems="center">
                            <h5>Refund Information</h5>
                            <Form.Check
                              className="mx-1 mt-2"
                              type="switch"
                              id="custom-switch"
                              checked={formik.values.isRefund}
                              {...formik.getFieldProps("isRefund")}
                            />
                            
                          </Box>

                          {formik.values.isRefund && (
                            <Box>
                              {multipleAddRefundInfo &&
                                multipleAddRefundInfo?.map((item, index) => {
                                  return (
                                    <RefundInfoListComponent
                                      key={`refundInfo-${index}`}
                                      getData={(data) => {
                                        handleUpdateMultipleRefundData(
                                          data,
                                          index
                                        );
                                      }}
                                      autoCount={index}
                                      paymentData={item}
                                      editData={editData}
                                      refreshEditData={refreshEditData}
                                      deleteMultipleListItem={() =>
                                        deleteMultipleRefundListItem(index)
                                      }
                                      expenseType="tds"
                                      role={role}
                                    />
                                  );
                                })}
                            </Box>
                          )}
                          {formik.values.isRefund && (
                          <Box display="flex" alignItems="center">
                            <h5>Add Refund</h5>
                            
                            <Button
                              className="add-project mt-2"
                              onClick={() => {
                                handleMultipleRefundInfo(
                                  multipleAddRefundInfo.length + 1
                                );
                              }}
                              disabled={!formik.values.isRefund}
                            >
                              <FaPlusCircle
                                className="add-project-icon"
                                size={15}
                              />
                            </Button>
                          </Box>
                          )}
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              )}

              {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest && (
                  <>
                    <div className="form-card">
                      <h5>Approve OR Reject Remark</h5>

                      <Row>
                        <Col>
                          <Box display="flex" className="input-box">
                            <Form.Label>
                              Remarks
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              placeholder="Type Here..."
                              value={approveOrRejectRemarks}
                              onChange={(e) =>
                                setApproveOrRejectRemarks(e.target.value)
                              }
                              isInvalid={
                                !approveOrRejectRemarks &&
                                errors?.approveOrRejectRemarks
                              }
                            />
                          </Box>
                          {!approveOrRejectRemarks && (
                            <p className="form_error">
                              {errors?.approveOrRejectRemarks}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              <Row className="mt-4">
                {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest ? (
                  <ModalFooter
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    {!totalPayAmountIsApproved && (
                      <div className="d-flex">
                        <Button
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => {
                            handleTotalPayAmountIsApproved(formik.values);
                          }}
                          className="submit-btn me-2"
                        >
                          Approve
                        </Button>

                        <Button
                          className="submit-btn"
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => handleTotalPayAmountIsRejected()}
                          style={{ background: "#191063" }}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  </ModalFooter>
                ) : (
                  <ModalFooter>
                    {(!totalPayAmountIsApproved && !sendPayAmountRequest) ||
                    totalSpentAmount > totalPaymentAmount ||
                    parseFloat(approvalAmount) !==
                      parseFloat(totalPaymentAmount) ? (
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleAmountApproveBtn(formik.values);
                        }}
                        className="submit-btn"
                      >
                        Send Request
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="submit-btn"
                        variant="brand"
                        style={{ background: "#191063" }}
                        onClick={() => setCompareDataModal(true)}
                      >
                        Submit
                      </Button>
                    )}
                  </ModalFooter>
                )}
              </Row>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {compareDataModal && comparingData && (
        <DataChangeModalOnEdit
          openModal={compareDataModal}
          closeModal={closeComparedDataModal}
          editData={editData}
          currentData={comparingData}
          isConfirmed={() => {
            submitUpdateData(comparingData, editData);
          }}
          type={"tds"}
        />
      )}
      {openQuotation && (
        <QuotationForm
          isOpen={openQuotation}
          closeModal={() => {
            handleCloseQuotation();
          }}
          requestedAmount={approvalAmount}
          handleData={(data) => handleQuotationData(data)}
          uploadedData={quotationData}
          expenseId={editData?._id || ""}
          allQuotation={allQuotation}
        />
      )}
    </>
  );
};

export default TdsAddNew;
