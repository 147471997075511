import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import { MdOutlineFileDownload } from "react-icons/md";

import InvoiceColumnTable from "views/admin/embrillTable/InvoiceColumnTable";
import { useToasts } from "react-toast-notifications";

const PdfListModel = ({
  openModal,
  closeModal,
  pdfData,
  currentId,
  deleteFileFromList,
  role
}) => {
  const [tableData, setTableData] = useState([]);
  
  const { addToast } = useToasts();


  const columnData = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Title",
      accessor: "label",
    },
    {
      Header: "Remark",
      accessor: "remark",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const handleDownload = (loc) => {
    const s3Link = loc;
    const link = document.createElement("a");
    link.href = s3Link;
    link.download = ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const apiURL = process.env.REACT_APP_BASE_URL;

  const handleRowClick = async(key,label) => {
    try {
      const response = await fetch(`${apiURL}/preview-invoice?key=${encodeURIComponent(key)}`, {
        method: 'GET',
        headers: {
          // Add any necessary headers, like authorization
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      
      const blob = await response.blob();   
  
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank'); 

    } catch (error) {
      addToast("Error fetching PDF preview:", { appearance: "error" });
    }
  };

  useEffect(() => {
    if (pdfData && pdfData.length > 0) {
      const content = pdfData.map((data, index) => {
        let label = "";
        if (data?.Key) {
          let splitKey = data.Key.split("/");
          label = data.Key && splitKey[splitKey.length - 1];
        }
        return {
          no: index + 1,
          Key: data?.Key,
          label: label,
          remark: data?.remark,
          action: (
            <div className="d-flex">
              <MdOutlineFileDownload
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleDownload(data?.Location);
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={(e) => {
                    e.preventDefault();
                    deletePdfFile(data?.Key, data?.listIndex);
                  }}
                  className="ms-2"
                />
              )}
            </div>
          ),
        };
      });
      setTableData(content);
    } else {
      setTableData([]);
    }
  }, [pdfData]);

  const deletePdfFile = (key, listIndex) => {
    Swal.fire({
      title: "Are You Sure? Delete File Permanently.",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: currentId,
          key: key,
          listIndex: listIndex
        };
        deleteFileFromList(sendData);
      }
    });
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onClose={closeModal}
        size="xl"
        className="modal-main"
      >
        <ModalOverlay />
        <ModalContent>
          <div className="fixed-header">
            <ModalHeader>Attachments</ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            <InvoiceColumnTable
              columnsData={columnData}
              tableData={tableData}
              type={""}
              handleRowClick={(e,key,label)=>handleRowClick(key,label)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

     
    </div>
  );
};

export default PdfListModel;
