/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartOptions } from "variables/charts";
import React, { useEffect, useState } from "react";

export default function Conversion(props) {
  const { ...rest } = props;

  const [expensesData, setExpensesData] = useState([]);
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(null);
  const [externalPercentage, setExternalPercentage] = useState(null);
  const [internalPercentage, setInternalPercentage] = useState(null);
  const [resourcePercentage, setResourcePercentage] = useState(null);
  const [tdsPercentage, setTdsPercentage] = useState(null);
  const [directTaxPercentage, setDirectTaxPercentage] = useState(null);

  const [piChartTypeOption, setPiChartTypeOption] = useState("yearly");

  useEffect(() => {
    if (props?.data) {
      setExpensesData([
        props?.data?.externalTotalPayAmount,
        props?.data?.ineternalTotalPayAmount,
        props?.data?.resourceTotalPayAmount,
        props?.data?.tdsTotalPayAmount,
        props?.data?.directTaxTotalPayAmount,
      ]);
      const total =
        props?.data?.externalTotalPayAmount +
        props?.data?.ineternalTotalPayAmount +
        props?.data?.resourceTotalPayAmount +
        props?.data?.tdsTotalPayAmount +
        props?.data?.directTaxTotalPayAmount;
      setTotalExpenseAmount(total);
    }
  }, [props?.data]);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  useEffect(() => {
    setExternalPercentage(
      (props?.data?.externalTotalPayAmount / totalExpenseAmount) * 100
    );
    setInternalPercentage(
      (props?.data?.ineternalTotalPayAmount / totalExpenseAmount) * 100
    );
    setResourcePercentage(
      (props?.data?.resourceTotalPayAmount / totalExpenseAmount) * 100
    );
    setTdsPercentage(
      (props?.data?.tdsTotalPayAmount / totalExpenseAmount) * 100
    );
    setDirectTaxPercentage(
      (props?.data?.directTaxTotalPayAmount / totalExpenseAmount) * 100
    );
  }, [totalExpenseAmount]);
  return (
    <Card 
      p={0} 
      align="center" 
      direction="column" 
      mx={20}
      
      w="90%" 
      {...rest}  
      className="weekly-expense-graph"
      >
      <Flex
       className="weekly-graph-flex"
        alignItems="center"
        justifyContent="space-between"

        w="100%"
        mb={10}
       
      >
        <Text>
          Expenses Pie Chart
        </Text>
        <Select
          fontSize="sm"
          variant="subtle"
          defaultValue="yearly"
          width="unset"
          fontWeight="700"
          onChange={(e) => {
            props.changePiType(e.target.value);
            setPiChartTypeOption(e.target.value);
          }}
        >
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>
        <Select
          fontSize="sm"
          variant="subtle"
          defaultValue="2024-2025"
          width="unset"
          fontWeight="700"
          onChange={(e) => {
            props.changePiYear(e.target.value);
          }}
        >
          <option value="">finance year</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
          <option value="2025-2026">2025-2026</option>

        </Select>
        {piChartTypeOption === "monthly" && (
          <Select
            fontSize="sm"
            variant="subtle"
            defaultValue="12"
            width="unset"
            fontWeight="700"
            onChange={(e) => {
              props.changePiMonth(e.target.value);
            }}
          >
            <option value="1">JAN</option>
            <option value="2">FAB</option>
            <option value="3">MAR</option>
            <option value="4">APR</option>
            <option value="5">MAY</option>
            <option value="6">JUN</option>
            <option value="7">JUL</option>
            <option value="8">AUG</option>
            <option value="9">SEP</option>
            <option value="10">OCT</option>
            <option value="11">NOV</option>
            <option value="12">DEC</option>
          </Select>
        )}
      </Flex>

      <PieChart
        h="100%"
        w="100%"
        chartData={expensesData}
        chartOptions={pieChartOptions}
      />
      <Card
        bg={cardColor}
        flexDirection="row"
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mx="auto"
      >
        <Flex direction="column" py="5px" me="20px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#4318FF" borderRadius="50%" me="4px" />
            <Text fontSize="xs" color="secondaryGray.600" fontWeight="700">
              External
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {externalPercentage?.toFixed(2)}%
          </Text>
        </Flex>
        {/* <VSeparator mx={{ base: "50px", xl: "50px", "2xl": "50px" }} /> */}
        <Flex direction="column" py="5px" me="20px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#6AD2FF" borderRadius="50%" me="4px" />
            <Text fontSize="xs" color="secondaryGray.600" fontWeight="700">
              Internal
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {internalPercentage?.toFixed(2)}%
          </Text>
        </Flex>
        {/* <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} /> */}
        <Flex direction="column" py="5px" me="20px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#f5e322" borderRadius="50%" me="4px" />
            <Text fontSize="xs" color="secondaryGray.600" fontWeight="700">
              Resource
            </Text>
            {/* <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} /> */}
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {resourcePercentage?.toFixed(2)}%
          </Text>
        </Flex>
        {/* <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} /> */}
        <Flex direction="column" py="5px" me="20px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#f01132" borderRadius="50%" me="4px" />
            <Text fontSize="xs" color="secondaryGray.600" fontWeight="700">
              TDS
            </Text>
          </Flex>
          {/* <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} /> */}
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {tdsPercentage?.toFixed(2)}%
          </Text>
        </Flex>
        <Flex direction="column" py="5px" me="20px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#A3C1AD" borderRadius="50%" me="4px" />
            <Text fontSize="xs" color="secondaryGray.600" fontWeight="700">
              Direct-Tax
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {directTaxPercentage?.toFixed(2)}%
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
