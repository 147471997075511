// src/utils/searchUtils.js
import { fetchAsyncSearchClientProjectData } from 'redux/clientProjectSlice';
import { fetchAsyncSearchDTData } from 'redux/directTaxSlice';
import { fetchAsyncSearchEmbrillProfileData } from 'redux/embrillProfileSlice';
import { fetchAsyncSearchExternalData } from 'redux/externalSlice';
import { fetchAsyncSearchInternalData } from 'redux/internalSlice';
import { fetchAsyncSearchResourceData } from 'redux/resourceSlice';
import { setDateRangeGlobal } from 'redux/slices/dateRangeSlice';
import { fetchAsyncSearchGstData } from 'redux/slices/gstSlice';
import { fetchAsyncSearchTdsData } from 'redux/tdsSlice';
import { fetchAsyncSearchVendorData } from 'redux/vendorSlice';


export const performSearch = (dispatch, location, value, startDate, endDate) => {
  switch (location.pathname) {
    case "/external":
      dispatch(fetchAsyncSearchExternalData({ value, startDate, endDate }));
      break;
    case "/internal":
      dispatch(fetchAsyncSearchInternalData({ value, startDate, endDate }));
      break;
    case "/resource-expanse":
      dispatch(fetchAsyncSearchResourceData({ value, startDate, endDate }));
      break;
    case "/tds":
      dispatch(fetchAsyncSearchTdsData({ value, startDate, endDate }));
      break;
    case "/direct-tax":
      dispatch(fetchAsyncSearchDTData({ value, startDate, endDate }));
      break;
    case "/gst":
      dispatch(fetchAsyncSearchGstData({ value, startDate, endDate }));
      break;
    case "/vendor":
      dispatch(fetchAsyncSearchVendorData({ value, startDate, endDate }));
      break;
    case "/client-project":
      dispatch(fetchAsyncSearchClientProjectData({ value, startDate, endDate }));
      break;
    case "/embrill-profile":
      dispatch(fetchAsyncSearchEmbrillProfileData({ value, startDate, endDate }));
      break;
    default:
      break;
  }
};
