import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Box,
    Divider,
    Text,
} from '@chakra-ui/react';
import QuotationTable from './QuotationTable'; // Import the new table component
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch } from 'react-redux';
import { fetchAsyncGetAllQuotation } from 'redux/quotationSlice';
import Swal from 'sweetalert2';
import { fetchAsyncDeleteQuotation } from 'redux/quotationSlice';
import { useToasts } from 'react-toast-notifications';

const apiURL = process.env.REACT_APP_BASE_URL;

const QuotationForm = (
    { 
        closeModal, 
        isOpen, 
        requestedAmount, 
        handleData,
        uploadedData,
        expenseId,
        allQuotation
    }) => {
    const [quotations, setQuotations] = useState([]);
    const [remarks, setRemarks] = useState('');
    const [amount, setAmount] = useState('');
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch(); 
    const { addToast } = useToasts();

    
    
    useEffect(()=>{
        if(expenseId && allQuotation && allQuotation?.length){

            setQuotations(allQuotation)
        }
    },[expenseId])

    useEffect(()=>{
        if(uploadedData && uploadedData?.files?.length){
            setRemarks(uploadedData?.remarks)
            setAmount(uploadedData?.amount);
          
        }
    },[uploadedData])
    
    const handleDeleteQuotation = (key,id) => {
        Swal.fire({
          title: "Are you sure? Delete File Permanently.",
          text: `Delete File ${key}`,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
          showCancelButton: true,
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const sendData = {
              id: id,
              key: key,
              expenseId:expenseId
            };
            dispatch(fetchAsyncDeleteQuotation(sendData)).then((res) => {
                const quotations = res.payload?.quotations;
                setQuotations(quotations)
                addToast("Quotation file deleted successfully!", { appearance: "success" });
            });
          }
        });
      };
    useEffect(()=>{
        if(requestedAmount){
            setAmount(requestedAmount)
        }
    },[requestedAmount])


    const handleAddQuotation = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if(!amount){
            addToast("Amount Is required!", { appearance: "error" });
            return
        }
        if(!files?.length && !remarks){
            addToast("Files or Remarks are required!", { appearance: "error" });
            return
        }

        handleData({remarks,amount,files});
        resetForm();
        addToast("Quotation Uploaded successfully!", { appearance: "success" });
        closeModal();
       
    };

    const resetForm = () => {
        setRemarks('');
        setAmount('');
        setFiles([]);
    };

   
    

    const handlePreview = (fileUrl) => {
        window.open(fileUrl, '_blank'); // Open in a new tab
    };

    return (
      <>
        <Modal isOpen={isOpen} onClose={closeModal}  className="modal-main" size="xl">
          <ModalOverlay />
          <ModalContent 
          className="info-model"
          top="0" // Position it at the top
          margin="0 auto" // Center it horizontally
          bottom="0"
          >
            
            <ModalHeader className="expense-modal-header">Add Quotation</ModalHeader>
            <ModalCloseButton className="expense-modal-header" />
            <ModalBody>
              <Form >
              <div className="form-card">
                <Row className=''>
                  <Col>
                  
                    <Form.Label>Requested Amount</Form.Label>
                    <span className="span-star">*</span>

                    <InputGroup >
                      <InputGroup.Text>
                        <span className="rupee-symbol">₹</span>{" "}
                      </InputGroup.Text>
                      <CurrencyFormat
                        customInput={Form.Control}
                        type="text"
                        disabled
                        name="totalPayAmount"
                        placeholder="Total Amount"
                        value={parseFloat(amount || 0)}
                        thousandSeparator={true}
                        onValueChange={(e) => {
                          setAmount(e.value);
                        }}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                  <Form.Label>Upload Files (image/pdf)</Form.Label>

                    <FormControl mb={4}>
                      <Input
                        type="file"
                        multiple
                        accept="image/*,.pdf"
                        onChange={(e) => setFiles(e.target.files)}
                      />
                    
                    </FormControl>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col>
                    <Form.Label>Remarks </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Type Here..."
                      name="remarks"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </Col>
                </Row>
                <Box display="flex" alignItems="center" justifyContent="center">

                <Button className='add-quotation-btn' type="submit" onClick={(e)=>handleAddQuotation(e)} colorScheme="green">
                  Add
                </Button>
                </Box>
              
                </div>
              </Form>
              <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
              <Box mt={6}>
                {quotations && quotations?.length ?
                <QuotationTable
                  quotations={quotations}
                  handlePreview={handlePreview}
                  handleDelete={(key,id)=>handleDeleteQuotation(key,id)}
                />
                :
                ""
                }
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeModal}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
};

export default QuotationForm;
