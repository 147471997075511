// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import { IoHomeSharp } from "react-icons/io5";
import { useSelector } from "react-redux";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const { currUser } = useSelector((state) => state?.auth);

  const auth = currUser?.auth;


  const moduleAccess = module => auth?.some(a=> a.module === module && a?.access !== 'No Access')
  
 
  return (
    <Box
      position={navbarPosition}
      bg="#f7f7fe"
      right={0}
      px={2}
      py={1}
      top={0}
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: `calc( 100vw - ${!props?.collapseSidebar ? '200px' : '30px'} )` ,
        "2xl": `calc( 100vw - ${!props?.collapseSidebar ? '200px' : '30px'} )` ,
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
        mb={gap}
      >
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            <Box display="flex">
            
            <BreadcrumbItem color={secondaryText} fontSize="sm">
            <BreadcrumbLink href={moduleAccess('Main Dashboard') ? '/' : '/all-category' } mb={1} ><IoHomeSharp size={15}/></BreadcrumbLink>
            {/* <span className="mx-1">/</span> */}
            {/* <BreadcrumbLink href="/all-category" color={secondaryText}>
               Category 
              </BreadcrumbLink> */}
              <span className="mx-1">/</span>
              <BreadcrumbLink href="#" color={secondaryText}>
              {brandText}
              </BreadcrumbLink>
            </BreadcrumbItem>
            </Box>
          </Breadcrumb>
          {/* <Link
            color={mainText}
            bg="inherit"
            borderRadius="inherit"
            fontWeight="600"
            fontSize="24px"
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            {brandText}
          </Link> */}
        </Box>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
            brandText={brandText}
          />
        </Box>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}
