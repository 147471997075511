/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
} from "@chakra-ui/react";
import { useToasts } from "react-toast-notifications";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";


import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import { fetchAsyncAddClient } from "redux/clientProjectSlice";
import { Tooltip } from "react-bootstrap";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { fetchAsyncUpdateClient } from "redux/clientProjectSlice";
import { fetchAsyncAddProject } from "redux/clientProjectSlice";
import { fetchAsyncEditProject } from "redux/clientProjectSlice";
import { fetchAsyncDeleteProject } from "redux/clientProjectSlice";
import { AiFillDelete } from "react-icons/ai";

// import { Option } from "antd/es/mentions";

// modal style

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});


const ClientProject = ({
  openModal,
  closeModal,
  isEditForm = false,
  editItem,
  role,
  refreshEditData
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [editData, setEditData] = useState(editItem)
  const [isEdit, setIsEdit] = useState(isEditForm)

  const [addProject,setAddProject] = useState(false)
  const [isEditProject,setIsEditProject] = useState(false)
  const [editProjectId,setEditProjectId] = useState('')

  const [projectName,setProjectName] = useState('')
  const [formData, setFormData] = useState({
    clientName:'',
    projects:[]
  })


  useEffect(()=>{
    if(editData) {
      const {clientName,projects} = editData;
    
      setFormData({
        ...formData,
        clientName:clientName,
        projects:projects
      })
    }
  },[isEdit])


  const handleOnChange = (e)=>{
    const name = e?.target?.name;
    const value = e?.target?.value;

    setFormData({
      ...formData,
      [name]:value
    })
  }
  
  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        closeModal();
      }
    });
  };


  const handleIsEditProject = (projectName,projectId)=>{
    setProjectName(projectName)
    setEditProjectId(projectId)
    setIsEditProject(!isEditProject)
  }

  useEffect(()=>{
    if(!isEditProject){
      setEditProjectId('')
      setProjectName('')
    }
  },[!isEditProject])

  const [errors, setErrors] = useState({
    clientName: '',
  });


  const validate = () => {
    const newErrors = {};
    if (!formData.clientName) newErrors.clientName = 'Client Name is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

const handleAddClient = ()=>{
  dispatch(fetchAsyncAddClient({
    clientName:formData.clientName,
    projectName:projectName
  }))
    .then((res) => {
      if (res?.payload) {
         
          const clientProject = res?.payload?.clientProject;
          const {clientName, projects} = clientProject
              setFormData({
                clientName:clientName,
                projects:projects
              })
              setEditData(clientProject)
              setIsEdit(true)
              setProjectName('')
              refreshEditData()
        addToast("client created successfully!", { appearance: "success" });
            }

    })
    .catch((err) => {
      addToast("Not Added", { appearance: "error" });
    });
}


const handleEditClient = ()=>{
  dispatch(fetchAsyncUpdateClient({ id: editData._id, clientData: formData }))
          .then((res) => {
            if (res?.payload) {
              const clientProject = res?.payload?.clientProject;
              const {clientName, projects} = clientProject
              setFormData({
                clientName:clientName,
                projects:projects
              })
                closeModal();
                addToast(`Client details have been updated successfully!`, { appearance: "success" });
            }
          })
          .catch((err) => {
            addToast("Error", { appearance: "error" });
          });
}

const handleAddProject = ()=>{
  if(!isEdit){
    if (validate()) {
        handleAddClient()
    }
  }else{
    dispatch(fetchAsyncAddProject({ id: editData._id, projectName:projectName }))
            .then((res) => {
              if (res?.payload) {
                const clientProject = res?.payload?.clientProject;
                const {clientName, projects} = clientProject
                setFormData({
                  clientName:clientName,
                  projects:projects
                })
                setProjectName('')
                  // closeModal();
                  addToast(`project added successfully!`, { appearance: "success" });
              }
            })
            .catch((err) => {
              addToast("Error", { appearance: "error" });
            });
  }
}

const handleSubmit = (e) => {
  e.preventDefault();
  if (validate()) {

      if(!isEdit){
        handleAddClient()
      }
      else{
        handleEditClient();
      }
    }
};
  const finalRef = useRef(null);


  const handleEditProject = (e)=>{
    e.preventDefault();
    dispatch(fetchAsyncEditProject({ id: editData._id,projectId:editProjectId ,projectName:projectName }))
            .then((res) => {
              if (res?.payload) {
                const clientProject = res?.payload?.clientProject;
                const {clientName, projects} = clientProject
                setFormData({
                  clientName:clientName,
                  projects:projects
                })
                setIsEditProject(false);
                setProjectName('')
                setEditProjectId("")
                  // closeModal();
                  addToast(`project updated successfully!`, { appearance: "success" });
              }
            })
            .catch((err) => {
              addToast("Error", { appearance: "error" });
            });
   
  } 

  const handleDeleteProject = (projectId)=>{
    dispatch(fetchAsyncDeleteProject({ id: editData._id,projectId:projectId }))
            .then((res) => {
              if (res?.payload) {
                const clientProject = res?.payload?.clientProject;
                const {clientName, projects} = clientProject
                setFormData({
                  clientName:clientName,
                  projects:projects
                })
                
                  addToast(`project deleted successfully!`, { appearance: "success" });
              }
            })
            .catch((err) => {
              addToast("Error", { appearance: "error" });
            });
  } 


  const handleAddProjectBtn = (e)=>{
    if(addProject && !projectName){
      setErrors({
        ...errors,
        projectName:'Project is not be empty!'
      })
    }
    else{
      if(!isEditProject){
        handleAddProject(e)
      }else{
        handleEditProject(e)
      }

    }

  }
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openModal}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent 
       
        top="0" // Position it at the top
        margin="0 auto" // Center it horizontally
        bottom="0"
        className="add-form-modal-content"
        >
          <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
              {isEdit && "Edit"} Client And Projects{" "}
            </ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            <Form className="addForm">
              <div className="form-card">
                <h5>Client Details</h5>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box" width={450}>

                    <Form.Label >Company / client Name <span className="span-star">*</span></Form.Label>
                    

                    <Form.Control
                      type="text"
                      name="clientName"
                      placeholder="Enter Client Name"
                      value={formData?.clientName}
                      onChange={(e) => handleOnChange(e)}
                      isInvalid={!!errors.clientName}
                    />
                  </Box>
                    <p className="form_error">{errors.clientName}</p>
                    
                  </Col>
                  
                </Row>
              </div>
              <div className="form-card mt-3">
                <Box display="flex" alignItems="center">

                <h5>Project Details</h5>
                <Button className="add-project mt-2 mx-1" onClick={()=>{setAddProject(!addProject)}}>
                     <FaPlusCircle 
                     className="add-project-icon"
                     size={15}
                     />
                     
                </Button>
                </Box>
                {(addProject || isEditProject) &&
                
                <Row>
                  <Col>
                  <Box display="flex" className="input-box" width={680}>

                    <Form.Label>Project Name </Form.Label>
                    <Form.Control
                      type="text"
                      name="projectName"
                      placeholder="Enter Project Name"
                      value={projectName}
                      onChange={(e) => setProjectName(e?.target?.value)}
                      isInvalid={!!errors.projectName}
                    />
                  <Box className="mx-3">

                  <Button
                      variant="brand"
                      className="add-project-btn px-4"
                      size="sm"
                      onClick={(e)=>handleAddProjectBtn(e)}
                    >
                     {isEditProject ? 'Edit' : 'Add'}
                    </Button>
                  </Box>
                    </Box>
                    <p className="form_error">{errors.projectName}</p>
                  </Col>
                 
                  </Row>
                }
                <TableContainer className="user-role" mt={5}>
                  <Table size="md" variant="bubble" className="user-role-table">
                    <Thead>
                      <Tr>
                        <Th>No.</Th>
                        <Th width={400}>Project Name</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {formData?.projects && formData?.projects?.length > 0 && formData?.projects.map((item,index)=>{
                        return (
                          <Tr id={item?._id}>
                            <Td>{index + 1}</Td>
                            <Td>{item?.projectName}</Td>
                            <Td>
                              <div className="d-flex">
                                <FaEdit
                                  style={{
                                    color: "#db8a2f",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  size={16}

                                  onClick={() => {
                                    handleIsEditProject(
                                      item?.projectName,
                                      item?._id
                                    );
                                  }}
                                />
                                <AiFillDelete
                                  style={{
                                    color:'red',
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  size={16}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Swal.fire({
                                      title: `Are you sure? you want to delete project <span style="color: #d33">project Name : ${
                                        item?.projectName && item?.projectName
                                      }</span>.`,
                                      text: "This action cannot be undone.",
                                      icon: "warning",
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete",
                                      showCancelButton: true,
                                      customClass: {
                                        container: "sweet_containerImportant",
                                        confirmButton:
                                          "sweet_confirmbuttonImportant",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDeleteProject(item?._id);
                                      }
                                    });
                                  }}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </div>
              <ModalFooter className="mt-3">
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="brand"
                  style={{ background: "#191063" }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClientProject;
