import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {
  Table,
  Td,
  Tr,
  Th,
  Tbody,
  Thead,
  Tfoot,
  Box,
} from "@chakra-ui/react";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination"; // Import Pagination component

const accountType = {
    Savings: 'Savings',
    Current: 'Current',
    Fixed: 'Fixed Deposit',
    Rsecurring:'Recurring Deposit'
};

const VendorBankInfo = ({ selectedItem }) => {
 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Set the number of items per page

 

  // Calculate pagination details
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = selectedItem?.bankDetails.slice(indexOfFirstItem, indexOfLastItem) || [];
  const pageCount = Math.ceil((selectedItem?.bankDetails?.length || 0) / itemsPerPage);

  return (
    <>
     
      {currentItems.length ? (
        <div className="info-table">
          <Box overflowX="auto">
            <Table size="md" className="table-info" variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Bank Name</Th>
                  <Th>Branch</Th>
                  <Th>Account Number</Th>
                  <Th>IFSC Code</Th>
                  <Th>Account Type</Th>
                  <Th>SWIFT Code</Th>
                  <Th>city</Th>
                  <Th>state</Th>

                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map((item, index) => {
                 
                  return (
                    <Tr key={index}>
                      <Td>{indexOfFirstItem + index + 1}</Td>
                      <Td>{item?.bankName}</Td>
                      <Td>{item?.branchName}</Td>
                      <Td>{item?.accountNumber}</Td>
                      <Td>{item?.ifscCode}</Td>
                      <Td>{accountType[item?.accountType]}</Td>
                      <Td>{item?.swiftCode}</Td>

                      <Td>{item?.bankAddress?.city}</Td>
                      <Td>{item?.bankAddress?.state}</Td>

                    </Tr>
                  );
                })}
              </Tbody>
             
            </Table>
          </Box>

          {/* Pagination Component */}
          <Pagination className="justify-content-end" style={{ marginTop: '20px' }}>
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />
            {Array.from({ length: pageCount }, (_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))}
              disabled={currentPage === pageCount}
            />
          </Pagination>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default VendorBankInfo;
