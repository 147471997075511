import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import readXlsxFile from 'read-excel-file';

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaCog } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import Swal from "sweetalert2";
import { createListForSingleOption, createOption } from "../createableSelect";
import PaymentCustomSelect from "components/paymentTypeSelect";
import ExcelSettingsModal from "./excelSettings";
import { fetchAsyncGetExcelFile } from "redux/embrillProfileSlice";
import { fetchAsyncUpdateExcel } from "redux/embrillProfileSlice";
import ExcelJS from 'exceljs';
import { InputGroup } from "react-bootstrap";

const ExcelComponent = ({
  getExcel,
  handleDelete,
  selectedFile,
  handleDeleteOnEdit,
  isEditing,
  role,
  expenseType,
  excelType,
  handleExcelType,
  addNewExcelOnEdit,
  excelData,
  editData
}) => {

    const formOptionsList = useSelector((state) => state.formOptions);
    const [excelTypeOption, setExcelTypeOptions] = useState([]);
    const [excelTypeValue, setExcelTypeValue] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false); 

    const [columnHeadings, setColumnHeadings] = useState([]);
    const [columnValue, setColumnValue] = useState([]);

    

    useEffect(()=>{
      setExcelTypeValue({
        label:excelType,
        value:excelType
      })
      if(excelType){
        // getVendorByCompanyName(excelType)
      }
    },[excelType])
    
    const dispatch = useDispatch();

    

      const createNewFormOption = async(data, type) => {
        await dispatch(
          fetchAsyncAddValueToFormOptions({
            label: data.label,
            value: data.value,
            type,
            expenseType:expenseType
          })
        )
          .then((res) => {
            console.log(res,'ress')
            if (res.payload.value) {
              const setData = {
                label: res.payload.label,
                value: res.payload.value,
              };
              if (type === "excel-type") {
                excelTypeValue(setData);
              }
    
             
              Swal.fire({
                title: "Successfully Added",
    
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                customClass: {
                  container: "sweet_containerImportant",
                  confirmButton: "sweet_confirmbuttonImportant",
                },
              }).then((result) => {});
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Successfully Added",
  
              icon: "success",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              customClass: {
                container: "sweet_containerImportant",
                confirmButton: "sweet_confirmbuttonImportant",
              },
            }).then((result) => {});
          });
      };
    
      const deleteFromOption = (option, type) => {
        const data = formOptionsList.find(
          (item) => item.value === option.value && item.type === type
        );
        dispatch(fetchAsyncDeleteFormOptions(data._id));
      };
      const editFormOption = (option,newValue, type) => {
        const data = formOptionsList.find(
          (item) => item.value === option.value && item.type === type
        );
        
        dispatch(fetchAsyncEditFormOptions({
          id:data._id,
          newData:{
            optionValue:newValue,
            expenseType:expenseType
          }
        })).then((res)=>{
          if (res.payload.value) {
            const setData = {
              label: res.payload.label,
              value: res.payload.value,
            };
            if (type === "excel-type") {
                setExcelTypeValue(setData);
            }
    
            
            dispatch(fetchAsyncGetFormOptions())
          }
        }).catch((err)=>{
          console.log(err)
        })
      };
  const { addToast } = useToasts();
      
  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size < 2 * 1024 * 1024) {
        if (isEditing) {
          addNewExcelOnEdit(e.target.files[0]);
        } else {
          getExcel(e.target.files[0]);
        }
      } else {
        addToast("Please attach file less then 2Mb", { appearance: "warning" });
      }
    }
  };
  
  const getExcelTypeOptions = ()=>{

    const excelTypes = excelData && excelData.map(data => data?.excelType);
    let excelList = createListForSingleOption(formOptionsList, "excel-type");
    const filteredVendors = excelList.filter(vendor => 
      !excelTypes.includes(vendor.value)
    );
  
    setExcelTypeOptions(filteredVendors);
  }
  useEffect(() => {
    getExcelTypeOptions()
  }, [formOptionsList]);
 
 

  const handleOpenModal = () => {
    if (selectedFile.Key) {
      fetchExcelFile(selectedFile.Key); 
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


 


  const fetchExcelFile = async (fileKey) => {
    try {
      const response = await dispatch(fetchAsyncGetExcelFile(fileKey)).unwrap();
     
    const file = new Blob([response.data]);
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      const binaryStr = event.target.result;
 
      if (!binaryStr) {
        addToast("Failed to fetch Excel file", { appearance: "error" });
      }
      try {
        const rows = await readXlsxFile(binaryStr);
        
        if (rows && rows.length > 0) {
          setColumnHeadings(rows[0]);
          setColumnValue(rows[1] || [])
        } else {
          throw new Error("No data found in the Excel file");
        }
 
      } catch (readError) {
        if (readError.message.includes("invalid zip file")) {
          addToast("The file is not a valid Excel file. Please check the file format.", { appearance: "error" });
        } else {
          addToast("Failed to read Excel file: " + readError.message, { appearance: "error" });
        }
      }
    };
    fileReader.onerror = () => {
      console.error("FileReader error:", fileReader.error);
    };
 
    fileReader.readAsArrayBuffer(file);
    } catch (error) {
      addToast("Failed to fetch Excel file", { appearance: "error" });
      console.error("Error fetching Excel file:", error);
    }
  };

const fetchAndUpdateExcelFile = async (fileKey, newHeading, newValues,columnsToRemove) => {
  try {
    // Fetch the existing Excel file
    const response = await dispatch(fetchAsyncGetExcelFile(fileKey)).unwrap();
    const file = new Blob([response.data]);
    
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await file.arrayBuffer());

    const worksheet = workbook.worksheets[0]; 
    // const secondRow = worksheet.getRow(2); 
    // newValues.forEach((value, index) => {
    //   secondRow.getCell(index + 1).value = value; 
    // });


    // Remove specified columns
    if (columnsToRemove && columnsToRemove.length) {
      // Sort indices in descending order to avoid shifting issues
      columnsToRemove.sort((a, b) => b - a);
      columnsToRemove.forEach(index => {
        worksheet.spliceColumns(index + 1, 1); // ExcelJS is 1-based index
      });
    }
    // Update the headings in the first row
    const firstRow = worksheet.getRow(1);
    newHeading.forEach((heading, index) => {
      firstRow.getCell(index + 1).value = heading;
    });

    // Update the values in the second row
    const secondRow = worksheet.getRow(2);
    newValues.forEach((value, index) => {
      secondRow.getCell(index + 1).value = value; 
    });

    // Step 4: Save the updated Excel file
    await saveUpdatedExcel(workbook);
  } catch (error) {
    addToast("Failed to fetch Excel file", { appearance: "error" });
    console.error("Error fetching Excel file:", error);
  }
};

const saveUpdatedExcel = async (workbook) => {
  const excelBuffer = await workbook.xlsx.writeBuffer();
  const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
 
  const formData = new FormData();
  formData.append('file', file);
  formData.append('key', selectedFile.Key); 
  // const url = window.URL.createObjectURL(file);
  // const a = document.createElement('a');
  // a.href = url;
  // a.download = selectedFile.Key; 
  // document.body.appendChild(a);
  // a.click();
  try {
    const res = await dispatch(
      fetchAsyncUpdateExcel({ id: editData._id, formData })
    );

    if (res?.payload) {
      addToast("Excel sheet updated successfully!", { appearance: "success" });
      handleCloseModal();
    }
  } catch (error) {
    handleCloseModal();
    addToast("Failed to upload Excel file. Please try again.", { appearance: "error" });
  }
};

  const updateExcelSheet = (newHeading,newValue,columnsToRemove)=>{
    fetchAndUpdateExcelFile(selectedFile?.Key,newHeading,newValue,columnsToRemove)
  }

  return (
    <>
      <div>
        <Row>
          <Col>
          <Box display="flex" className="input-box">

            <Form.Label>Payment Type</Form.Label>
            <PaymentCustomSelect
              options={excelTypeOption}
              placeHolder={<div>Type or select...</div>}
              onChange={(newValue) => {
                setExcelTypeValue(newValue);
                handleExcelType(newValue?.value);
              }}
              isSearchable
              onCreateOption={(e) => {
                createNewFormOption(createOption(e), "excel-type");
              }}
              disabled
              onDeleteOption={(option) => {
                deleteFromOption(option, "excel-type");
              }}
              onEditOption={(option, newValue) => {
                editFormOption(option, newValue, "excel-type");
              }}
              defaultValue={excelTypeValue}
              role={role}
              selectedFile={selectedFile}
            />
            </Box>
          </Col>
          {selectedFile.Key ? (
            <>
              <Col >
              <Box display="flex" className="input-box">

                <Form.Label>Excel Sheet Attached</Form.Label>
                  <InputGroup style={{width:'190px'}}>
                  <Form.Control
                    type="input"
                    name="excel"
                    placeholder="excel"
                    disabled

                    //   accept="application/pdf"
                    value={selectedFile.Key?.split("/").at(-1)}
                    title={selectedFile.Key?.split("/").at(-1)}
                  />
                  <InputGroup.Text>
                    <FaCog 
                      style={{
                        fontSize: "18px",
                        color: "#000D55",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenModal}
                      />
                  </InputGroup.Text>
                  </InputGroup>
                   
                   
                 <Box className="mx-2">

                  <FaTrash
                      style={{
                        fontSize: "16px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteOnEdit(selectedFile.Key)}
                    />
                 </Box>
                  </Box>
                 
               
              </Col>

              
            </>
          ) : (
            <>
              <Col>
              <Box display="flex" className="input-box">
                <Form.Label>
                  Excel Sheet Attached (<span>*</span>.xlsx)
                </Form.Label>
                <InputGroup style={{width:'250px'}}>
                  <Form.Control
                    type="file"
                    name="excel"
                    placeholder="excel"
                    disabled={!excelTypeValue?.value}
                    onChange={(e) => handleFileChange(e)}
                      accept=".xlsx"
                  />
                  </InputGroup>
                  <div className="mx-2">
                    <FaTrash
                      style={{
                        fontSize: "16px",
                        color: "red",
                        // marginTop: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete()}
                    />
                  </div>
                </Box>
              </Col>
            </>
          )}
        </Row>
      </div>
      <ExcelSettingsModal 
      isOpen={isModalOpen} 
      onClose={handleCloseModal} 
      columnHeadings={columnHeadings} 
      selectedFile={selectedFile} 
      columnValue={columnValue} 
      updateExcel={updateExcelSheet}
      />

    </>
  );
};

export default ExcelComponent;
