import {
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
  
    Flex,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  
  import Form from "react-bootstrap/Form";
  import Col from "react-bootstrap/Col";
  import Row from "react-bootstrap/Row";
  import moment from "moment";
  import { poNumberFormate } from "jsFunctions/helperFunctions";
  import { InputGroup, Tab, Tabs } from "react-bootstrap";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import PaymentInfo from "./components/paymentInfo";
import InvoiceInfo from "./components/invoicceInfo";
import RefundInfo from "./components/refundInfo";
  
  const DataRow = ({ label, value, type }) => {
    return (
      <>
          <Form.Label>{label}</Form.Label>
      {type === 'Amount' 
      ?
      <InputGroup>
        <InputGroup.Text style={{height:'2rem', border:0, background:'lightGray'}}><span className="rupee-symbol">₹</span> </InputGroup.Text>
        <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? formatAmountIndianRupees(value) : " "}
        disabled
      />
      </InputGroup>
      :
      <Form.Control
        type="text"
        value={value !== "undefined" && value !== "" ? value : " "}
        disabled
      />
      }
      </>
    );
  };
  
  const DirectTaxInfoModel = ({ openInfoModal, closeInfoModal, selectedData, role }) => {
    const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
    const [selectedItem, setSelectedItem] = useState(selectedData);
    const dispatch = useDispatch();
  
    const [activeTab, setActiveTab] = useState("information");
    const [historyTab, setHistoryTab] = useState("requested");
  
    useEffect(() => {
      setSelectedItem(selectedData);
    }, [selectedData]);
  
    useEffect(() => {
      if (selectedItem.orderMonth) {
        const dateString = selectedItem.orderMonth;
        const date = moment(dateString);
  
        const month = date.format("MMM");
        const year = date.format("YYYY");
        setGetPoMonth(`${year}-${month}`);
      } else {
        setGetPoMonth("");
      }
      
      if(selectedItem?.referenceResourcePO){
         
        dispatch(fetchAsyncResourcePONum()).then((res) => {
          if (res.payload) {
            const resourcePoNo = [];
            res.payload.forEach((item, index) => {
              resourcePoNo.push({
                _id: item._id,
                poNo: poNumberFormate(item.poNo),
              });
            });
          }
        });
    
    }
      
    }, [selectedItem]);
  
    const [remainingAmount, setRemainingAmount] = useState('')

    useEffect(()=>{
      if(selectedItem.paymentInfo.totalPayAmount){
        if(selectedItem?.paymentInfo?.list) {
          const array = selectedItem?.paymentInfo?.list;
          if(array.length > 0){
    
            const sum = array.reduce((acc, current) => {
              if (current?.amount && current?.amount !== '') {
                return acc + parseFloat(current?.amount);
              } else {
                return acc;
              }
            }, 0);
            const total_pay_amount = parseFloat(selectedItem.paymentInfo.totalPayAmount)
            setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
          }
          else{
            const total_pay_amount = parseFloat(selectedItem.paymentInfo.totalPayAmount) 
            setRemainingAmount(parseFloat(total_pay_amount))
          }
        }
        else{
          const total_pay_amount = parseFloat(selectedItem.paymentInfo.totalPayAmount) 
          setRemainingAmount(parseFloat(total_pay_amount))
        }
  
      }
    },[selectedItem.paymentInfo.totalPayAmount])
   
  
    return (
      <div>
        <Modal
          isOpen={openInfoModal}
          onClose={closeInfoModal}
          size="xl"
          className="modal-main"
        >
          <ModalOverlay />
          <ModalContent className="info-model">
            <div className="fixed-header">
              <ModalHeader className="expense-modal-header">
                <span>Tax Deducted at Source (TDS) Information</span>
              </ModalHeader>
              <ModalCloseButton className="expense-modal-header" />
            </div>
            <ModalBody>
              <Form className="addForm">
                <Tabs
                  id="justify-tab-example1"
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                  className=""
                  style={{ marginTop: "4rem" }}
                  justify
                >
                  <Tab
                    eventKey="information"
                    title="Information"
                    className="main-tab-item"
                  >
                    <div className="card">
                      <h5 className="heading-h5"> Purchase Order Details</h5>
  
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Order Number"
                            value={poNumberFormate(selectedItem.poNo)}
                          />
                        </Col>
                        <Col>
                          <DataRow label="Order Month" value={getPoMonth} />
                        </Col>
                      </Row>
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Order Date"
                            value={
                              selectedItem.orderDate
                                ? moment(selectedItem.orderDate).format(
                                    "DD-MMM-yyyy"
                                  )
                                : ""
                            }
                          />
                        </Col>
                        <Col></Col>
                      </Row>
  
                    
  
                     
                     
                     
                     
                     <Row className="info-row">
                    
                        <Col>
                          <DataRow
                            label="Receiver Name"
                            value={selectedItem?.receiverName}
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Total Tax"
                            value={selectedItem?.totalTax}
                            type='Amount'
                          />
                          
                        </Col>
                     </Row>
                     <Row className="info-row mb-3">
                        <Col>
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled
                            rows={4}
                            value={selectedItem?.remarks}
                          />
                        </Col>
                      </Row>
                     
                     <Divider
                      className="info-divider"
                      orientation="horizontal"
                    />
                   
                     
  
                     
  
                      <Divider
                        className="info-divider mt-3"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Payment Information</h5>
                      <PaymentInfo selectedItem = {selectedItem} remainingAmount={remainingAmount}/>
                      <Divider
                        className="info-divider mt-3"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Refund Amount Information</h5>
                      <RefundInfo selectedItem = {selectedItem}/>
                     
                      <Divider
                        className="info-divider mt-3"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Invoice</h5>
                      <InvoiceInfo selectedItem={selectedItem} />
                    </div>
                  </Tab>
                  {role === "Manager" || role === "Admin" ? (
                    <Tab eventKey="history" title="History">
                      <Form className="addForm addform2">
                        <Tabs
                          id="justify-tab-example3"
                          activeKey={historyTab}
                          onSelect={(k) => setHistoryTab(k)}
                          transition={true}
                          className="mt-0"
                        >
                          <Tab
                            eventKey="requested"
                            className="inner-tab"
                            title="Requested"
                          >
                            <div className="inner-card">
                              <h5 className="heading-h5">Requested Amount Details</h5>
                              <Row className="info-row">
                                <Col className="check-cross-mark mt-2">
                                  <Form.Label>
                                    <span
                                      className={
                                        selectedItem.paymentInfo
                                          ?.payAmountApproveIsRequest
                                          ? "checkmark"
                                          : "crossmark"
                                      }
                                    ></span>{" "}
                                    Is requested amount send for approval?
                                  </Form.Label>
                                  <br />
                                </Col>
                              </Row>
                              <Row className="info-row">
                                <Col>
                                  <DataRow
                                    label="Requested At"
                                    value={
                                      selectedItem.paymentInfo?.requestedAt
                                        ? moment(
                                            selectedItem.paymentInfo?.requestedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Requested By"
                                    value={selectedItem.paymentInfo?.requestedBy}
                                  />
                                </Col>
                              </Row>
  
                              <Divider
                                className="info-divider mt-3"
                                orientation="horizontal"
                              />
                              <h5 className="heading-h5">Last Updated Details</h5>
  
                              <Row className="info-row mb-3">
                                <Col>
                                  <DataRow
                                    label="Last Updated At"
                                    value={
                                      selectedItem.lastUpdatedAt
                                        ? moment(
                                            selectedItem.lastUpdatedAt
                                          ).format("DD-MMM-yyyy hh:mm A")
                                        : ""
                                    }
                                  />
                                </Col>
                                <Col>
                                  <DataRow
                                    label="Last Updated By"
                                    value={selectedItem.updatedBy}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Tab>
  
                          {!selectedItem.paymentInfo?.totalPayAmountIsRejected
                      ?
                      <Tab eventKey="approved" title="Approved">
                      <div className="inner-card">
                        <h5 className="heading-h5">Requested Amount Details</h5>
                        <Row className="info-row">
                          <Col className="check-cross-mark mt-2">
                            <Form.Label>
                              <span
                                className={
                                  selectedItem.paymentInfo
                                    ?.totalPayAmountIsApproved
                                    ? "checkmark"
                                    : "crossmark"
                                }
                              ></span>{" "}
                              Is requested amount is approved ?
                            </Form.Label>
                            <br />
                          </Col>
                        </Row>
                        <Row className="info-row">
                          <Col>
                            <DataRow
                              label="Approved At"
                              value={
                                selectedItem.paymentInfo?.approvedAt
                                  ? moment(
                                      selectedItem.paymentInfo?.approvedAt
                                    ).format("DD-MMM-yyyy hh:mm A")
                                  : ""
                              }
                            />
                          </Col>
                          <Col>
                            <DataRow
                              label="Approved By"
                              value={selectedItem.paymentInfo?.approvedBy}
                            />
                          </Col>
                        </Row>

                        <Divider
                          className="info-divider mt-3"
                          orientation="horizontal"
                        />
                        <h5 className="heading-h5">Last Updated Details</h5>

                        <Row className="info-row mb-3">
                          <Col>
                            <DataRow
                              label="Last Updated At"
                              value={
                                selectedItem.lastUpdatedAt
                                  ? moment(
                                      selectedItem.lastUpdatedAt
                                    ).format("DD-MMM-yyyy hh:mm A")
                                  : ""
                              }
                            />
                          </Col>
                          <Col>
                            <DataRow
                              label="Last Updated By"
                              value={selectedItem.updatedBy}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                    :
                    <Tab eventKey="approved" title="Rejected">
                    <div className="inner-card">
                      <h5 className="heading-h5">Requested Amount Details</h5>
                      <Row className="info-row">
                        <Col className="check-cross-mark mt-2">
                          <Form.Label>
                            <span
                              className={
                                selectedItem.paymentInfo
                                  ?.totalPayAmountIsRejected
                                  ? "checkmark"
                                  : "crossmark"
                              }
                            ></span>{" "}
                            Is requested amount is rejected ?
                          </Form.Label>
                          <br />
                        </Col>
                      </Row>
                      <Row className="info-row">
                        <Col>
                          <DataRow
                            label="Rejected At"
                            value={
                              selectedItem.paymentInfo?.rejectedAt
                                ? moment(
                                    selectedItem.paymentInfo?.rejectedAt
                                  ).format("DD-MMM-yyyy hh:mm A")
                                : ""
                            }
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Rejected By"
                            value={selectedItem.paymentInfo?.rejectedBy}
                          />
                        </Col>
                      </Row>

                      <Divider
                        className="info-divider mt-3"
                        orientation="horizontal"
                      />
                      <h5 className="heading-h5">Last Updated Details</h5>

                      <Row className="info-row mb-3">
                        <Col>
                          <DataRow
                            label="Last Updated At"
                            value={
                              selectedItem.lastUpdatedAt
                                ? moment(
                                    selectedItem.lastUpdatedAt
                                  ).format("DD-MMM-yyyy hh:mm A")
                                : ""
                            }
                          />
                        </Col>
                        <Col>
                          <DataRow
                            label="Last Updated By"
                            value={selectedItem.updatedBy}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                      }
                        </Tabs>
                      </Form>
                    </Tab>
                  ) : (
                    <Tab eventKey="" title="" disabled></Tab>
                  )}
                </Tabs>
              </Form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
  };
  
  export default DirectTaxInfoModel;
  