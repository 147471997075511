import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncGetAllQuotation = createAsyncThunk(
    "/fetchAsyncGetAllQuotation",
    async (id) => {
      try {
        let url = `${API_URL}/quotation/get-all/${id}`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

  export const fetchAsyncDeleteQuotation = createAsyncThunk(
    "/fetchAsyncDeleteQuotation",
    async (data) => {
      try {
        let url = `${API_URL}/quotation/delete/${data.id}`;
        const response = await axiosInstance.post(url,data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.msg);
      }
    }
  );
 



  




