import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddClient = createAsyncThunk(
  "/fetchAsyncAddClient",
  async (data) => {
    try {
      const response = await axiosInstance.post('/client-project/create', data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetClient = createAsyncThunk(
  "/fetchAsyncGetClient",
  async () => {
    try {
      let url = `${API_URL}/client-project/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteClient = createAsyncThunk(
  "/fetchAsyncDeleteClient",
  async (id) => {
    try {
      let url = `${API_URL}/client-project/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUpdateClient = createAsyncThunk(
  "/fetchAsyncUpdateClient",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/client-project/update/${data.id}`, data.clientData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncAddProject = createAsyncThunk(
  "/fetchAsyncAddProject",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/client-project/add-project`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncEditProject = createAsyncThunk(
  "/fetchAsyncEditProject",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/client-project/edit-project`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncDeleteProject = createAsyncThunk(
  "/fetchAsyncDeleteProject",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/client-project/delete-project`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncSearchClientProjectData = createAsyncThunk(
  "/fetchAsyncSearchClientProjectData",
  async (data) => {
    try {
      let url = `${API_URL}/client-project/search`;
      if (data.value) {
        url = url + `?search=${data.value}`;
      }
      if (data.startDate && data.endDate && data.value) {
        url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      if (data.startDate && data.endDate && !data.value) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

const AllClientSlice = createSlice({
  name: "clientData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetClient.pending]: (state) => {},
    [fetchAsyncGetClient.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncSearchClientProjectData.pending]: (state) => {},
    [fetchAsyncSearchClientProjectData.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const getAllClient = (state) => state.clientData;

export default AllClientSlice.reducer;