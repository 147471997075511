/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  Box
} from "@chakra-ui/react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

import { createListForSingleOption } from "./createableSelect";
import { createOption } from "./createableSelect";
import PaymentInfoComponent from "./paymentInfoComponent";
import CustomSelect from "components/CustomSelect";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { fetchAsyncGetPONumDirectTaxExpense } from "redux/directTaxSlice";
import { fetchAsyncAddDirectTaxExpense } from "redux/directTaxSlice";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { fetchAsyncDeleteDirectPdf } from "redux/directTaxSlice";
import { fetchAsyncUploadDirectPdf } from "redux/directTaxSlice";
import { fetchAsyncUpdateDirectTaxExpense } from "redux/directTaxSlice";
import DataChangeModalOnEdit from "components/models/DataChangeModalOnEdit";
import { fetchAsyncAddDirectTaxInvoiceRemark } from "redux/directTaxSlice";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { checkApprovalAmount } from "jsFunctions/helperFunctions";
import { checkTotalApprovedAmountWithSpentAmount } from "jsFunctions/helperFunctions";
import { fetchAsyncRejectDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncApproveDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncSendRequestApprovalDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncSendRequestUpdateApprovalDirectTaxTotalPayAmount } from "redux/directTaxSlice";
import { fetchAsyncDeleteDirectTaxPaymentListItem } from "redux/directTaxSlice";
import { checkSpentAmountisEmpty } from "jsFunctions/helperFunctions";
import { InputGroup, OverlayTrigger, Popover, Tab, Tabs } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import RefundInfoListComponent from "./refundListInfoComponent";
import { fetchAsyncDeleteDirectTaxRefundListItem } from "redux/directTaxSlice";
import QuotationForm from "./seprateFormComponent/quatationForm";
import { FaCheck, FaFileUpload, FaPlusCircle } from "react-icons/fa";
import { fetchAsyncGetAllQuotation } from "redux/quotationSlice";
import DownloadExcelFile from "./DownloadExcel/downloadExcel";

// import { Option } from "antd/es/mentions";

// modal style

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});

const DirectTax = ({
  openModal,
  closeModal,
  isEditForm = false,
  editItem,
  refreshEditData,
  activeList,
  role
}) => {
  const dispatch = useDispatch();
  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
  const [generatedNum, setGeneratedNum] = useState("PR-DT-");
  const [isEdit, setIsEdit] = useState(isEditForm)
  const [editData, setEditData] = useState(editItem)
  const [poPrefix, setPoPrefix] = useState({
    prefix: "PR-DT-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [approveOrRejectRemarks, setApproveOrRejectRemarks] = useState('')

  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [approvalAmount, setApprovalAmount] = useState('');
  const [sendPayAmountRequest, setSendPayAmountRequest] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState()
  const [totalPayAmountIsApproved, setTotalPayAmountIsApproved] = useState(false);
 

  const [multipleAddPaymentInfo, setMultipleAddPaymentInfo] = useState([""]);

  const [multipleInvoiceData, setMultipleInvoiceData] = useState([]);
  const [receiverOption, setReceiverOption] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date());
  const [receiverValue, setReceiverValue] = useState("");
  const [totalTax, setTotalTax] = useState("");
  const [multipleAddRefundInfo, setMultipleAddRefundInfo] = useState([""]);

  const [compareDataModal, setCompareDataModal] = useState(false);
  const [comparingData, setComparingData] = useState(null);
  const { addToast } = useToasts();

  const formOptionsList1 = useSelector((state) => state.formOptions);
  const [formOptionsList, setFormOptionsList] = useState([])
  const [remarksValue, setRemarksValue] = useState('');
  const [errors, setErrors] = useState({})
  const [totalSpentAmount, setTotalSpentAmount] = useState(0)
  const [totalRefundAmount, setTotalRefundAmount] = useState(0)

  const [openQuotation, setOpenQuotation] = useState(false)

  const [quotationData, setQuotationData] = useState({
    remarks:"",
    amount:"",
    files:""
  })

  const [allQuotation,setAllQuotation] = useState([])

  const finalRef = useRef(null);
  const closeComparedDataModal = () => {
    setCompareDataModal(false);

    setComparingData(null);
  };

  const currUser = useSelector((state) => state.auth.currUser);

  const initialValues = {
    poNo: "",
    orderMonth: "",
    orderDate: "",
    receiverName: "",
    remarks: "",
    totalTax: "",
    payee: 0,
    paymentAmount: 0,
    approvalAmount: 0,
    bank: "",
    referance: "",
    date: null,
    isRefund:
      editData?.refundInfo?.isRefund|| false,
    invoice: "",
  };

  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === 'directTax')
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (!receiverValue || !totalTax) {
        setErrors({
          ...errors,
          receiverValue:'Receiver value is required!',
          totalTax:'Total tax is required!'
        })
        return;
      }

     
     
      const checkApproval = checkApprovalAmount(
        approvalAmount,
      );
      if (!checkApproval.status) {
        setErrors({
          ...errors,
          approvalAmount:checkApproval.msg
        })         
        return;
      }
      const filteredMultipleAddPaymentInfo = multipleAddPaymentInfo.filter((item) => item?.amount);

      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        filteredMultipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })
        return;
      }
      let formData = new FormData();
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", todayDate);
      formData.append("receiverName", receiverValue.value);
      formData.append("remarks", remarksValue);
     
      formData.append("isRefund", values.isRefund);
      const filteredMultipleAddRefundInfoPay = filteredMultipleAddPaymentInfo.filter((item) => item?.amount);
      formData.append("refundInfo", JSON.stringify(filteredMultipleAddRefundInfoPay));

      formData.append("totalPaymentAmount", totalPaymentAmount);
      const filteredMultipleAddPaymentInfoPay = multipleAddPaymentInfo.map((item)=>{
        if(item?.invoice){
          return {
            ...item,
            invoice: item?.invoice.filter(entry => 'Key' in entry)
          }
        }
        else{
          return item
        }
      });
      formData.append("payInfo", JSON.stringify(filteredMultipleAddPaymentInfoPay));
      formData.append("totalTax", totalTax);
      formData.append("approvalAmount", approvalAmount);

    if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
        formData.append('quotation_remarks', quotationData?.remarks);
       formData.append('quotation_amount', quotationData?.amount);
       Array.from(quotationData?.files).forEach((file) => {
           formData.append('quotation_files', file);
       });

     }
      if (isEdit) {
        setComparingData(formData);
      } else {
        multipleInvoiceData.forEach((element, i) => {
          formData.append(`file${i}`, element);
        });
        dispatch(fetchAsyncAddDirectTaxExpense(formData))
          .then((res) => {
            if (res?.payload) {
              closeModal();
              resetForm();
              addToast(`Expense successfully created!`, { appearance: "success" });
             
            } 
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
  });



  const handleDeleteInvoiceOnEdit = (key,listIndex) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          key: key,
          listIndex:listIndex

        };
        dispatch(fetchAsyncDeleteDirectPdf(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)

          refreshEditData();
        });
      }
    });
  };
  // f
  // form list option
  useEffect(() => {
    let receiverList = createListForSingleOption(formOptionsList, "receiver");
   
    setReceiverOption(receiverList);

  }, [formOptionsList]);
  // create new function
  const createNewFormOption = (data, type) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        expenseType:'directTax'
      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
          };
          if (type === "receiver") {
            setReceiverValue(setData);
          }
         
          Swal.fire({
            title: "Successfully Added",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      });
  };
  // SET PO NUMBER





  useEffect(() => {
    setGeneratedNum(poNumberFormate(poPrefix));
  }, [poPrefix]);

  useEffect(() => {
    if (!isEdit && getPoMonth) {
      const dataSplit = getPoMonth.split("-");
      dispatch(
        fetchAsyncGetPONumDirectTaxExpense({
          month: dataSplit[1],
          year: dataSplit[0],
        })
      )
        .then((res) => {
          if (res.payload?.[0]?.poNo) {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: res.payload?.[0]?.poNo.number + 1,
              };
            });
          } else {
            setPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: dataSplit[1],
                year: dataSplit[0],
                number: 1,
              };
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getPoMonth]);
  const deleteFromOption = (option, type) => {
    let data = {};
    if(type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
    dispatch(fetchAsyncDeleteFormOptions(data._id));
  };

  const editFromOption = (option,newValue, type) => {
    let data = {};
    if(type === 'company-type'){
      data = formOptionsList1.find(
        (item) => item.value === option.value && item.type === type
      );
    }
    else{

      data = formOptionsList.find(
        (item) => item.value === option.value && item.type === type
      );
    
    }
    
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:'directTax'

      }
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
        };
       
       

        if (type === "receiver") {
          setReceiverValue(setData);
        }
       
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };
  const handleInvoiceChange = (data) => {
    // setOpenInvoice(true);
    // if (multipleInvoiceData.length < 10) {
    //   setMultipleInvoiceData([
    //     ...multipleInvoiceData,
    //     { data: "", invNumber: data.data },
    //   ]);
    // }

    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === data?.id) {

        let invoice = []

        if(item?.invoice){
           if (item?.invoice?.length < 5) {
          invoice = [
            ...item?.invoice,
            { data: "", invNumber: data.data }
          ]
        }
        else{
          invoice = item?.invoice
        }
        }
        else{
          invoice = [
            { data: "", invNumber: data.data }
          ]
        }

        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handelDeleteInvoice = (index,id) => {
    // const updateList = multipleInvoiceData.filter((item, i) => i !== index);
    // setMultipleInvoiceData([...updateList]);
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === id) {

        let invoice = []
        if(item?.invoice){

          invoice = item?.invoice.filter((item, i) => i !== index)
        }
        
        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handleUpdateInvoiceData = (invoiceData, index) => {
    const updateList = multipleInvoiceData.map((item, i) => {
      if (i === index) {
        return invoiceData;
      } else return item;
    });
    setMultipleInvoiceData([...updateList]);
  };

  const addNewInvoiceOnEdit = (file, index,listIndex) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (editData) {
          const lastInvoiceKey =
            editData.invoice?.length > 0 &&
            editData.invoice[editData.invoice.length - 1].Key;
          let number = index + 1;
          if (lastInvoiceKey) {
            number = lastInvoiceKey.split("PAY_")[1]?.split(".pdf")[0];
            number = parseInt(number) + 1;
          }
          let formData = new FormData();
          formData.append("poNo", JSON.stringify(editData.poNo));
          formData.append("number", number);
          
          formData.append("file", file);
          formData.append("receiverName", editData?.receiverName);
          formData.append("listIndex", listIndex);
          formData.append("index", index);
          const listItem = multipleAddPaymentInfo[listIndex];
          formData.append("listItem", JSON.stringify(listItem));
          dispatch(
            fetchAsyncUploadDirectPdf({ id: editData._id, formData })
          ).then((res) => {
            setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)

            refreshEditData();
          });
        }
      }
    });
  };
  // payment info function
  const handleMultiplePayInfo = (data) => {
    setMultipleAddPaymentInfo([
      ...multipleAddPaymentInfo,
      { data: "", paydata: data.data,invoice:[] },
    ]);
  };

  
  const handleUpdateMultiplePayData = (payData, index) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };

 
  // 
  // Changes on Edit
  const editFormPrefiledData = (formOptionsList, editData) => {
    const resourceData = getOptionDataOnEdit(
      formOptionsList,
      editData,
      "receiverName",
      "receiver"
    );
    if (resourceData) {
      setReceiverValue({
        label: resourceData.label,
        value: resourceData.value,
      });
    }
   
  };
  const getOptionDataOnEdit = (formOptionsList, editData, value, type) => {
    const data = formOptionsList.find(
      (item) => item.value === editData?.[value] && item.type === type
    );
    return data;
  };

  const handleMultipleRefundInfo = (data) => {
    setMultipleAddRefundInfo([
      ...multipleAddRefundInfo,
      { data: "", paydata: data.data },
    ]);
  };
  const handleUpdateMultipleRefundData = (payData, index) => {
    const updateList = multipleAddRefundInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddRefundInfo([...updateList]);
  };

  const handleQuotationData = (data)=>{
    setQuotationData(data)
  }
  const handleCloseQuotation = ()=>{
    setOpenQuotation(!openQuotation)
    if (isEdit && editData) {
    getAllQuotation(editData?._id)
    }
  }

  const getAllQuotation = async(_id)=>{
    await dispatch(fetchAsyncGetAllQuotation(_id)).then((res)=>{

        if(res?.payload){
            const quotations = res.payload?.quotations;
            setAllQuotation(quotations)
        }
        }).catch((err)=>{
          console.log(err)
        })
}
  useEffect(() => {
    // fill data in form on edit
    // console.log("is directtax edit data", editData);
    if (isEdit && editData) {
      getAllQuotation(editData?._id)

      setPoPrefix(editData.poNo);
      setTodayDate(new Date(editData.orderDate));
      setSelectedDate(new Date(editData.orderDate));

      setGetPoMonth(
        `${editData.poNo.year}-${
          editData.poNo.month <= 9
            ? "0" + editData.poNo.month
            : editData.poNo.month
        }`
      );
      if (formOptionsList?.length > 0 || formOptionsList1?.length > 0) {
        editFormPrefiledData(formOptionsList, editData);
      }
      if (editData.paymentInfo?.totalPayAmount) {
        setTotalPaymentAmount(editData.paymentInfo?.totalPayAmount);
      }
      if (editData.paymentInfo?.payAmountApproveIsRequest) {
        setSendPayAmountRequest(true)
      }
      if (editData.refundInfo?.list && editData.refundInfo?.list.length > 0) {
        setMultipleAddRefundInfo(editData.refundInfo?.list);
      }
       if(editData?.paymentInfo?.totalPayAmountIsApproved){
        setTotalPayAmountIsApproved(true)
      }
      if (editData?.remarks) {
        setRemarksValue(editData?.remarks)
      }
      if (editData?.totalTax) {
        setTotalTax(editData?.totalTax)
      }
      if (editData?.approveRejectRemarks) {
        setApproveOrRejectRemarks(editData?.approveRejectRemarks)
      }
     
      if (editData.paymentInfo?.approvalAmount) {
        setApprovalAmount(editData.paymentInfo?.approvalAmount);
      }
      
      if (editData.paymentInfo?.list && editData.paymentInfo?.list.length > 0) {
        setMultipleAddPaymentInfo(editData.paymentInfo?.list);
      }
     
     
     
      if (editData.invoice?.length > 0) {
        setMultipleInvoiceData([...editData.invoice]);
      } else {
        setMultipleInvoiceData([]);
      }
    }
  }, [isEdit, editData, formOptionsList]);



  const submitUpdateData = (formData, editData) => {
    dispatch(
      fetchAsyncUpdateDirectTaxExpense({
        id: editData._id,
        data: formData,
      })
    )
      .then((res) => {
        if (res.payload) {
          addToast(`Expense ${generatedNum} updated successfully!`, { appearance: "success" });
        }
        closeModal();
        setComparingData(null);
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        addToast("Error", { appearance: "error" });
      });
  };

  // add Remark for Invoice
  const addRemarkForInvoice = (value, item,listIndex,index) => {
    dispatch(
      fetchAsyncAddDirectTaxInvoiceRemark({
        id: editData._id,
        remarkData: { key: item.Key, remark: value, listIndex, index },
      })
    ).then((res) => {
      setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)
      refreshEditData();
    });
  };

  const handleAmountApproveBtn = (values)=>{   
    if(!isEdit){
  
      if (!receiverValue || !totalTax) {
        setErrors({
          ...errors,
          receiverValue:'Receiver value is required!',
          totalTax:'Total tax is required!'
        })       
         return;
      }
    }
     
  

    const checkApproval = checkApprovalAmount(
      approvalAmount,
    );
    if (!checkApproval.status) {
      setErrors({
        ...errors,
        approvalAmount:checkApproval.msg
      }) 
      return;
    }
  
    if(approvalAmount <= totalPaymentAmount && remainingAmount >= 0){
  
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        totalPaymentAmount,
        multipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })   
        return;
      }
    }
    if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
      return;
    }
      let formData = new FormData();
      
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
     
      formData.append("receiverName", receiverValue.value);
     
      formData.append("remarks", remarksValue);

      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("approvalAmount", approvalAmount);
     
      formData.append("totalTax", totalTax);
      formData.append("isRefund", values.isRefund);

      if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
        formData.append('quotation_remarks', quotationData?.remarks);
       formData.append('quotation_amount', quotationData?.amount);
       Array.from(quotationData?.files).forEach((file) => {
           formData.append('quotation_files', file);
       });

     }
      if(isEdit && editData){
        dispatch(fetchAsyncSendRequestUpdateApprovalDirectTaxTotalPayAmount
          ({ 
          id: editData._id,
          data:formData
         })).then(
          (res) => {
            if (res.payload && res.payload.updatedData) {
              setIsEdit(true)
              setEditData(res.payload.updatedData)
              setSendPayAmountRequest(true)
              addToast(`Resend updated request for approval for expense ${generatedNum} !`, { appearance: "success" });
              closeModal();
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
  
          
          }
        );
      }
      else{
  
        dispatch(fetchAsyncSendRequestApprovalDirectTaxTotalPayAmount(formData)).then((res) => {
            if (res.payload) {
              addToast(`Send request for approval for expense ${generatedNum} !`, { appearance: "success" });
              setIsEdit(true)
              setEditData(res.payload)
              setSendPayAmountRequest(true)
              closeModal();
  
            
            }
            else{
              addToast("Error", { appearance: "error" });
  
            }
          });
      }
      
      
      }
  const handleTotalPayAmountIsApproved = ()=>{
    const _id = editData._id;
    let formData = new FormData();
    formData.append("poNo", JSON.stringify(poPrefix));
    formData.append("orderMonth", getPoMonth);
    formData.append("orderDate", selectedDate);
   
    formData.append("receiverName", receiverValue.value);
   
    formData.append("remarks", remarksValue);

    formData.append("totalPaymentAmount", totalPaymentAmount);
    formData.append("approvalAmount", approvalAmount);
    formData.append("totalTax", totalTax);
    formData.append("approveRejectRemarks", approveOrRejectRemarks);

    if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
      formData.append('quotation_remarks', quotationData?.remarks);
     formData.append('quotation_amount', quotationData?.amount);
     Array.from(quotationData?.files).forEach((file) => {
         formData.append('quotation_files', file);
     });

   }

    if(!editData.paymentInfo?.totalPayAmountIsApproved){
      Swal.fire({
        title: `Are You Sure? You Want To Approve Amount <span style="color: orange"><span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)}</span> For <span style="color:orange">${generatedNum}</span>.`,
        text: `Please confirm to proceed with the approval.`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncApproveDirectTaxTotalPayAmount({ 
            id: _id,
            data:formData
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData) {
                addToast(`Requested Amount for ${generatedNum} has been successfully approved.`, { appearance: "success" });
                closeModal();
              }
              
            }
          );
        }
      });
     
    }
  
  }

  const handleTotalPayAmountIsRejected = ()=>{
    const _id = editData._id;
    
    if(!editData.paymentInfo?.totalPayAmountIsApproved &&  !editData.paymentInfo?.totalPayAmountIsRejected){
      Swal.fire({
        title: `Are You Sure? You Want To Reject Amount <span style='color:orange'> <span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)} </span> For <span style="color:orange">${generatedNum}.</span>`,
        text: `Please confirm to reject. This action cannot be undone.`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncRejectDirectTaxTotalPayAmount({ 
            id: _id,
            data:{totalPayAmountIsRejected:true,approveRejectRemarks:approveOrRejectRemarks}
           })).then(
            (res) => {
              if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsRejected) {
                addToast(`Requested Amount for ${generatedNum} has been successfully rejected.`, { appearance: "success" });
                closeModal();
              }
              
            }
          );
        }
      });
     
    }
  
  }
  useEffect(()=>{
    if(multipleAddRefundInfo) {
      const array = multipleAddRefundInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalRefundAmount(parseFloat(sum))
        
      }
    }
    
  },[multipleAddRefundInfo])

  useEffect(()=>{
    if(multipleAddPaymentInfo) {
      const array = multipleAddPaymentInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalSpentAmount(parseFloat(sum))

        setRemainingAmount(parseFloat(totalPaymentAmount) - parseFloat(sum))
      }



    }
  },[multipleAddPaymentInfo])

  useEffect(()=>{
    if(totalPaymentAmount && !remainingAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[totalPaymentAmount])
  
  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        closeModal();
      }
    });
  };


  const deleteMultipleListItem = (listIndex)=>{
    Swal.fire({
      title: "Are you sure? Delete Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteDirectTaxPaymentListItem(sendData)).then((res) => {
          setMultipleAddPaymentInfo(res?.payload?.directTaxExpense?.paymentInfo?.list)
          refreshEditData();
        });
      }
    });
}

const deleteMultipleRefundListItem = (listIndex)=>{
  Swal.fire({
    title: "Are you sure? Delete Refund Amount Details Permanently.",
    text: ``,
    icon: "warning",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete",
    showCancelButton: true,
    customClass: {
      container: "sweet_containerImportant",
      confirmButton: "sweet_confirmbuttonImportant",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const sendData = {
        id: editData._id,
        listIndex:listIndex
      };
      dispatch(fetchAsyncDeleteDirectTaxRefundListItem(sendData)).then((res) => {
        setMultipleAddRefundInfo(res?.payload?.directTaxExpense?.refundInfo?.list)
        refreshEditData();
      });
    }
  });
}
const handleRemarksChange = (e)=>{
  const value = e?.target?.value;
  const encoder = new TextEncoder(); // Default is 'utf-8'
  const byteLength = encoder.encode(value).length;
  if(byteLength <=128){

    setRemarksValue(value)
    setErrors({
      ...errors,
      remarks:''
    })
  }
  else{
    setErrors({
      ...errors,
      remarks:'Remarks must be within the 128 byte limit!'
    })
  }
}

const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      Amount Spent: ${200}
    </Popover.Body>
  </Popover>
);
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openModal}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          top="0" // Position it at the top
          margin="0 auto" // Center it horizontally
          bottom="0"
          className="add-form-modal-content"
        >
          <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
              {isEdit && "Edit"} Direct Tax - Expense
            </ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            <Form className="addForm" onSubmit={formik.handleSubmit}>
              <div className="form-card">
                <h5>Direct Tax Details</h5>
                <Row className="mt-2">
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Number <span className="span-star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="No"
                        value={generatedNum && generatedNum}
                        disabled
                      />
                    </Box>
                  </Col>

                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order - Month <span className="span-star">*</span>
                      </Form.Label>
                      <DatePicker
                        className="monthDate"
                        dateFormat="MM-yyyy"
                        showMonthYearPicker
                        name="orderMonth"
                        onChange={(e) => {
                          const dateTime = moment(e).format("yyyy-MM");
                          setGetPoMonth(dateTime);
                        }}
                        disabled={isEdit}
                        // defaultValue={new Date()}
                        selected={
                          getPoMonth ? new Date(moment(getPoMonth)) : ""
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Date <span className="span-star">*</span>
                      </Form.Label>
                      <DatePicker
                        name="orderDate"
                        className="monthDate"
                        dateFormat="dd-MMM-yyyy"
                        // disabled={isEdit}
                        onChange={(date) => {
                          setSelectedDate(date);
                        }}
                        selected={selectedDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                  <Col className="custom-select"></Col>
                </Row>

                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Receiver Name <span className="span-star">*</span>
                      </Form.Label>

                      <CustomSelect
                        options={receiverOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => setReceiverValue(newValue)}
                        isSearchable
                        onCreateOption={(e) => {
                          createNewFormOption(createOption(e), "receiver");
                        }}
                        onDeleteOption={(option) => {
                          deleteFromOption(option, "receiver");
                        }}
                        onEditOption={(option, newValue) => {
                          editFromOption(option, newValue, "receiver");
                        }}
                        defaultValue={receiverValue}
                        role={role}
                      />
                    </Box>
                    {errors?.receiverValue && !receiverValue && (
                      <p className="form_error">{errors?.receiverValue}</p>
                    )}
                  </Col>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Total Tax <span className="span-star">*</span>
                      </Form.Label>

                      <InputGroup>
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>

                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          name="totalTax"
                          placeholder="Total Tax"
                          value={totalTax || ""}
                          onValueChange={(e) => {
                            setTotalTax(e.value);
                          }}
                          isInvalid={errors?.totalTax && !totalTax}
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </InputGroup>
                    </Box>
                    {errors?.totalTax && !totalTax && (
                      <p className="form_error">{errors?.totalTax}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                  <Box display="flex" className="input-box">

                    <Form.Label>Remarks </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="remarks"
                      placeholder="Type Here..."
                      value={remarksValue}
                      // defaultValue={remarksValue}
                      onChange={(e) => handleRemarksChange(e)}
                      isInvalid={!!errors?.remarks}
                    />
                  </Box>
                  </Col>
                </Row>
              </div>
              <div className="form-card mt-3">
                <h5>Payment Information</h5>
                <Row >
                  <Col>
                  <Box display="flex" className="input-box">
                  <Form.Label>
                        Requested Amount <span className="span-star">*</span>
                      </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <span className="rupee-symbol">₹</span>{" "}
                      </InputGroup.Text>

                      <CurrencyFormat
                        customInput={Form.Control}
                        type="text"
                        disabled={
                          sendPayAmountRequest &&
                          !totalPayAmountIsApproved &&
                          (role === "Admin" || role === "Manager") &&
                          currUser.name !== editData?.paymentInfo?.requestedBy
                        }
                        name="approvalAmount"
                        placeholder="Requested Amount"
                        value={approvalAmount || ""}
                        onValueChange={(e) => {
                          setApprovalAmount(e.value);
                        }}
                        isInvalid={
                          (remainingAmount < 0 &&
                            approvalAmount <= totalPaymentAmount) ||
                          (!approvalAmount && errors?.approvalAmount)
                        }
                        thousandSeparator={true}
                        // prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                      <InputGroup.Text
                        className="quotation-upload"
                        onClick={() => {
                          approvalAmount && setOpenQuotation(!openQuotation);
                        }}
                      >
                        <Tooltip
                          label="Upload Quotation"
                          fontSize="md"
                          borderRadius="2xl"
                        >
                          <div
                            disabled={!approvalAmount}
                            className="position-relative"
                          >
                            <FaFileUpload size={20} />
                            <span className="position-absolute top-0 start-100 translate-middle badge  bg-primary text-white">
                              {quotationData?.files || quotationData?.remarks
                                ? allQuotation?.length + 1
                                : allQuotation?.length || 0}
                            </span>
                          </div>
                        </Tooltip>
                      </InputGroup.Text>
                    </InputGroup>
                    </Box>
                    {((remainingAmount < 0 &&
                      approvalAmount <= totalPaymentAmount) ||
                      !approvalAmount) && (
                      <p className="form_error">{errors?.approvalAmount}</p>
                    )}
                  </Col>
                  {totalPayAmountIsApproved ? (
                    <Col>
                     <Box display="flex" className="input-box">

                      <Form.Label>Approved Amount</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>

                        <CurrencyFormat
                          customInput={Form.Control}
                          type="text"
                          disabled
                          name="totalPayAmount"
                          placeholder="Total Amount"
                          value={totalPaymentAmount ? totalPaymentAmount : 0}
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </InputGroup>
                     </Box>
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                {totalPayAmountIsApproved && (
                  <>
                    <Row>
                      <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>Net Payable Amount</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <span className="rupee-symbol">₹</span>{" "}
                          </InputGroup.Text>

                          <CurrencyFormat
                            customInput={Form.Control}
                            type="text"
                            disabled
                            name="Net Payable Amount"
                            value={remainingAmount ? remainingAmount : 0}
                            thousandSeparator={true}
                            decimalScale={2}
                            isInvalid={
                              remainingAmount < 0 &&
                              approvalAmount <= totalPaymentAmount
                            }
                            fixedDecimalScale={true}
                          />
                        </InputGroup>
                        </Box>
                        {remainingAmount < 0 &&
                          approvalAmount <= totalPaymentAmount && (
                            <p className="form_error">
                              Net payable amount cannot be negative. Please
                              revise the request with an increased requested
                              amount and resubmit for approval.
                            </p>
                          )}
                      </Col>
                      <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>Total Spent Amount</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <span className="rupee-symbol">₹</span>{" "}
                          </InputGroup.Text>
                          <CurrencyFormat
                            customInput={Form.Control}
                            type="text"
                            disabled
                            name="totalPayAmount"
                            placeholder="Total Amount"
                            value={
                              parseFloat(totalSpentAmount) -
                              parseFloat(totalRefundAmount)
                            }
                            thousandSeparator={true}
                            // prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </InputGroup>
                        </Box>
                      </Col>
                    </Row>
                    
                  </>
                )}

               
              </div>
              {totalPayAmountIsApproved && (
                <div>
                  <Tabs
                    defaultActiveKey="spent"
                    transition={false}
                    id="noanim-tab-example"
                    className="add-form-tabs"
                  >
                    <Tab eventKey="spent" title="Spent Amount" className="add-form-tab mx-0">
                    <div className="form-card">
                        
                    {totalPayAmountIsApproved &&
                  multipleAddPaymentInfo.map((item, index) => {
                    return (
                      <>
                        <PaymentInfoComponent
                          getData={(data) => {
                            handleUpdateMultiplePayData(data, index);
                          }}
                          autoCount={index}
                          paymentData={item}
                          editData={editData}
                          refreshEditData={refreshEditData}
                          isEdit={isEdit}
                          handleInvoiceChange={(item) =>
                            handleInvoiceChange(item)
                          }
                          multipleInvoiceData={
                            item?.invoice ? item?.invoice : [""]
                          }
                          handleUpdateInvoiceData={(item, index) =>
                            handleUpdateInvoiceData(item, index)
                          }
                          handelDeleteInvoice={(index, id) =>
                            handelDeleteInvoice(index, id)
                          }
                          handleDeleteInvoiceOnEdit={(key, listIndex) =>
                            handleDeleteInvoiceOnEdit(key, listIndex)
                          }
                          addNewInvoiceOnEdit={(file, index, listIndex) =>
                            addNewInvoiceOnEdit(file, index, listIndex)
                          }
                          addRemarkForInvoice={(
                            value,
                            item,
                            listIndex,
                            index
                          ) =>
                            addRemarkForInvoice(value, item, listIndex, index)
                          }
                          expenseType="directTax"
                          deleteMultipleListItem={() =>
                            deleteMultipleListItem(index)
                          }
                          role={role}
                        />
                      </>
                    );
                  })}
                  <Box display="flex" alignItems="center" onClick={() => {
                              handleMultiplePayInfo(
                                multipleAddPaymentInfo.length + 1
                              );
                            }}>
                          <h5>Add Spent Amount</h5>

                          <Button
                            className="add-project mt-2 mx-1"
                            minWidth={0}
                            
                          >
                            <FaPlusCircle
                              className="add-project-icon"
                              size={15}
                            />
                          </Button>
                        </Box>
                    </div>
                    </Tab>
                    {(role === 'Admin' || role === 'Manager') &&
                    <Tab
                      eventKey="excel"
                      title="Download Excel"
                      className="add-form-tab"
                    >
                      <DownloadExcelFile
                        prNo={generatedNum}
                        payAmount={totalPaymentAmount}
                        role={role}
                      />
                    </Tab>
                    }
                    {(role === 'Admin' || role === 'Manager') &&
                    <Tab
                      eventKey="refund"
                      title={
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                          <div className="d-flex align-items-center">
                          Refund
                            {formik.values.isRefund &&
                            <FaCheck size={12}  className="tab-icon"/> 
                            }
                          </div>
                        </OverlayTrigger>
                      }                    
                      className="add-form-tab"
                    >
                  <div className="form-card">
                    <Box display="flex" alignItems="center">
                        <h5>Refund Information</h5>
                          <Form.Check
                            className="mx-1 mt-2"
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.isRefund}
                            {...formik.getFieldProps("isRefund")}
                            />
                            
                        </Box>
                     
                      {formik.values.isRefund && (
                        <Box>
                         

                          {multipleAddRefundInfo &&
                            multipleAddRefundInfo?.map((item, index) => {
                              return (
                                <RefundInfoListComponent
                                  key={`refundInfo-${index}`}
                                  getData={(data) => {
                                    handleUpdateMultipleRefundData(data, index);
                                  }}
                                  autoCount={index}
                                  paymentData={item}
                                  editData={editData}
                                  refreshEditData={refreshEditData}
                                  deleteMultipleListItem={() =>
                                    deleteMultipleRefundListItem(index)
                                  }
                                  expenseType="directTax"
                                  role={role}
                                />
                              );
                            })}
                        </Box>
                      )}
                      {formik.values.isRefund && (
                       <Box display="flex" alignItems="center" onClick={() => {
                        handleMultipleRefundInfo(
                          multipleAddRefundInfo.length + 1
                        );
                      }}>
                        <h5>Add Refund</h5>
                          
                             <Button
                                className="add-project mt-2"
                                
                                disabled={!formik.values.isRefund}
                              >
                                <FaPlusCircle
                                  className="add-project-icon"
                                  size={15}
                                />
                              </Button>
                        </Box>
                      )}
                    </div>
                    </Tab>
                    }
                  </Tabs>
                  </div>
              )}
              
              {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest && (
                  <>
                    <div className="form-card">
                      <h5>Approve OR Reject Remark</h5>

                      <Row>
                        <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Type Here..."
                            value={approveOrRejectRemarks}
                            onChange={(e) =>
                              setApproveOrRejectRemarks(e.target.value)
                            }
                            isInvalid={
                              !approveOrRejectRemarks &&
                              errors?.approveOrRejectRemarks
                            }
                          />
                        </Box>
                          {!approveOrRejectRemarks && (
                            <p className="form_error">
                              {errors?.approveOrRejectRemarks}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              <Row className="mt-4">
                {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest ? (
                  <ModalFooter
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    {!totalPayAmountIsApproved && (
                      <div className="d-flex">
                        <Button
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => {
                            handleTotalPayAmountIsApproved();
                          }}
                          className="submit-btn me-2"
                        >
                          Approve
                        </Button>

                        <Button
                          className="submit-btn"
                          variant="brand"
                          disabled={totalPaymentAmount === null ? true : false}
                          onClick={() => handleTotalPayAmountIsRejected()}
                          style={{ background: "#191063" }}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  </ModalFooter>
                ) : (
                  <ModalFooter>
                    {(!totalPayAmountIsApproved && !sendPayAmountRequest) ||
                    remainingAmount < 0 ||
                    parseFloat(approvalAmount) !==
                      parseFloat(totalPaymentAmount) ? (
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleAmountApproveBtn(formik.values);
                        }}
                        className="submit-btn"
                      >
                        Send Request
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="submit-btn"
                        variant="brand"
                        style={{ background: "#191063" }}
                        onClick={() => setCompareDataModal(true)}
                      >
                        Submit
                      </Button>
                    )}
                  </ModalFooter>
                )}
              </Row>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {compareDataModal && comparingData && (
        <DataChangeModalOnEdit
          openModal={compareDataModal}
          closeModal={closeComparedDataModal}
          editData={editData}
          currentData={comparingData}
          isConfirmed={() => {
            submitUpdateData(comparingData, editData);
          }}
          type={"direct-tax"}
        />
      )}
      {openQuotation && (
        <QuotationForm
          isOpen={openQuotation}
          closeModal={() => {
            handleCloseQuotation();
          }}
          requestedAmount={approvalAmount}
          handleData={(data) => handleQuotationData(data)}
          uploadedData={quotationData}
          expenseId={editData?._id || ""}
          allQuotation={allQuotation}
        />
      )}
    </>
  );
};

export default DirectTax;
