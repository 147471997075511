/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import { Box, Flex, Icon,Text, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import React, { useEffect, useState } from "react";
import { SiVirustotal } from "react-icons/si";
import { useDispatch } from "react-redux";
import { fetchAsyncGetDirectTaxTotalPaymentAmount } from "redux/directTaxSlice";
import { fetchAsyncGetExternalTotalPaymentAmount } from "redux/externalSlice";
import { fetchAsyncGetInternalTotalPaymentAmount } from "redux/internalSlice";
import { fetchAsyncGetResourceTotalPaymentAmount } from "redux/resourceSlice";
import { fetchAsyncGetTDSTotalPaymentAmount } from "redux/tdsSlice";
import { fetchAsyncGetInternalExpenseBarChart } from "redux/userSlice";

// icons

import { useToasts } from "react-toast-notifications";
import WeeklyExpense from "./components/WeeklyExpense";
import LineCard from "./components/LineCard";
import { fetchAsyncGetExternalExpenseBarChart } from "redux/externalSlice";
import { fetchAsyncGetResourceExpenseBarChart } from "redux/resourceSlice";
import { fetchAsyncGetTDSExpenseBarChart } from "redux/tdsSlice";
import { fetchAsyncGetDirectTaxExpenseBarChart } from "redux/directTaxSlice";

export default function ExpenseReports({name, type, icon,heading}) {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const dispatch = useDispatch();
  const [expensTotalPayAmount, setExpenseTotalPayAmount] =
    useState(null);
  
  
  const [optionBarChart, setOptionsBarChart] = useState([]);
  const [valuesBarChart, setValuesBarChart] = useState([]);

  const [selectExpYear, setSelectExpYear] = useState("2024-2025");
  const [selectExpType, setSelectExpType] = useState(type);

  const [selectFilterData, setSelectFilterData] = useState({
    viewType:"monthly",
    office:"",
    majorType:"",
    minorType:"",
    minorSubCategory:"",
    merchantName:"",
    clientName:"",
    resource:"",
    referenceResourcePO:"",
    receiverName:"",
    projectName:""
  })

  const handleFilterData = (e)=>{
    const name = e.target.name;
    const value = e.target.value;

    if(name === "clientName" && value === ""){
      setSelectFilterData({
        ...selectFilterData,
        projectName:""
      })
    }
    if (name === 'majorType' && selectFilterData[name] !== value) {
      setSelectFilterData({
        ...selectFilterData,
        majorType: value,
        minorType: "",
        minorSubCategory: ""
      });
    } else if (name === 'minorType' && selectFilterData[name] !== value) {
      setSelectFilterData({
        ...selectFilterData,
        minorType: value,
        minorSubCategory: ""
      });
    } else {
      setSelectFilterData({
        ...selectFilterData,
        [name]: value
      });
    }
   
  }

  const { addToast } = useToasts();

  useEffect(() => {
    switch (type) {
      case "internal":
        dispatch(fetchAsyncGetInternalTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
      case "external":
        dispatch(fetchAsyncGetExternalTotalPaymentAmount())
          .then((res) => {
            if (res.payload) {
              setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "resource":
        dispatch(fetchAsyncGetResourceTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
      case "tds":
        dispatch(fetchAsyncGetTDSTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
      case "directTax":
        dispatch(fetchAsyncGetDirectTaxTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
        default:
          break;

    }

  }, []);

  // Function for Bar chart Expanse
  useEffect(() => {
    let startYear = selectExpYear.split("-")[0];
    let endYear = selectExpYear.split("-")[1];

    switch (type) {
      case "internal":
        dispatch(
          fetchAsyncGetInternalExpenseBarChart({
              expanse: selectExpType,
              filterOption: selectFilterData,
              startDate : startYear,
              endDate : endYear,
            }
          )
        ).then((res) => {
          if (res.payload) {
            if (res.payload?.length > 0) {
              let options = res.payload.map((data) => Object.keys(data)[0]);
              let values = res.payload.map((data) => Object.values(data)[0]);
              setOptionsBarChart(options);
              setValuesBarChart(values);
            }
          } else {
            addToast(res.error?.message, { appearance: "error" });
          }
        });
        break;
      case "external":
        dispatch(
          fetchAsyncGetExternalExpenseBarChart({
              expanse: selectExpType,
              filterOption: selectFilterData,
              startDate : startYear,
              endDate : endYear,
            }
          )
        ).then((res) => {
          if (res.payload) {
            if (res.payload?.length > 0) {
              let options = res.payload.map((data) => Object.keys(data)[0]);
              let values = res.payload.map((data) => Object.values(data)[0]);
              setOptionsBarChart(options);
              setValuesBarChart(values);
            }
          } else {
            addToast(res.error?.message, { appearance: "error" });
          }
        });
        break;
        case "resource":
          dispatch(
            fetchAsyncGetResourceExpenseBarChart({
                expanse: selectExpType,
                filterOption: selectFilterData,
                startDate : startYear,
                endDate : endYear,
              }
            )
          ).then((res) => {
            if (res.payload) {
              if (res.payload?.length > 0) {
                let options = res.payload.map((data) => Object.keys(data)[0]);
                let values = res.payload.map((data) => Object.values(data)[0]);
                setOptionsBarChart(options);
                setValuesBarChart(values);
              }
            } else {
              addToast(res.error?.message, { appearance: "error" });
            }
          });
          break;
          case "tds":
            dispatch(
              fetchAsyncGetTDSExpenseBarChart({
                  expanse: selectExpType,
                  filterOption: selectFilterData,
                  startDate : startYear,
                  endDate : endYear,
                }
              )
            ).then((res) => {
              if (res.payload) {
                if (res.payload?.length > 0) {
                  let options = res.payload.map((data) => Object.keys(data)[0]);
                  let values = res.payload.map((data) => Object.values(data)[0]);
                  setOptionsBarChart(options);
                  setValuesBarChart(values);
                }
              } else {
                addToast(res.error?.message, { appearance: "error" });
              }
            });
            break;
            case "directTax":
              dispatch(
                fetchAsyncGetDirectTaxExpenseBarChart({
                    expanse: selectExpType,
                    filterOption: selectFilterData,
                    startDate : startYear,
                    endDate : endYear,
                  }
                )
              ).then((res) => {
                if (res.payload) {
                  if (res.payload?.length > 0) {
                    let options = res.payload.map((data) => Object.keys(data)[0]);
                    let values = res.payload.map((data) => Object.values(data)[0]);
                    setOptionsBarChart(options);
                    setValuesBarChart(values);
                  }
                } else {
                  addToast(res.error?.message, { appearance: "error" });
                }
              });
              break;
            default:
              break;     
    }
   
  }, [selectExpYear, selectExpType, selectFilterData]);

 
 
  

  return (
    <>
     {/* <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {heading}
        </Text>
      </Flex> */}
    <Box className="mx-2" >
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 2, "2xl": 3 }}
        gap="20px"
        mb="20px"
        className="grid-amount mt-5"

      
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon w="22px" h="22px" as={icon} color={brandColor} />
              }
            />
          }
          name={name}
          value={expensTotalPayAmount ? formatAmountInINR(expensTotalPayAmount) : formatAmountInINR(0)}
        />
      </SimpleGrid>
    
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* <TotalSpent /> */}
        {optionBarChart.length > 0 && valuesBarChart.length > 0 && (
          <>
          <WeeklyExpense
            optionBarChart={optionBarChart}
            valuesBarChart={valuesBarChart}
            type={selectExpType}
            changeYear={(value) => {
              setSelectExpYear(value);
            }}
            handleFilter = {(e)=>{
              handleFilterData(e)
            }}
            filterData = {selectFilterData}
            heading = {heading}
          />
          
          </>
        )}
       
      </SimpleGrid>
    </Box>
    </>
  );
}
