import React, { useEffect, useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { FaFileDownload, FaFileExcel } from 'react-icons/fa';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import ClientProjectSelect from "components/ClientProjectSelect";
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

const DownloadPopover = (
  { 
    onDownload, 
    role,
    bankValue,
    vendorValue,
    payAmount,
    excelTypeOption,
    paymentModeValue  
  }
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [amount, setAmount] = useState(payAmount);
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentDate, setPaymentDate] = useState(moment().format("YYYY-MM-DD"));
  const [excelTypeValue, setExcelTypeValue] = useState("");
  const [remarks, setRemarks] = useState("");


  useEffect(() => {
    if (paymentModeValue) {
      setPaymentMode({ label: paymentModeValue || 'NEFT', value: paymentModeValue || "NEFT" });
    }
  }, [paymentModeValue]);

  const handleDownload = () => {
    onDownload({ amount, paymentMode, paymentDate, excelTypeValue,remarks });
    // Reset form fields
    setExcelTypeValue("")
    // Close the popover
    onClose();
  };

  const paymentModeOption = [
    { label: "Intra Bank", value: "Intra Bank" },
    { label: "NEFT", value: "NEFT" },
    { label: "IMPS", value: "IMPS" },
    { label: "RTGS", value: "RTGS" },
  ];

  return (
    <Popover placement="top-start" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <button
          disabled={!bankValue?.value || !vendorValue?.value}
          className="btn border-0"
          
          onClick={onOpen}
        ><FaFileExcel style={{ color: '#13b95f' }} size={22}/></button>
      </PopoverTrigger>
      <PopoverContent style={{ width: "450px" }}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Excel Sheet Details</PopoverHeader>
        <PopoverBody>
          <Form>
            <Row>
              <Col>
                <Form.Label>Embrill Payment Type</Form.Label>
                <span className="span-star">*</span>
                <ClientProjectSelect
                  options={excelTypeOption}
                  placeHolder={<div>Type or select...</div>}
                  isSearchable
                  onChange={(newValue) => setExcelTypeValue(newValue)}
                  value={excelTypeValue}
                  defaultValue={excelTypeValue}

                />
              </Col>
              <Col>
                <Form.Label>Amount</Form.Label>
                <span className="span-star">*</span>
                <InputGroup>
                  <InputGroup.Text>
                    <span className="rupee-symbol">₹</span>
                  </InputGroup.Text>
                  <CurrencyFormat
                    customInput={Form.Control}
                    type="text"
                    name="amount"
                    placeholder="Enter Amount"
                    thousandSeparator={true}
                    value={amount || ""}
                    onValueChange={(e) => setAmount(e.value)}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Payment Mode</Form.Label>
                <span className="span-star">*</span>
                <ClientProjectSelect
                  options={paymentModeOption}
                  placeHolder={<div>Type or select...</div>}
                  isSearchable
                  onChange={(newValue) => setPaymentMode(newValue)}
                  value={paymentMode}
                  defaultValue={paymentMode}

                />
              </Col>
              <Col>
                <Form.Label>Payment Date</Form.Label>
                <span className="span-star">*</span>
                <Form.Control
                  type="date"
                  value={paymentDate}
                  onChange={(e) => setPaymentDate(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={remarks}
                  placeholder="Type Here..."
                  onChange={(e) => setRemarks(e.target.value)}

                />
              </Col>
            </Row>
            <Box display="flex" justifyContent="center">

              <Button
              colorScheme="green"
              className="mt-3"
              leftIcon={<FaFileDownload/>}
              onClick={handleDownload}
              disabled={!amount || !paymentDate || !paymentMode?.value || !excelTypeValue?.value}
            >
              Download
            </Button>
            </Box>
          </Form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DownloadPopover;
