import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 



// Icon component
const Icon = ({ isOpen }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="#222"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={isOpen ? "translate" : ""}
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

// CustomSelect component
const ExcelCustomSelect = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  align,
  defaultValue,
  role,
  isInvalid,
  value,

}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedExcelValue, setSelectedExcelValue] = useState(isMulti ? [] : null);
  const [searchValue, setSearchValue] = useState("");
 


  const searchRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    if (defaultValue && defaultValue.value) {
      setSelectedExcelValue(defaultValue);
    } else {
      setSelectedExcelValue(null);
    }
  }, [defaultValue]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, [inputRef]);

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedExcelValue || selectedExcelValue.length === 0) {
      return placeHolder;
    }
    return selectedExcelValue.label;
  };

  const removeOption = (option) => {
    return selectedExcelValue.filter((o) => o.value !== option.value);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (selectedExcelValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedExcelValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedExcelValue(newValue);
    onChange(newValue);
    setShowMenu(false);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedExcelValue.filter((o) => o.value === option.value).length > 0;
    }
    return selectedExcelValue && selectedExcelValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div ref={inputRef} className="custom--dropdown-container">
      <div onClick={handleInputClick} className={`dropdown-input`}>
        <div className={`dropdown-selected-value`}>{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon isOpen={showMenu} />
          </div>
        </div>
      </div>

      {showMenu && (
        <div className={`dropdown-menu alignment--${align || "auto"}`}>
          {isSearchable && (
            <div className="search-box">
              <input
                className="form-control"
                onChange={onSearch}
                value={searchValue}
                ref={searchRef}
                isInvalid={isInvalid}
              />
            </div>
          )}
          {getOptions().map((option) => (
            <div className="d-flex" key={option.value}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(option);
                }}
                className={`dropdown-item ${isSelected(option) && "selected"}`}
              >
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
     
    </div>
  );
};

export default ExcelCustomSelect;
