/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {  AiOutlineDashboard } from "react-icons/ai";

import { Box, Heading } from "@chakra-ui/react";
import CategoryCard from "components/card/CategoryCard";
import { BsDoorOpen, BsFillHouseHeartFill } from "react-icons/bs";
import { GrResources } from "react-icons/gr";
import { TbReceiptTax } from "react-icons/tb";
import { HiReceiptTax } from "react-icons/hi";
import {  useSelector } from "react-redux";
import { TfiReceipt } from "react-icons/tfi";
import { FaBriefcase, FaLaptop, FaUserCircle } from "react-icons/fa";

const AllCategory = () => {
  const { currUser } = useSelector((state) => state.auth);

  const auth = currUser?.auth;
  // verifies if routeName is the one active (in browser input)
  const moduleAccess = module => auth?.some(a=> a.module === module)
  
  const roleAccess = (module)=>{
    const  access = ["Admin", "Manager", "Accountant"];
    const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === module);
    if(!roleObj){
      return false
    } else {
      return access.includes(roleObj?.access)
    }
  }

  const categoryArray = [
    {
      name : "External",
      module : "External-Client/Production",

      icon : <BsDoorOpen/> ,
      path : '/external'
      
    },
    {
      name : "Internal",
      module : "Internal-Local expense",

      icon : <BsFillHouseHeartFill/> ,
      path : '/internal'
    },
    {
      name : "Resource",
      module : "Resource/Service Expense",

      icon : <GrResources/> ,
      path : '/resource-expanse'
    },
    {
      name : "TDS",
      module : "Tax Deducted at Source (TDS)",

      icon : <TbReceiptTax/> ,
      path : '/tds'
    },
    {
      name : "Direct Tax",
      module : "Direct Tax",

      icon : <HiReceiptTax/> ,
      path : '/direct-tax'
    },
    {
      name : "GST",
      module : "Goods and Services Tax (GST)",

      icon : <TfiReceipt/> ,
      path : '/gst'
    },
    
  ]

  const categoryArray2 = [
    {
      name : "Client & Projects",
      module : "Client and Projects",

      icon : <FaUserCircle/> ,
      path : '/client-project'
    },
    {
      name : "Vendors",
      module : "Vendor",

      icon : <FaBriefcase/> ,
      path : '/vendor'
    },
    {
      name : "Embrill Profile",
      module : "Embrill Profile",

      icon : <FaLaptop/> ,
      path : '/embrill-profile'
    },
  ]

  return (
    <>
      <Box
        className="all_category"
        pt={{ base: "40px", md: "5px", xl: "5px" }}
      >
        {/* <Heading className="heading" as="h5" size="sm">
          All Category
        </Heading> */}
      </Box>
      {

      }
      { moduleAccess('Main Dashboard') && roleAccess('Main Dashboard') &&
      <Box className="all_category category-main-card">
      
      <CategoryCard  name='Main Dashboard' icon={<AiOutlineDashboard/> } path={'/dashboard'} />
      </Box>
      }
      <Box className="all_category category-main-card">
        {
          categoryArray.map((item,index)=>{
            return (
              moduleAccess(item?.module) && roleAccess(item?.module) ?
                <CategoryCard key={index} name={item.name} icon={item.icon} path={item.path} />
                :
                ''
              
            )
          })
        }
       
      </Box>
      <Box className="all_category category-main-card">
        {
          categoryArray2.map((item,index)=>{
            return (
              moduleAccess(item?.module) && roleAccess(item?.module) ?
                <CategoryCard key={index} name={item.name} icon={item.icon} path={item.path} />
                :
                ''
              
            )
          })
        }
       
      </Box>
    </>
  );
};

export default AllCategory;
