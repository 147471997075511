import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddEmbrillProfile = createAsyncThunk(
  "/fetchAsyncAddEmbrillProfile",
  async (data) => {
    try {
      const response = await axiosInstance.post('/embrill-profile/create', data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



export const fetchAsyncUpdateEmbrillProfile = createAsyncThunk(
    "/fetchAsyncUpdateEmbrillProfile",
    async (data) => {
      try {
        const response = await axiosInstance.post(`/embrill-profile/update/${data?.id}`, data?.data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

export const fetchAsyncGetEmbrillProfile = createAsyncThunk(
    "/fetchAsyncGetEmbrillProfile",
    async () => {
      try {
        let url = `${API_URL}/embrill-profile/get`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

  export const fetchAsyncGetAllExcelType = createAsyncThunk(
    "/fetchAsyncGetAllExcelType",
    async () => {
      try {
        let url = `${API_URL}/embrill-profile/get-all-excel-type`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );
  export const fetchAsyncGetExcelFile = createAsyncThunk(
    "/fetchAsyncGetExcelFile",
    async (key) => {
      try {
        let url = `${API_URL}/embrill-profile/get-excel-file`;
        url = url + `?key=${key}`;

        const response = await axiosInstance.get(url,{ responseType: 'blob' });
        return response;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );
  export const fetchAsyncGetEmbrillProfileByID = createAsyncThunk(
    "/fetchAsyncGetEmbrillProfileByID",
    async (id) => {
      try {
        let url = `${API_URL}/embrill-profile/get-embrill-profile/${id}`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );
  export const fetchAsyncDeleteEmbrillProfile = createAsyncThunk(
    "/fetchAsyncDeleteEmbrillProfile",
    async (id) => {
      try {
        let url = `${API_URL}/embrill-profile/delete/${id}`;
        const response = await axiosInstance.delete(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

  export const fetchAsyncUploadExcel = createAsyncThunk(
    "/fetchAsyncUploadExcel",
    async (data) => {
      try {
        let url = `${API_URL}/embrill-profile/upload_excel/${data.id}`;
        const response = await axiosInstance.post(url,data.formData);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.msg);
      }
    }
  );

  export const fetchAsyncUpdateExcel = createAsyncThunk(
    "/fetchAsyncUpdateExcel",
    async (data) => {
      try {
        let url = `${API_URL}/embrill-profile/update_excel_file/${data.id}`;
        const response = await axiosInstance.post(url,data.formData);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.msg);
      }
    }
  );
  export const fetchAsyncDeleteExcel = createAsyncThunk(
    "/fetchAsyncDeleteExcel",
    async (data) => {
      try {
        let url = `${API_URL}/embrill-profile/delete_excel/${data.id}`;
        const response = await axiosInstance.post(url,{ key: data.key,index:data?.index });
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.msg);
      }
    }
  );
  export const fetchAsyncSearchEmbrillProfileData = createAsyncThunk(
    "/fetchAsyncSearchEmbrillProfileData",
    async (data) => {
      try {
        let url = `${API_URL}/embrill-profile/search`;
        if (data.value) {
          url = url + `?search=${data.value}`;
        }
        if (data.startDate && data.endDate && data.value) {
          url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
        }
        if (data.startDate && data.endDate && !data.value) {
          url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
        }
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.msg);
      }
    }
  );
  const AllEmbrillProfileSlice = createSlice({
    name: "embrillProfileData",
    initialState,
    reducers: {},
    extraReducers: {
      [fetchAsyncGetEmbrillProfile.pending]: (state) => {},
      [fetchAsyncGetEmbrillProfile.fulfilled]: (state, { payload }) => {
        return payload;
      },
      [fetchAsyncSearchEmbrillProfileData.pending]: (state) => {},
      [fetchAsyncSearchEmbrillProfileData.fulfilled]: (state, { payload }) => {
        return payload;
      },
    },
  });
  
  export const getAllEmbrillProfile = (state) => state.embrillProfileData;
  
  export default AllEmbrillProfileSlice.reducer;




