import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import Logo from "../../../assets/img/Logo_without_teg.svg";
import Logo1 from "../../../assets/img/Embrill-Logo_R_white.png";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column" className="logo-container">
      <img src={Logo1} alt="logo" style={{ width: "120px" }} />
    </Flex>
  );
}

export default SidebarBrand;
